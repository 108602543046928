import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import {
  createCareHomeByPharmacyAction,
  deleteCareHomeByPharmacyAction,
  fetchCareHomesByPharmacyAction,
  updateCareHomeByPharmacyAction,
} from "../../../redux/actions/careHomeActions";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  WarningNotification,
  ErrorNotification,
} from "../../../Components/Notifications/ToastNotifications";
import "./careHome.css";

const defaultDetails = {
  name: "",
  address: "",
  postCode: "",
  landline: "",
  mobile: "",
  note: "",
};

const defaultManager = {
  name: "",
  landline: "",
  mobile: "",
};

const defaultCarer = {
  name: "",
  landline: "",
  mobile: "",
};

const CareHome = () => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [newDetails, setNewDetails] = useState(defaultDetails);
  const [managerDetails, setManagerDetails] = useState(defaultManager);
  const [carerDetails, setCarerDetails] = useState(defaultCarer);
  const [mode, setMode] = useState("add");
  const [showCareHomeModal, setShowCareHomeModal] = useState(false);
  const [careHomeShow, setCareHomeShow] = useState(false);
  const [patientsShow, setPatientsShow] = useState(false);
  const [selectedCareHome, setSelectedCareHome] = useState({});
  const [patientsUnderCareHome, setPatientsUnderCareHome] = useState([]);

  const careHomes = useSelector((state) => state.careHome.careHomes);
  const user = useSelector((state) => state.auth.user);
  const patients = useSelector((state) => state.patient.patients);

  const componentRef = useRef();
  const handleSave = () => {
    if (
      !newDetails.name ||
      !newDetails.address ||
      (!newDetails.landline && !newDetails.mobile)
    ) {
      WarningNotification(
        "Please provide a name, address, and at least one phone number (mobile or landline)."
      );
      return;
    }

    const careHomeData = {
      ...newDetails,
      managerName: managerDetails.name,
      managerLandline: managerDetails.landline,
      managerMobile: managerDetails.mobile,
      careDetailsName: carerDetails.name,
      careDetailsLandline: carerDetails.landline,
      careDetailsMobile: carerDetails.mobile,
    };

    if (mode === "add") {
      // No ID is needed for creating a new care home
      dispatch(createCareHomeByPharmacyAction(pharmacyId, careHomeData))
        .then(() => {
          // Success logic here
          handleSuccess();
        })
        .catch((error) => {
          ErrorNotification(error);
        });
    } else if (mode === "edit") {
      // Ensure that ID is included for updating
      dispatch(
        updateCareHomeByPharmacyAction(pharmacyId, newDetails.id, careHomeData)
      )
        .then(() => {
          // Success logic here
          handleSuccess();
        })
        .catch((error) => {
          ErrorNotification(error);
        });
    }
  };

  const handleSuccess = () => {
    setShowCareHomeModal(false);
    setNewDetails(defaultDetails);
    setManagerDetails(defaultManager);
    setCarerDetails(defaultCarer);
    getCareHomes();
    dispatch(
      createNotificationByPharmacyAction(pharmacyId, {
        content: `Care home ${mode === "add" ? "created" : "updated"}: ${
          newDetails.name
        }`,
        user: user._id,
        forRole: ["admin", "manager"],
        priority: mode === "add" ? "high" : "medium",
      })
    );
  };

  const deleteCareHome = (id) => {
    dispatch(deleteCareHomeByPharmacyAction(pharmacyId, id))
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Care home deleted.`,
            user: user._id,
            forRole: ["admin"],
            priority: "high",
          })
        );
        getCareHomes();
      })
      .catch(() => {
        ErrorNotification("Error deleting care home.");
      });
  };

  const handleChange = (e, detailsType) => {
    const { name, value } = e.target;
    if (["landline", "mobile"].includes(name) && value.length > 12) return;
    const setter =
      detailsType === "newDetails"
        ? setNewDetails
        : detailsType === "managerDetails"
        ? setManagerDetails
        : setCarerDetails;
    setter((prev) => ({ ...prev, [name]: value }));
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {
      console.error("Error fetching patients:", err);
    });
  };

  useEffect(() => {
    getPatients();
    getCareHomes();
  }, []);

  const openModal = (careHome = null) => {
    if (careHome) {
      setNewDetails({
        id: careHome._id, // Ensure this is correct as per your data structure
        ...careHome,
      });
      // Assuming manager and carer details are part of careHome, adjust accordingly
      setManagerDetails({
        name: careHome.managerName,
        landline: careHome.managerLandline,
        mobile: careHome.managerMobile,
      });
      setCarerDetails({
        name: careHome.careDetailsName,
        landline: careHome.careDetailsLandline,
        mobile: careHome.careDetailsMobile,
      });
      setMode("edit");
    } else {
      setNewDetails(defaultDetails);
      setManagerDetails(defaultManager);
      setCarerDetails(defaultCarer);
      setMode("add");
    }
    setShowCareHomeModal(true);
  };

  const closeModal = () => {
    setShowCareHomeModal(false);
    setNewDetails(defaultDetails);
    setManagerDetails(defaultManager);
    setCarerDetails(defaultCarer);
  };

  const viewCareHome = (careHome) => {
    setSelectedCareHome(careHome);
    setCareHomeShow(true);
  };

  const closeViewModal = () => {
    setCareHomeShow(false);
  };

  const viewPatientsUnderCareHome = (careHomeId) => {
    const filteredPatients = patients.filter(
      (patient) => patient.careHome === careHomeId
    );
    setPatientsUnderCareHome(filteredPatients);
    setPatientsShow(true);
  };

  const closePatientsModal = () => {
    setPatientsShow(false);
    setPatientsUnderCareHome([]);
  };

  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <div className="page-holder">
            <div className="col-md-12 col-xs-12">
              <Button
                onClick={() => openModal()}
                className="medtrakr-btn"
                style={{ margin: "0 0 1rem 0" }}
              >
                Create Care Home
              </Button>
            </div>

            {/* Care Homes Table */}
            <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
              <table className="w-100 table-bordered table" ref={componentRef}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Manager</th>
                    <th>Carer</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {careHomes.map((careHome) => (
                    <tr key={careHome._id}>
                      <td>{careHome.name}</td>
                      <td>{careHome.managerName}</td>
                      <td>{careHome.careDetailsName}</td>
                      <td>
                        <button
                          className="btn view"
                          onClick={() => viewCareHome(careHome)}
                          style={{ marginRight: "0.5rem" }}
                        >
                          View Details
                        </button>
                        <button
                          className="btn view"
                          onClick={() =>
                            viewPatientsUnderCareHome(careHome._id)
                          }
                          style={{ marginRight: "0.5rem" }}
                        >
                          View Patients
                        </button>
                        <button
                          className="btn update"
                          onClick={() => openModal(careHome)}
                          style={{ marginRight: "0.5rem" }}
                        >
                          Edit
                        </button>
                        <button
                          className="btn delete"
                          onClick={() => deleteCareHome(careHome._id)}
                          style={{ marginRight: "0.5rem" }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Create/Edit Care Home Modal */}
      <Modal show={showCareHomeModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "add" ? "Create Care Home" : "Edit Care Home"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <h4 style={{ padding: "0 0.75rem" }}>Care Home Details</h4>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupName"
              >
                <Form.Label>
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={newDetails.name}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupAddress"
              >
                <Form.Label>
                  Address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  required
                  value={newDetails.address}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupPostCode"
              >
                <Form.Label>Post Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postCode"
                  value={newDetails.postCode}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupLandline"
              >
                <Form.Label>
                  Landline <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  required
                  value={newDetails.landline}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupMobile"
              >
                <Form.Label>
                  Mobile <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={newDetails.mobile}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-md-6 col-xs-12"
                controlId="formGroupNote"
              >
                <Form.Label>Note</Form.Label>
                <Form.Control
                  type="text"
                  name="note"
                  value={newDetails.note}
                  onChange={(e) => handleChange(e, "newDetails")}
                />
              </Form.Group>
            </div>
            <div className="row">
              <h4 style={{ padding: "0 0.75rem" }}>Manager Details</h4>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupManagerName"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={managerDetails.name}
                  onChange={(e) => handleChange(e, "managerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupManagerLandline"
              >
                <Form.Label>Landline</Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  required
                  value={managerDetails.landline}
                  onChange={(e) => handleChange(e, "managerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupManagerMobile"
              >
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={managerDetails.mobile}
                  onChange={(e) => handleChange(e, "managerDetails")}
                />
              </Form.Group>
              <h4 style={{ padding: "0 0.75rem" }}>Carer Details</h4>
              <Form.Group className="col-xs-12" controlId="formGroupCarerName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={carerDetails.name}
                  onChange={(e) => handleChange(e, "carerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupCarerLandline"
              >
                <Form.Label>Landline</Form.Label>
                <Form.Control
                  type="text"
                  name="landline"
                  required
                  value={carerDetails.landline}
                  onChange={(e) => handleChange(e, "carerDetails")}
                />
              </Form.Group>
              <Form.Group
                className="col-xs-12"
                controlId="formGroupCarerMobile"
              >
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  required
                  value={carerDetails.mobile}
                  onChange={(e) => handleChange(e, "carerDetails")}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View Care Home Modal */}
      <Modal show={careHomeShow} onHide={closeViewModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Care Home Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Name:</strong> {selectedCareHome.name}
          </p>
          <p>
            <strong>Address:</strong> {selectedCareHome.address}
          </p>
          <p>
            <strong>Post Code:</strong> {selectedCareHome.postCode}
          </p>
          <p>
            <strong>Landline:</strong> {selectedCareHome.landline}
          </p>
          <p>
            <strong>Mobile:</strong> {selectedCareHome.mobile}
          </p>
          <p>
            <strong>Note:</strong> {selectedCareHome.note}
          </p>
          <p>
            <strong>Manager Name:</strong> {selectedCareHome.managerName}
          </p>
          <p>
            <strong>Manager Contact:</strong> {selectedCareHome.managerLandline}
            , {selectedCareHome.managerMobile}
          </p>
          <p>
            <strong>Carer Name:</strong> {selectedCareHome.careDetailsName}
          </p>
          <p>
            <strong>Carer Contact:</strong>{" "}
            {selectedCareHome.careDetailsLandline},{" "}
            {selectedCareHome.careDetailsMobile}
          </p>
        </Modal.Body>
      </Modal>

      {/* View Patients Under Care Home Modal */}
      <Modal show={patientsShow} onHide={closePatientsModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Patients Under Care Home</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {patientsUnderCareHome.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>NHS Number</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {patientsUnderCareHome.map((patient, index) => (
                  <tr key={patient._id}>
                    <td>{index + 1}</td>
                    <td>{patient.displayName}</td>
                    <td>{patient.nhsNumber}</td>
                    <td>
                      {new Date(patient.createdAt).toLocaleDateString("en-GB")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No patients have been added under this care home.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePatientsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CareHome;

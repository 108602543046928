import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { newPassword } from "../../../api/auth";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../../Components/Notifications/ToastNotifications";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  // Handle form change
  const handleFormChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (password !== confirmPassword) {
      ErrorNotification("Passwords don't match");
      return;
    }

    setLoading(true);
    try {
      const res = await newPassword(token, password);
      SuccessNotification(res.data.message);
      setSuccess(true);
    } catch (err) {
      ErrorNotification(err.response.data.type[0].message);
      console.log("SET_PASSWORD_ERROR", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-md-center">
      <Col md={6}>
        {!success ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPassword">
              <Form.Label>Password:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={handleFormChange(setPassword)}
              />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter password"
                value={confirmPassword}
                onChange={handleFormChange(setConfirmPassword)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              Change Password
            </Button>
          </Form>
        ) : (
          <div className="text-center">
            <h1>Password Reset Successfully</h1>
            <Link to="/login">Login</Link>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default NewPassword;

import React, { useEffect, useState } from "react";
import { fetchMdsByPharmacyAction } from "../../../redux/actions/mdsActions";
import { Button, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./reminderRx.css";

export default function ReminderRx() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMds, setSelectedMds] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterType, setFilterType] = useState("upcoming");

  const mdsData = useSelector((state) => state.mds.mdsState);

  useEffect(() => {
    dispatch(fetchMdsByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching pharmacy:", error);
    });
  }, [dispatch, pharmacyId]);

  const handleOpenModal = (mds) => {
    setSelectedMds(mds);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedMds(null);
    setShowModal(false);
  };

  const handleFilterMds = () => {
    const now = new Date();
    const today = new Date().toISOString().split("T")[0];
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowStr = tomorrow.toISOString().split("T")[0];

    let filteredData = mdsData;

    if (filterType === "today") {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start).toISOString().split("T")[0] === today
      );
    } else if (filterType === "tomorrow") {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start).toISOString().split("T")[0] === tomorrowStr
      );
    } else if (filterType === "upcoming") {
      filteredData = filteredData.filter((mds) => new Date(mds.start) >= now);
    } else if (filterType === "previous") {
      filteredData = filteredData.filter((mds) => new Date(mds.start) < now);
    }

    if (fromDate) {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start) >= new Date(fromDate)
      );
    }
    if (toDate) {
      filteredData = filteredData.filter(
        (mds) => new Date(mds.start) <= new Date(toDate)
      );
    }

    if (searchQuery) {
      filteredData = filteredData.filter((mds) => {
        const fullName =
          `${mds.patient.firstName} ${mds.patient.lastName}`.toLowerCase();
        return (
          fullName.includes(searchQuery.toLowerCase()) ||
          mds.patient.nhsNumber.toString().includes(searchQuery)
        );
      });
    }

    return filteredData;
  };

  const resetFilters = () => {
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    setFilterType("upcoming");
  };

  const filteredData = handleFilterMds();

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMdsData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  return (
    <>
      <div className="mt-80">
        <div className="container-fluid">
          <div className="search-input-box">
            <input
              type="text"
              placeholder="Search by name or NHS number..."
              className="form-control mr-10 search-filter-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="date-filter-box">
              <input
                type="date"
                className="form-control mb-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control mb-3"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />

              <Button className="btn btn-warning mb-3" onClick={resetFilters}>
                Reset Filters
              </Button>
            </div>
          </div>
          <div>
            <Button
              className={
                filterType === "all" ? "active-filter-btn" : "reminder-btn"
              }
              onClick={() => setFilterType("all")}
            >
              All
            </Button>
            <Button
              className={
                filterType === "today" ? "active-filter-btn" : "reminder-btn"
              }
              onClick={() => setFilterType("today")}
            >
              Today
            </Button>
            <Button
              className={
                filterType === "tomorrow" ? "active-filter-btn" : "reminder-btn"
              }
              onClick={() => setFilterType("tomorrow")}
            >
              Tomorrow
            </Button>
            <Button
              className={
                filterType === "upcoming" ? "active-filter-btn" : "reminder-btn"
              }
              onClick={() => setFilterType("upcoming")}
            >
              Upcoming
            </Button>
            <Button
              className={
                filterType === "previous" ? "active-filter-btn" : "reminder-btn"
              }
              onClick={() => setFilterType("previous")}
            >
              Previous
            </Button>
          </div>

          <Table bordered className="w-100 mt-4">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>NHS Number</th>
                <th>Date</th>
                <th>Medication Name (Dosage)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentMdsData.map((mds) => (
                <tr key={mds._id}>
                  <td>{`${mds.patient.firstName} ${mds.patient.lastName}`}</td>
                  <td>{mds.patient.nhsNumber}</td>
                  <td>
                    {new Date(mds.start).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    })}
                  </td>
                  <td>
                    {mds.medicationDetails
                      .map((detail) => `${detail.name} (${detail.dosage})`)
                      .join(", ")}
                  </td>
                  <td>
                    <Button size="sm" onClick={() => handleOpenModal(mds)}>
                      Request Prescription
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="page-number-box">
            <div>
              <select
                className="form-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[5, 10, 15, 20].map((number) => (
                  <option key={number} value={number}>
                    {number} MDS/page
                  </option>
                ))}
              </select>
            </div>
            <div className="pagination">
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber + 1)}
                  className={`page-link ${
                    currentPage === pageNumber + 1 ? "active" : ""
                  }`}
                >
                  {pageNumber + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for requesting prescription */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Request Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMds && (
            <div>
              <p>
                <b>Patient Name</b>: {selectedMds.patient.firstName}{" "}
                {selectedMds.patient.lastName}
              </p>
              <p>
                <b>Medication Details</b>:{" "}
                {selectedMds.medicationDetails
                  .map((detail) => `${detail.name} ${detail.dosage}`)
                  .join(", ")}
              </p>
              <p>
                <b>Request:</b>
                <br />
                {`Dear [Doctor's Name],\n\nPlease provide a prescription for the following medications for patient ${
                  selectedMds.patient.firstName
                } ${
                  selectedMds.patient.lastName
                }:\n\n${selectedMds.medicationDetails
                  .map((detail) => `- ${detail.name} (${detail.dosage})`)
                  .join("\n")}\n\nThank you.`}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              navigator.clipboard.writeText(
                `Dear [Doctor's Name],\n\nPlease provide a prescription for the following medications for patient ${
                  selectedMds.patient.firstName
                } ${
                  selectedMds.patient.lastName
                }:\n\n${selectedMds.medicationDetails
                  .map((detail) => `- ${detail.name} (${detail.dosage})`)
                  .join("\n")}\n\nThank you.`
              )
            }
          >
            Copy Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import api from "./index";

// Fetch Pharmacy
export const fetchPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/pharmacy`);
};
// Super Admin
// Fetch All Pharmacies
export const fetchAllPharmacies = async () => {
  return api.get("/pharmacy-list");
};

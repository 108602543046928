import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import {
  fetchShelvesByPharmacyAction,
  assignShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchCollectionsByPharmacyAction } from "../../../redux/actions/collectionActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useParams } from "react-router-dom";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { MdHome, MdLocalHospital } from "react-icons/md";
import "./medicineStorage.css";

const formatDateForDisplay = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

const formatDateForSearch = (dateString) => {
  const date = new Date(dateString);
  return `${String(date.getDate()).padStart(2, "0")}/${String(
    date.getMonth() + 1
  ).padStart(2, "0")}/${date.getFullYear()}`;
};

function MedicineStorage() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchPatient, setSearchPatient] = useState("");
  const [selectedShelf, setSelectedShelf] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [capacityNeeded, setCapacityNeeded] = useState(1);
  const [collectionType, setCollectionType] = useState("handout");
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Pagination for occupied shelves
  const [occupiedShelfPage, setOccupiedShelfPage] = useState(1);
  const [occupiedShelfItemsPerPage, setOccupiedShelfItemsPerPage] = useState(5);

  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };
  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const getShelves = () => {
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching shelves:", error);
    });
  };
  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {
      console.error("Error fetching patients:", err);
    });
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  const getCollections = () => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
  };

  useEffect(() => {
    getPatients();
    getShelves();
    getCareHomes();
    getCollections();
  }, []);

  const patients = useSelector((state) =>
    state.patient.patients.map((patient) => {
      return {
        ...patient,
        dateOfBirth: formatDateForDisplay(patient.dateOfBirth),
      };
    })
  );

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchPatient(value);
    setCurrentPage(1);
  };

  const filteredPatients = patients.filter((patient) => {
    const dobFormatted = formatDateForSearch(patient.dateOfBirth);
    const monthName = new Date(patient.dateOfBirth)
      .toLocaleString("en-GB", { month: "short" })
      .toLowerCase();
    const address = patient.addressLine1?.toLowerCase() || "";
    const postcode = patient.postCode?.toLowerCase() || "";
    const careHome = patient.careHome
      ? `${careHomeName(patient.careHome)} ${careHomeAddress(
          patient.careHome
        )}`.toLowerCase()
      : "";

    const searchWords = searchPatient.split(" ");

    // Check if every word in the search query matches any of the patient's details
    return searchWords.every(
      (word) =>
        patient.firstName.toLowerCase().includes(word) ||
        patient.lastName.toLowerCase().includes(word) ||
        patient.middleName?.toLowerCase().includes(word) ||
        patient.nhsNumber.toLowerCase().includes(word) ||
        dobFormatted.includes(word) ||
        monthName.includes(word) ||
        address.includes(word) ||
        postcode.includes(word) ||
        careHome.includes(word)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPatients = filteredPatients.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  const toggleShelfModal = () => {
    setIsTableOpen(!isTableOpen);
  };

  const remainingCapacity = (shelfCapacity, occupiedCapacity) => {
    return shelfCapacity - occupiedCapacity;
  };

  const handleAddCollection = () => {
    const shelfName = shelves.find(
      (shelf) => shelf._id === selectedShelf
    )?.name;

    if (!selectedShelf || !selectedPatient._id || !collectionType) {
      ErrorNotification("Missing required fields for assigning a shelf");
      return;
    }

    dispatch(
      assignShelfAction(
        pharmacyId,
        selectedShelf,
        capacityNeeded,
        selectedPatient._id,
        collectionType
      )
    )
      .then(() => {
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
        setSelectedShelf("");
        setCapacityNeeded(1);
        setShowAddCollectionModal(false);
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Shelf '${shelfName}' with ${capacityNeeded} assigned successfully to ${selectedPatient.firstName} ${selectedPatient.lastName}.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
      })
      .catch((error) => {
        console.error("Error assigning shelf:", error);
        ErrorNotification(error);
      });
  };

  const handleCloseModal = () => {
    setShowAddCollectionModal(false);
    setSelectedShelf(""); // Reset the selected shelf when the modal is closed
  };

  // Pagination for occupied shelves
  const handlePageChangeOccupiedShelves = (pageNumber, e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the modal from closing
    setOccupiedShelfPage(pageNumber);
  };

  const handleItemsPerPageChangeOccupiedShelves = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the modal from closing
    setOccupiedShelfItemsPerPage(Number(e.target.value));
    setOccupiedShelfPage(1); // Reset to the first page whenever items per page change
  };

  const getOccupiedShelvesByPatient = (patientId) => {
    return shelves
      .map((shelf) => {
        const patientCollections = shelf.collectionRef
          .map((collectionId) =>
            collections.find(
              (col) => col._id === collectionId && col.patient._id === patientId
            )
          )
          .filter((col) => col);

        const userOccupiedCapacity = patientCollections.reduce(
          (total, collection) => total + collection.capacityNeeded,
          0
        );

        return {
          ...shelf,
          userOccupiedCapacity,
          remainingCapacity: remainingCapacity(
            shelf.shelfCapacity,
            shelf.occupiedCapacity
          ),
          createdAt: patientCollections.length
            ? patientCollections[0].createdAt
            : "",
          updatedAt: patientCollections.length
            ? patientCollections[0].updatedAt
            : "",
        };
      })
      .filter((shelf) => shelf.userOccupiedCapacity > 0); // Only return shelves with capacity occupied by the user
  };

  const occupiedShelves = getOccupiedShelvesByPatient(selectedPatient._id);

  // Pagination logic for occupied shelves
  const indexOfLastOccupiedShelf =
    occupiedShelfPage * occupiedShelfItemsPerPage;
  const indexOfFirstOccupiedShelf =
    indexOfLastOccupiedShelf - occupiedShelfItemsPerPage;
  const currentOccupiedShelves = occupiedShelves.slice(
    indexOfFirstOccupiedShelf,
    indexOfLastOccupiedShelf
  );
  const totalOccupiedShelfPages = Math.ceil(
    occupiedShelves.length / occupiedShelfItemsPerPage
  );

  return (
    <div className="mt-100">
      {userCurrentPlan === "Free" && (
        <div className="information-text">
          <p>Setting Collection type to "delivery is disabled for Free Plan</p>
        </div>
      )}
      <div className="container-fluid">
        <div className="search-input-box">
          <input
            type="text"
            value={searchPatient}
            placeholder="Search By Name, NHS, Address, DOB, Postcode"
            className="search-input"
            onChange={handleSearchChange}
          />
          <Button onClick={toggleShelfModal} className="medtrakr-btn">
            {isTableOpen ? "Close Shelf Capacity" : "View Shelf Capacity"}
          </Button>
        </div>

        <div className="icon-ledger-container">
          <div>
            <div className="d-flex align-items-center">
              <MdHome size={24} color="green" style={{ marginRight: "8px" }} />
              <h6>Patient Address:</h6>
            </div>
            <div className="d-flex align-items-center">
              <MdLocalHospital
                size={24}
                color="blue"
                style={{ marginRight: "8px" }}
              />
              <h6>Care Home Address:</h6>
            </div>
          </div>
        </div>

        <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>NHS Number</th>
                <th>DOB</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentPatients.map((patient) => (
                <tr key={patient._id}>
                  <td>{patient.firstName + " " + patient.lastName}</td>
                  <td>{patient.nhsNumber}</td>
                  <td>{patient.dateOfBirth}</td>
                  <td>{patient.mobileNo}</td>
                  <td>
                    {patient.careHome ? (
                      <div className="create-patient-address">
                        <div className="d-flex align-items-center">
                          <MdLocalHospital
                            size={24}
                            color="blue"
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div>
                          <p className="create-patient-carehome-name">
                            {careHomeName(patient.careHome)}
                          </p>
                          <div>{careHomeAddress(patient.careHome)}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="create-patient-address">
                        <div className="d-flex align-items-center">
                          <MdHome
                            size={24}
                            color="green"
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div>
                          {patient.addressLine1 &&
                            `${
                              patient.addressLine1.trim().endsWith(",")
                                ? patient.addressLine1
                                : patient.addressLine1 + ","
                            } `}
                          {patient.addressLine2 &&
                            `${
                              patient.addressLine2.trim().endsWith(",")
                                ? patient.addressLine2
                                : patient.addressLine2 + ","
                            } `}
                          {patient.townName && `${patient.townName}, `}
                          {patient.postCode}
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="btn view btn-sm"
                      onClick={() => {
                        setSelectedPatient(patient);
                        setShowAddCollectionModal(true);
                      }}
                    >
                      Add Collection
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="page-number-box">
            <div>
              <select
                className="form-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[5, 10, 15, 20].map((number) => (
                  <option key={number} value={number}>
                    {number} Patients/page
                  </option>
                ))}
              </select>
            </div>
            <div className="pagination">
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber + 1)}
                  className={`page-link ${
                    currentPage === pageNumber + 1 ? "active" : ""
                  }`}
                >
                  {pageNumber + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Assign Shelf Modal */}
      <Modal show={showAddCollectionModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Assign Shelf</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Table showing all shelves occupied by the selected patient */}
            {occupiedShelves.length > 0 && (
              <>
                <h5>Occupied Shelves</h5>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Shelf Name</th>
                      <th>Package Size</th>
                      <th>Patient Occupied Capacity</th>
                      <th>Total Remaining Capacity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentOccupiedShelves.map((shelf) => (
                      <tr key={shelf._id}>
                        <td>{shelf.name}</td>
                        <td>{shelf.packageSize}</td>
                        <td>{shelf.userOccupiedCapacity}</td>
                        <td>{shelf.remainingCapacity}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="page-number-box">
                  <div>
                    <select
                      className="form-select"
                      value={occupiedShelfItemsPerPage}
                      onChange={(e) =>
                        handleItemsPerPageChangeOccupiedShelves(e)
                      }
                    >
                      {[5, 10, 15, 20].map((number) => (
                        <option key={number} value={number}>
                          {number} Shelves/page
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pagination">
                    {[...Array(totalOccupiedShelfPages).keys()].map(
                      (pageNumber) => (
                        <button
                          key={pageNumber}
                          type="button"
                          onClick={(e) =>
                            handlePageChangeOccupiedShelves(pageNumber + 1, e)
                          }
                          className={`page-link ${
                            occupiedShelfPage === pageNumber + 1 ? "active" : ""
                          }`}
                        >
                          {pageNumber + 1}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </>
            )}
            <Form.Group>
              <Form.Label>Select Shelf:</Form.Label>
              <Form.Control
                as="select"
                value={selectedShelf}
                onChange={(e) => setSelectedShelf(e.target.value)}
              >
                <option value="">Select Shelf</option>
                {shelves.map((shelf) => {
                  const remainingCapacity =
                    shelf.shelfCapacity - shelf.occupiedCapacity;
                  return (
                    <option key={shelf._id} value={shelf._id}>
                      {shelf.name} - {shelf.packageSize} (Remaining Capacity:{" "}
                      {remainingCapacity})
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Capacity Needed:</Form.Label>
              <Form.Control
                type="number"
                value={capacityNeeded}
                onChange={(e) => setCapacityNeeded(Number(e.target.value))}
                min="1"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Collection Type:</Form.Label>
              <Form.Control
                as="select"
                value={collectionType}
                onChange={(e) => setCollectionType(e.target.value)}
              >
                <option value="handout">Handout</option>
                {userCurrentPlan === "Free" ? (
                  <option value="delivery" disabled>
                    Delivery
                  </option>
                ) : (
                  <option value="delivery">Delivery</option>
                )}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddCollection}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Shelf Capacity Modal */}
      <Modal show={isTableOpen} onHide={toggleShelfModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Shelf Capacity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Package Size</th>
                <th>Occupied</th>
                <th>Remaining</th>
                <th>Capacity</th>
              </tr>
            </thead>
            <tbody>
              {shelves.map((shelf) => (
                <tr key={shelf._id}>
                  <td>{shelf.name}</td>
                  <td>{shelf.packageSize}</td>
                  <td>{shelf.occupiedCapacity}</td>
                  <td>
                    {remainingCapacity(
                      shelf.shelfCapacity,
                      shelf.occupiedCapacity
                    )}
                  </td>
                  <td>{shelf.shelfCapacity}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MedicineStorage;

import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Table, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaPlus, FaTrashAlt } from "react-icons/fa";
import {
  createPlanAction,
  updatePlanAction,
  deletePlanAction,
  fetchPlansAction,
} from "../../../../redux/actions/planActions";
import "./plan.css";
import { WarningNotification } from "../../../../Components/Notifications/ToastNotifications";

function Plan() {
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [planData, setPlanData] = useState({
    name: "",
    metaDescription: "",
    monthlyPrice: "",
    annualPrice: "",
    currency: "usd",
    stripeProductId: "",
    stripeMonthlyPriceId: "",
    stripeAnnualPriceId: "",
    seatType: "",
    trialDays: "0",
    maxUsers: "",
    maxStorage: "",
    features: [{ feature: "" }],
  });
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plan.plans);

  useEffect(() => {
    dispatch(fetchPlansAction());
  }, [dispatch]);

  const handleInputChange = (e) => {
    setPlanData({ ...planData, [e.target.name]: e.target.value });
  };

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...planData.features];
    newFeatures[index].feature = value;
    setPlanData({ ...planData, features: newFeatures });
  };

  const addFeature = () => {
    setPlanData({
      ...planData,
      features: [...planData.features, { feature: "" }],
    });
  };

  const removeFeature = (index) => {
    const newFeatures = planData.features.filter((_, i) => i !== index);
    setPlanData({ ...planData, features: newFeatures });
  };
  console.log("====================================");
  console.log(planData, "Plan Data");
  console.log("====================================");
  const handleSubmit = () => {
    const requiredFields = [
      { key: "name", label: "Name" },
      { key: "metaDescription", label: "Meta Description" },
      { key: "monthlyPrice", label: "Monthly Price" },
      { key: "annualPrice", label: "Annual Price" },
      { key: "currency", label: "Currency" },
      { key: "stripeProductId", label: "Stripe Product ID" },
      { key: "stripeMonthlyPriceId", label: "Stripe Monthly Price ID" },
      { key: "stripeAnnualPriceId", label: "Stripe Annual Price ID" },
      { key: "seatType", label: "Seat Type" },
      { key: "trialDays", label: "Trial Days" },
      { key: "maxUsers", label: "Maximum Users" },
      { key: "maxStorage", label: "Maximum Storage" },
      {
        key: "features",
        label: "Features",
        customCheck: (data) =>
          data.features &&
          data.features.length > 0 &&
          data.features.every((f) => f.feature),
      },
    ];

    const missingFields = requiredFields
      .filter((field) =>
        field.customCheck ? !field.customCheck(planData) : !planData[field.key]
      )
      .map((field) => field.label);

    if (missingFields.length > 0) {
      WarningNotification(
        "Please fill in all required fields: " + missingFields.join(", ")
      );
      return;
    }

    if (editMode) {
      dispatch(updatePlanAction(planData._id, planData));
    } else {
      dispatch(createPlanAction(planData));
    }
    setShowModal(false);
    setPlanData({
      name: "",
      metaDescription: "",
      monthlyPrice: "",
      annualPrice: "",
      currency: "usd",
      stripeProductId: "",
      stripeMonthlyPriceId: "",
      stripeAnnualPriceId: "",
      seatType: "",
      trialDays: "0",
      maxUsers: "",
      maxStorage: "",
      features: [{ feature: "" }],
    }); // Reset form
  };

  const handleEdit = (plan) => {
    setEditMode(true);
    setPlanData(plan);
    setShowModal(true);
  };

  const handleView = (plan) => {
    setCurrentPlan(plan);
    setViewModal(true);
  };

  const handleDelete = (id) => {
    dispatch(deletePlanAction(id));
  };

  return (
    <div className="mt-100">
      <div className="container-fluid">
        <Button
          onClick={() => {
            setShowModal(true);
            setEditMode(false);
          }}
          className="medtrakr-btn"
        >
          Create Plan
        </Button>
        <Table striped bordered hover className="mt-50">
          <thead>
            <tr>
              <th>Name</th>
              <th>Monthly Price</th>
              <th>Annual Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {plans.length > 0
              ? plans.map((plan) => (
                  <tr key={plan._id}>
                    <td>{plan.name}</td>
                    <td>{plan.monthlyPrice}</td>
                    <td>{plan.annualPrice}</td>
                    <td>
                      <Button
                        className="medtrakr-btn"
                        onClick={() => handleEdit(plan)}
                        style={{ marginRight: "10px" }}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn delete"
                        onClick={() => handleDelete(plan._id)}
                        style={{ marginRight: "10px" }}
                      >
                        Delete
                      </Button>
                      <Button
                        className="btn view"
                        onClick={() => handleView(plan)}
                        style={{ marginRight: "10px" }}
                      >
                        <FaEye />
                      </Button>
                    </td>
                  </tr>
                ))
              : "No Plans Created Yet"}
          </tbody>
        </Table>

        {/* Modal To Create and Edit Plan */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? "Edit Plan" : "Create Plan"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter plan name"
                  name="name"
                  value={planData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Meta Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter meta description"
                  name="metaDescription"
                  value={planData.metaDescription}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Monthly Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter monthly price"
                  name="monthlyPrice"
                  value={planData.monthlyPrice}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Annual Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter annual price"
                  name="annualPrice"
                  value={planData.annualPrice}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Currency</Form.Label>
                <Form.Control
                  as="select"
                  name="currency"
                  value={planData.currency}
                  onChange={handleInputChange}
                >
                  <option value="usd">USD</option>
                  <option value="inr">INR</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Stripe Product ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Stripe product ID"
                  name="stripeProductId"
                  value={planData.stripeProductId}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Stripe Monthly Price ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Stripe monthly price ID"
                  name="stripeMonthlyPriceId"
                  value={planData.stripeMonthlyPriceId}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Stripe Annual Price ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Stripe annual price ID"
                  name="stripeAnnualPriceId"
                  value={planData.stripeAnnualPriceId}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Seat Type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter seat type"
                  name="seatType"
                  value={planData.seatType}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Trial Days</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter trial days"
                  name="trialDays"
                  value={planData.trialDays}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Maximum Users</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter maximum number of users"
                  name="maxUsers"
                  value={planData.maxUsers}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Maximum Storage (in GB)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter maximum storage in GB"
                  name="maxStorage"
                  value={planData.maxStorage}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Features</Form.Label>
                {planData.features.map((item, index) => (
                  <Row key={index} className="mb-2">
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        placeholder="Enter feature"
                        value={item.feature}
                        onChange={(e) =>
                          handleFeatureChange(index, e.target.value)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        variant="danger"
                        onClick={() => removeFeature(index)}
                      >
                        <FaTrashAlt />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button variant="success" onClick={addFeature}>
                  <FaPlus /> Add Feature
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              {editMode ? "Update" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* View Plan Modal */}
        <Modal show={viewModal} onHide={() => setViewModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Plan Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentPlan && (
              <Card>
                <Card.Body>
                  <Card.Title>{currentPlan.name}</Card.Title>
                  <Card.Text>
                    Meta Description: {currentPlan.metaDescription}
                    <br />
                    Monthly Price: {currentPlan.monthlyPrice}
                    <br />
                    Annual Price: {currentPlan.annualPrice}
                    <br />
                    Currency: {currentPlan.currency.toUpperCase()}
                    <br />
                    Features:
                    <ul>
                      {currentPlan.features.map((f, i) => (
                        <li key={i}>{f.feature}</li>
                      ))}
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setViewModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Plan;

// reducers/notificationReducer.js
import { NOTIFICATION_TYPES } from "../constants/notificationTypes";

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.CREATE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case NOTIFICATION_TYPES.FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case NOTIFICATION_TYPES.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification._id === action.payload.id
            ? { ...notification, ...action.payload }
            : notification
        ),
      };
    case NOTIFICATION_TYPES.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default notificationReducer;

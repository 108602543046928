import { PHARMACY_TYPES } from "../constants/pharmacyTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import { fetchPharmacy, fetchAllPharmacies } from "../../api/pharmacyApi";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Fetch Pharmacy Action
export const fetchPharmacyAction = (pharmacyId) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchPharmacy(pharmacyId);

    dispatch({
      type: PHARMACY_TYPES.FETCH_PHARMACY,
      payload: res.data,
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false, message: "Pharmacy fetched" },
    });
  } catch (error) {
    ErrorNotification(
      error.response?.data?.message || "Failed to fetch pharmacy"
    );

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        loading: false,
        message: error.response?.data?.message || "Failed to fetch pharmacy",
      },
    });
  }
};
//   Fetch All Pharmacy Action
export const fetchAllPharmacyAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchAllPharmacies();
    dispatch({
      type: PHARMACY_TYPES.FETCH_ALL_PHARMACY,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

import api from "./index";

// Check current user
export const currentUser = () => {
  return api.post("/currentUser");
};

// Get an user by ID
export const getUserById = (id) => {
  return api.get(`/user/${id}`);
};

// Update user by Id
export const updateUserById = async (userId, userData) => {
  try {
    const response = await api.put(`/user/${userId}`, userData);
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return {
        success: false,
        message: "Update failed with status: " + response.status,
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        message: error.response.data.message || "An error occurred",
      };
    } else {
      return { success: false, message: error.message };
    }
  }
};
// Upload User Profile Image
export const uploadUserProfileImage = async (id, imageData) => {
  try {
    const response = await api.post(`/user/${id}/profile-picture`, imageData);
    return response.data;
  } catch (error) {
    console.error("Upload error:", error);
    return { success: false, message: error.message };
  }
};

// Super Admin
// Users List
export const getAllUsers = () => {
  return api.get("/users-list");
};

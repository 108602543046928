import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingToRedirectCard from "../Components/Loaders/LoadingToRedirectCard";
import VerticalLayout from "../layouts/VerticalLayout";
import {
  isSuperAdminAction,
  isAdminCheck,
  isManagerCheck,
  isDriverCheck,
  isGeneralCheck,
} from "../redux/actions/authActions";
const ProtectedRoute = ({ element, requiredRoles, ...rest }) => {
  const dispatch = useDispatch();
  const [ok, setOk] = useState(false);
  const [roles, setRoles] = useState("");

  // Access user data from Redux store
  const user = useSelector((state) => state.auth.user);
  const pharmacyId = user ? user.pharmacy : null;
  useEffect(() => {
    if (requiredRoles.includes("superadmin")) {
      dispatch(isSuperAdminAction({ setOk }));
      setRoles("superadmin");
    } else if (requiredRoles.includes("admin")) {
      dispatch(isAdminCheck({ setOk }));
      setRoles("admin");
    } else if (requiredRoles.includes("manager")) {
      dispatch(isManagerCheck({ setOk }));
      setRoles("manager");
    } else if (requiredRoles.includes("driver")) {
      dispatch(isDriverCheck({ setOk }));
      setRoles("driver");
    } else if (requiredRoles.includes("general")) {
      dispatch(isGeneralCheck({ setOk }));
      setRoles("general");
    }
  }, [dispatch, requiredRoles]);

  return ok ? (
    <VerticalLayout roles={roles} pharmacyId={pharmacyId}>
      {element}
    </VerticalLayout>
  ) : (
    <LoadingToRedirectCard />
  );
};

export default ProtectedRoute;

import React, { useEffect, useState } from "react";
import "./createPatient.css";
import {
  createPatientByPharmacyAction,
  updatePatientByPharmacyAction,
  deletePatientByPharmacyAction,
  fetchPatientsByPharmacyAction,
} from "../../../redux/actions/patientAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { MdHome, MdLocalHospital } from "react-icons/md";
import { FaRegEye, FaRegEdit, FaTrash } from "react-icons/fa";
const formatDateForSearch = (dateString) => {
  const date = new Date(dateString);
  return `${String(date.getDate()).padStart(2, "0")}:${String(
    date.getMonth() + 1
  ).padStart(2, "0")}:${date.getFullYear()}`;
};

function CreatePatient() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const user = useSelector((state) => state.auth.user);

  const formInitialState = {
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    nhsNumber: "",
    dateOfBirth: "",
    displayName: "",
    email: "",
    mobileNo: "",
    alternativeContactNo: "",
    landlineNo: "",
    dependentContactNo: "",
    status: "active",
    addressLine1: "",
    addressLine2: "",
    postCode: "",
    country: "",
    deliveryType: "",
    patientType: "",
    service: "",
    careHome: "",
    consentGiven: "no",
  };

  const [formData, setFormData] = useState(formInitialState);
  const [mode, setMode] = useState("add");
  const [searchPatient, setSearchPatient] = useState("");
  const [viewId, setViewId] = useState("");
  const [selectedMember, setSelectedMember] = useState({});
  const [radioSelection, setRadioSelection] = useState("address"); // 'address' or 'careHome'
  const [validationErrors, setValidationErrors] = useState({}); // Validation errors state

  // View Patient
  const [showPatientModal, setShowPatientModal] = useState(false);

  // Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // useSelector
  const careHomes = useSelector((state) => state.careHome.careHomes);

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };
  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const patientsFromRedux = useSelector((state) => {
    // Assuming your patient reducer is combined under the key 'patient'
    return state.patient.patients.map((patient) => {
      return {
        ...patient,
        dateOfBirth: formatDateForSearch(patient.dateOfBirth),
      };
    });
  });

  // Open Patient
  const handleOpenPatientModal = (patient = null) => {
    if (patient) {
      // Populate the form data for editing
      selectPatient(patient); // Populate this function to set form data for edit
      setMode("edit"); // Assuming you have a mode state to differentiate add/edit
      setSelectedMember(patient);
    } else {
      setFormData(formInitialState); // Clear form for adding
      setMode("add");
    }
    setShowPatientModal(true);
  };
  const convertDateToISOFormat = (dateString) => {
    // Split the date string which is expected to be in DD:MM:YYYY format
    const [day, month, year] = dateString.split(":");
    // Return in ISO format (YYYY-MM-DD)
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  const selectPatient = (patient) => {
    const isoDateOfBirth = convertDateToISOFormat(patient.dateOfBirth);
    setFormData({
      title: patient.title,
      firstName: patient.firstName,
      middleName: patient.middleName,
      lastName: patient.lastName,
      gender: patient.gender,
      nhsNumber: patient.nhsNumber,
      dateOfBirth: isoDateOfBirth,
      displayName: patient.displayName,
      email: patient.email,
      mobileNo: patient.mobileNo,
      alternativeContactNo: patient.alternativeContactNo,
      landlineNo: patient.landlineNo,
      dependentContactNo: patient.dependentContactNo,
      status: patient.status,
      addressLine1: patient.addressLine1,
      addressLine2: patient.addressLine2,
      postCode: patient.postCode,
      country: patient.country,
      deliveryType: patient.deliveryType,
      service: patient.service,
      nursingHome: patient.nursingHome,
      careHome: patient.careHome,
      consentGiven: patient.consentGiven || "no",
    });
  };

  const handleClosePatientModal = () => {
    setShowPatientModal(false);
    setValidationErrors({}); // Clear validation errors on close
  };

  // Delete
  const handleShowDeleteModal = (patient) => {
    setSelectedMember(patient); // Assuming you have a state `selectedMember` to keep track of the patient to delete
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeletePatient = () => {
    // Assuming you have a dispatch action to delete a patient
    dispatch(deletePatientByPharmacyAction(pharmacyId, selectedMember._id))
      .then(() => {
        setShowDeleteModal(false); // Close modal on successful deletion
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Patient ${selectedMember.firstName} ${selectedMember.lastName} has been deleted.`,
            user: user._id,
            forRole: ["admin"],
            priority: "high",
          })
        );
        getPatients(); // Refresh the patient list
      })
      .catch((err) => {
        ErrorNotification("Error Deleting Patient");
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (
      e.target.name === "landlineNo" ||
      e.target.name === "mobileNo" ||
      e.target.name === "dependentContactNo" ||
      e.target.name === "alternativeContactNo"
    ) {
      if (e.target.value.length <= 12) {
        // Use <= to include exactly 12 characters
        setFormData({
          ...formData,
          [name]: fieldValue,
        });
      }
    } else if (e.target.name === "nhsNumber") {
      if (e.target.value.length <= 10) {
        // Use <= to include exactly 10 characters
        setFormData({
          ...formData,
          [name]: fieldValue,
        });
      }
    } else if (e.target.name === "postCode") {
      if (e.target.value.length <= 8) {
        // Use <= to include exactly 8 characters
        setFormData({
          ...formData,
          [name]: fieldValue,
        });
      }
    } else if (e.target.name === "firstName") {
      setFormData({
        ...formData,
        firstName: fieldValue,
        displayName: fieldValue,
      });
    } else if (name === "dateOfBirth") {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        setValidationErrors({
          ...validationErrors,
          dateOfBirth: "Date of Birth cannot be a future date.",
        });
      } else {
        // Clear validation error if date is correct and update form data
        const newErrors = { ...validationErrors };
        delete newErrors.dateOfBirth; // Remove dateOfBirth error
        setValidationErrors(newErrors);
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.title.trim()) {
      errors.title = "Title is required";
    }
    if (!formData.firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!formData.nhsNumber.trim()) {
      errors.nhsNumber = "NHS Number is required";
    }
    if (!formData.dateOfBirth.trim()) {
      errors.dateOfBirth = "Date of Birth is required";
    }
    if (radioSelection === "address") {
      if (!formData.addressLine1.trim()) {
        errors.addressLine1 = "Address Line 1 is required";
      }
      if (!formData.postCode.trim()) {
        errors.postCode = "Post Code is required";
      }
    } else if (radioSelection === "careHome") {
      if (!formData.careHome.trim()) {
        errors.careHome = "Care Home is required";
      }
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      const firstErrorField = document.querySelector(
        ".is-invalid, [aria-invalid='true']"
      );
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    if (mode === "add") {
      dispatch(createPatientByPharmacyAction(pharmacyId, formData))
        .then(() => {
          setFormData(formInitialState); // Reset form fields
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `A new patient, ${formData.firstName} ${formData.lastName}, has been created.`,
              user: user._id,
              forRole: ["admin", "manager"],
              priority: "high",
            })
          );
          getPatients(); // Fetch updated list of patients
          setShowPatientModal(false);
        })
        .catch((error) => {
          console.error("Error creating patient:", error);
          // Handle error scenario
          if (error.response && error.response.status === 400) {
            getPatients(); // Fetch updated list of patients if needed
          }
        });
    } else if (mode === "edit") {
      dispatch(
        updatePatientByPharmacyAction(pharmacyId, selectedMember._id, formData)
      )
        .then(() => {
          // Handle success for updating a patient
          setShowPatientModal(false);
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `Patient details for ${formData.firstName} ${formData.lastName} updated successfully.`,
              user: user._id,
              forRole: ["admin", "manager"],
              priority: "medium",
            })
          );
          setSelectedMember("");
        })
        .catch((error) => {
          console.error("Error updating patient:", error);
        });
    }
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  // View Patient Modal
  const [showViewPatientModal, setShowViewPatientModal] = useState(false);

  // Function to open View Patient Modal
  const handleOpenViewPatientModal = (patientId) => {
    setViewId(patientId); // Assuming setViewId updates state to the selected patient's ID
    setShowViewPatientModal(true);
  };

  // Function to close View Patient Modal
  const handleCloseViewPatientModal = () => {
    setShowViewPatientModal(false);
  };

  useEffect(() => {
    getPatients();
    getCareHomes();
  }, []);

  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {});
  };

  const handleSearchChange = (e) => {
    const trimmedValue = e.target.value.trim().toLowerCase();
    setSearchPatient(trimmedValue);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  // Calculate the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentPatients = patientsFromRedux
    .filter((patient) => {
      const dobFormatted = patient.dateOfBirth
        ? patient.dateOfBirth.toLowerCase()
        : "";
      const address = patient.addressLine1?.toLowerCase() || "";
      const postcode = patient.postCode?.toLowerCase() || "";

      return (
        patient.firstName?.toLowerCase().includes(searchPatient) ||
        patient.lastName?.toLowerCase().includes(searchPatient) ||
        patient.middleName?.toLowerCase().includes(searchPatient) ||
        patient.nhsNumber?.toLowerCase().includes(searchPatient) ||
        dobFormatted.includes(searchPatient) ||
        address.includes(searchPatient) ||
        postcode.includes(searchPatient)
      );
    })
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(patientsFromRedux.length / itemsPerPage);

  return (
    <div className="mt-100">
      <div className="container-fluid">
        <div className="search-input-box">
          <input
            type="text"
            placeholder="Search By Name, NHS, Address, DOB, Postcode"
            onChange={handleSearchChange}
            className="search-input"
          />
          <Button
            className="medtrakr-btn"
            onClick={() => handleOpenPatientModal()}
          >
            Add Patient
          </Button>
        </div>

        <div className="icon-ledger-container">
          <div>
            <div className="d-flex align-items-center">
              <MdHome size={24} color="green" style={{ marginRight: "8px" }} />
              <h6>Patient Address:</h6>
            </div>
            <div className="d-flex align-items-center">
              <MdLocalHospital
                size={24}
                color="blue"
                style={{ marginRight: "8px" }}
              />
              <h6>Care Home Address:</h6>
            </div>
          </div>
          <div>
            {/* View Patient Modal  Icon */}
            <div className="d-flex align-items-center">
              <FaRegEye
                size={22}
                style={{
                  marginRight: "8px",
                  backgroundColor: "#e0f6f6",
                  color: "#4b6c70",
                }}
              />
              <h6>View Patient</h6>
            </div>
            {/* Edit Patient Modal  Icon */}
            <div className="d-flex align-items-center">
              <FaRegEdit
                size={22}
                style={{
                  marginRight: "8px",
                  backgroundColor: "#e2f6ed ",
                  color: "#2caf48 ",
                }}
              />
              <h6>Edit Patient</h6>
            </div>
            {/* Delete Patient Modal  Icon */}
            <div className="d-flex align-items-center">
              <FaTrash
                size={20}
                style={{
                  marginRight: "8px",
                  backgroundColor: "#fde2e7 ",
                  color: "#ea3c5d ",
                }}
              />
              <h6>Delete Patient</h6>
            </div>
          </div>
        </div>

        <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>NHS Number</th>
                <th>DOB</th>
                <th>Address</th>
                <th>Service</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentPatients.map((patient) => (
                <tr key={patient.id}>
                  <td>{patient?.firstName + " " + patient?.lastName}</td>
                  <td>{patient.nhsNumber}</td>
                  <td>{patient.dateOfBirth}</td>
                  <td>
                    {patient?.careHome ? (
                      <div className="create-patient-address">
                        <div className="d-flex align-items-center">
                          <MdLocalHospital
                            size={24}
                            color="blue"
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div>
                          <p className="create-patient-carehome-name">
                            {careHomeName(patient?.careHome)}
                          </p>
                          <div>{careHomeAddress(patient?.careHome)}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="create-patient-address">
                        <div className="d-flex align-items-center">
                          <MdHome
                            size={24}
                            color="green"
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div>
                          {patient?.addressLine1 &&
                            `${
                              patient?.addressLine1.trim().endsWith(",")
                                ? patient?.addressLine1
                                : patient?.addressLine1 + ","
                            } `}
                          {patient?.addressLine2 &&
                            `${
                              patient?.addressLine2.trim().endsWith(",")
                                ? patient?.addressLine2
                                : patient?.addressLine2 + ","
                            } `}
                          {patient?.townName && `${patient?.townName}, `}
                          {patient?.postCode}
                        </div>
                      </div>
                    )}
                  </td>
                  <td>
                    {patient.service.charAt(0).toUpperCase() +
                      patient.service.slice(1)}
                  </td>
                  <td>
                    <button
                      className="btn view "
                      onClick={() => {
                        handleOpenViewPatientModal(patient._id);
                      }}
                      style={{ marginRight: "0.5rem" }}
                    >
                      <FaRegEye
                        size={22}
                        style={{
                          backgroundColor: "#e0f6f6",
                          color: "#4b6c70",
                        }}
                      />
                    </button>

                    <button
                      className="btn update"
                      onClick={() => handleOpenPatientModal(patient)}
                      style={{ marginRight: "0.5rem" }}
                    >
                      <FaRegEdit
                        size={22}
                        style={{
                          backgroundColor: "#e2f6ed ",
                          color: "#2caf48 ",
                        }}
                      />
                    </button>

                    <button
                      className="btn delete"
                      onClick={() => handleShowDeleteModal(patient)}
                    >
                      <FaTrash
                        size={22}
                        style={{
                          backgroundColor: "#fde2e7 ",
                          color: "#ea3c5d ",
                        }}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="page-number-box">
            <div>
              <select
                className="form-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[5, 10, 15, 20].map((number) => (
                  <option key={number} value={number}>
                    {number} Patients/page
                  </option>
                ))}
              </select>
            </div>
            <div className="pagination">
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber + 1)}
                  className={`page-link ${
                    currentPage === pageNumber + 1 ? "active" : ""
                  }`}
                >
                  {pageNumber + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* View Patient Modal */}
      <Modal
        show={showViewPatientModal}
        onHide={handleCloseViewPatientModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            {patientsFromRedux
              .filter((patient) => patient._id === viewId)
              .map((patient) => (
                <React.Fragment key={patient._id}>
                  <h3 className="heading-color">Personal Information</h3>
                  <div className="row">
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Title:</span> {patient.title}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">NHS Number: </span>{" "}
                      {patient.nhsNumber}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">First Name: </span>{" "}
                      {patient.firstName}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Gender: </span> {patient.gender}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Middle Name: </span>{" "}
                      {patient.middleName}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Date of Birth: </span>{" "}
                      {patient.dateOfBirth}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Surname: </span>{" "}
                      {patient.lastName}
                    </div>
                  </div>

                  <h3 className="mt-4 heading-color">Contact Information</h3>
                  <div className="row">
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Display Name:</span>{" "}
                      {patient.displayName}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Mobile No:</span>{" "}
                      {patient.mobileNo}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Email:</span> {patient.email}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Alternative Contact No:</span>{" "}
                      {patient.alternativeContactNo}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Landline No:</span>{" "}
                      {patient.landlineNo}
                    </div>
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Dependent Contact No:</span>{" "}
                      {patient.dependentContactNo}
                    </div>
                  </div>

                  <h3 className="mt-4 heading-color">Address Information</h3>
                  {patient.careHome ? (
                    <div>
                      <h4>Care Home Address:</h4>
                      {/* Display Care Home Address */}
                      <div>
                        <span className="fw-bold">Care Home Name:</span>{" "}
                        {careHomeName(patient.careHome)}
                      </div>
                      <div>
                        <span className="fw-bold">Address:</span>{" "}
                        {careHomeAddress(patient.careHome)}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* Display Patient's Address */}
                      <div className="row">
                        <div className="col-md-6 mt-1">
                          <span className="fw-bold">Address Line 1:</span>{" "}
                          {patient.addressLine1}
                        </div>
                        <div className="col-md-6 mt-1">
                          <span className="fw-bold">Address Line 2:</span>{" "}
                          {patient.addressLine2}
                        </div>
                        <div className="col-md-6 mt-1">
                          <span className="fw-bold">Town Name:</span>{" "}
                          {patient.townName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <span className="fw-bold">Post Code:</span>{" "}
                          {patient.postCode}
                        </div>
                      </div>
                    </div>
                  )}

                  <h3 className="mt-4 heading-color">Service Information</h3>
                  <div className="row">
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Service Type:</span>{" "}
                      {patient.service}
                    </div>
                  </div>

                  <h3 className="mt-4 heading-color">Consent given</h3>
                  <div className="row">
                    <div className="col-md-6 mt-1">
                      <span className="fw-bold">Consent Given:</span>{" "}
                      {patient.consentGiven}
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewPatientModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add / Edit Patient Modal */}
      <Modal
        show={showPatientModal}
        onHide={handleClosePatientModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "add" ? "Create a patient" : "Edit Patient"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <Form.Group className="col-md-6">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="select"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.title}
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {validationErrors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={formData.firstName}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="text"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Surname *</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  required
                  value={formData.lastName}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>NHS Number *</Form.Label>
                <Form.Control
                  type="number"
                  name="nhsNumber"
                  value={formData.nhsNumber}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={handleChange}
                  isInvalid={!!validationErrors.nhsNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.nhsNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Date of Birth *</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth} // This should be in 'YYYY-MM-DD' format
                  onChange={handleChange}
                  isInvalid={!!validationErrors.dateOfBirth}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.dateOfBirth}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Contact Information */}
              <Form.Group className="col-md-6">
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  type="text"
                  name="displayName"
                  value={formData.displayName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Check
                  type="checkbox"
                  label="Email Notification"
                  name="sendEmail"
                  checked={formData.sendEmail}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Mobile No</Form.Label>
                <Form.Control
                  type="number"
                  name="mobileNo"
                  value={formData.mobileNo}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Consent Given?</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Yes"
                    name="consentGiven"
                    type="radio"
                    value="yes"
                    checked={formData.consentGiven === "yes"}
                    onChange={handleChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="consentGiven"
                    type="radio"
                    value="no"
                    checked={formData.consentGiven === "no"}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Check
                  type="checkbox"
                  label="SMS Notification"
                  name="sendMessage"
                  checked={formData.sendMessage}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Alternative Contact No</Form.Label>
                <Form.Control
                  type="number"
                  name="alternativeContactNo"
                  value={formData.alternativeContactNo}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Landline No</Form.Label>
                <Form.Control
                  type="number"
                  name="landlineNo"
                  value={formData.landlineNo}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Dependent Contact No</Form.Label>
                <Form.Control
                  type="number"
                  name="dependentContactNo"
                  value={formData.dependentContactNo}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={handleChange}
                />
              </Form.Group>

              {/* Service Information */}
              <Form.Group className="col-md-6">
                <Form.Label>Patient Type</Form.Label>
                <Form.Control
                  as="select"
                  name="patientType"
                  required
                  value={formData.patientType}
                  onChange={(e) =>
                    setFormData({ ...formData, patientType: e.target.value })
                  }
                >
                  <option value="normal">Regular</option>
                  <option value="mds">MDS</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Service Type</Form.Label>
                <Form.Control
                  as="select"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                >
                  <option value="">Select Service Type</option>
                  <option value="collection">Collection</option>
                  <option value="delivery">Delivery</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Select Patient Address Type</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Address"
                    name="address"
                    type="radio"
                    id="radioAddress"
                    value="address"
                    checked={radioSelection === "address"}
                    onChange={(e) => setRadioSelection(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Care Home"
                    name="careHome"
                    type="radio"
                    id="radioCareHome"
                    value="careHome"
                    checked={radioSelection === "careHome"}
                    onChange={(e) => setRadioSelection(e.target.value)}
                  />
                </div>
              </Form.Group>

              {radioSelection === "careHome" && (
                <>
                  {/* Care Home Dropdown */}
                  <Form.Group className="col-md-6">
                    <Form.Label>Care Home</Form.Label>
                    <Form.Control
                      as="select"
                      name="careHome"
                      value={formData.careHome}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          careHome: e.target.value,
                        })
                      }
                      isInvalid={!!validationErrors.careHome}
                    >
                      <option value="">Select Care Home</option>
                      {careHomes.map((obj) => (
                        <option key={obj._id} value={obj._id}>
                          {obj.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {validationErrors.careHome}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}

              {radioSelection === "address" && (
                <>
                  {/* Address Information */}
                  <Form.Group className="col-md-6">
                    <Form.Label>Address Line 1 *</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLine1"
                      required
                      value={formData.addressLine1}
                      onChange={handleChange}
                      isInvalid={!!validationErrors.addressLine1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validationErrors.addressLine1}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="addressLine2"
                      value={formData.addressLine2}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Town Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="townName"
                      value={formData.townName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Post Code *</Form.Label>
                    <Form.Control
                      type="text"
                      name="postCode"
                      required
                      value={formData.postCode}
                      onChange={handleChange}
                      isInvalid={!!validationErrors.postCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {validationErrors.postCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {/* Alternative Address (Optional) */}
                  <Form.Group className="col-md-6">
                    <Form.Label>Alternative Address Line 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="alternativeaddressLine1"
                      value={formData.alternativeaddressLine1}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Alternative Address Line 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="alternativeaddressLine2"
                      value={formData.alternativeaddressLine2}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Alternative Town Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="alternativetownName"
                      value={formData.alternativetownName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="col-md-6">
                    <Form.Label>Alternative Post Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="alternativepostCode"
                      value={formData.alternativepostCode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePatientModal}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              {mode === "add" ? "Add Patient" : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this patient?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeletePatient}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreatePatient;

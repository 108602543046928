import React from "react";
import "./terms.css";
export default function Terms() {
  return (
    <>
      <div
        className="container"
        style={{
          width: "90%",
          display: "block",
          margin: "auto",
          backgroundColor: "lightgray",
          borderRadius: 20,
          border: "2px solid gray",
          padding: "1.5rem",
        }}
      >
        <h1>Terms &amp; Condition</h1>
        <article>
          <p>
            This document sets out the terms for using the MedTrakr.com services
            and governs both our software or services ("services"), and our
            end-users. When we refer to "You" within the document, we mean
            Users, pharmacy, patients and Driver. MedTrakr.com is committed to
            transparency, including providing a Terms of Use that is
            understandable and written in plain English. Please take your time
            to read this document, for it represents our Agreement with you and
            about how you use our Platform.
          </p>
          <p>
            Our Terms of Use ("Agreement") also includes our Privacy Policy and
            Cookies Policy, any other documents referred to by those agreements,
            form a whole, which governs any access to the MedTrakr.com services,
            whether you are a visitor, user, pharmacy, driver, etc.
          </p>
        </article>
        <ol>
          <l1>General</l1>
          <l1>Who We Are</l1>
          <l1>Age Required for Access</l1>
          <l1>Website Purpose and Services</l1>
          <l1>Description of Services</l1>
          <l1>Subscription</l1>
          <l1>Privacy and Data Policy</l1>
          <l1>Ownership, License</l1>
          <l1>Registering an Account </l1>
          <l1>Placing an Order</l1>
          <l1>Our Contractual Agreement</l1>
          <l1>Third-party Payment Processor</l1>
          <l1>Acceptable Use</l1>
          <l1>Content Standards</l1>
          <l1>Platform Guidelines</l1>
          <l1>Cancellation</l1>
          <l1>No Refunds Policy</l1>
          <l1>Intellectual Property Rights</l1>
          <l1>Trademark License</l1>
          <l1>Software Available On This Web Site</l1>
          <l1>Application License</l1>
          <l1>Customer or User Feedback</l1>
          <l1>Third-Party website and Interactions</l1>
          <l1>Disclaimer of Warranties</l1>
          <l1>Loss or Damage Suffered By You</l1>
          <l1>Termination</l1>
          <l1>Our Site and Software May Change Regularly</l1>
          <l1>Data Protection Policy</l1>
          <l1>Indemnification</l1>
          <l1>Termination</l1>
          <l1>Privacy policy</l1>
          <l1>Claims of Copyright Infringement</l1>
          <l1>No Joint Venture or Partnership</l1>
          <l1>Communications</l1>
          <l1>Severability</l1>
          <l1>Dispute Resolution</l1>
          <l1>Choice of Law</l1>
          <l1>End User Agreement</l1>
          <l1>Copyright License</l1>
          <l1>Complaints</l1>
        </ol>
        <h2>General</h2>
        <article>
          <p>
            These Terms and Conditions govern your Use of this website, which
            provides information about, MedTrakr.com the “MedTrakr.com” web
            Software, any MedTrakr.com Services, and other related products and
            services we offer. We are www.MedTrakr.com. (“MedTrakr”) and we may
            refer to ourselves in these Terms and Conditions as “we,” “us,” or
            “our”. This website also provides you a link with the means to
            register with us so you can use our website or app and receive the
            related services.
          </p>
          <p>
            Please read these terms carefully before ordering any items from our
            website or Software. If you have any questions relating to these
            terms, please contact us on info@medtrakr.com before you place an
            order.
          </p>
        </article>
        <h2>Who We are</h2>
        <p>
          MedTrakr.com provides a Software used by Pharmacy, customer and
          general practice via the MedTrakr.com website. MedTrakr.com is a
          business registered in the United Kingdom. With registered office 207
          Springwell Road, Hounslow, TW5 9BN. Branded as MedTrakr.com ("we") and
          covers all MedTrakr.com services made pursuant to this website.
        </p>
        <h2>Age Required for Access</h2>
        <p>
          The Services and Website are intended only to persons who are the age
          of majority and can form legally binding contracts under applicable
          law. If you are between the ages of 13 and 18, you must have your
          parent or guardian's permission to use the Platform. Without limiting
          the preceding, the Services and Website are not intended to be used by
          individuals under 13. If you are under the age of 13, you may not use
          our Platform in any manner, nor may you register for an account
        </p>
        <h2>Website Purpose and Services</h2>
        <p>
          Our objective is to provide fully integrated software system to
          Pharmacy so customers can place orders for pharmacy related products
          (our “services”). Where you order from a Pharmacy, MedTrakr.com acts
          as a facilitator that provides a pharmacy software the Pharmacy owners
          can use to enhance the productivity of their business. By providing
          the following products and services below
        </p>
        <ol>
          <li>Remainder services</li>
          <li>Rx Delivery Services</li>
          <li>Rx Collection Services</li>
          <li>MDS Rx management</li>
          <li>Notifications</li>
        </ol>
        <h2>1. Description of Services</h2>
        <article>
          <p>
            MedTrakr.com provides a web application that connects pharmacy,
            users and drivers. We enable an easy-to-use platform interface for
            pharmacy owners and drivers to register and list their services and
            medication on the platform. We also provide a user an opportunity to
            search for and place an order for a medication of their choice which
            will be delivered by registered and approved drivers of the
            platform.
          </p>
          <p>
            MedTrakr.com services act only as a facilitator, we provide the
            online medium for user’s pharmacy and drivers to connect and
            transact. We do not provide medication or delivery service, we only
            provide the platform for service providers, who offer their service
            for sale on the MedTrakr.com website.
          </p>
        </article>
        <h2>1. Subscription</h2>
        <p>
          Certain features of the Service may require a pharmacy to purchase our
          monthly subscription to unlock some amazing features. All fees and
          applicable taxes, if any, are payable via credit cards. All purchases
          are subject to acceptance by us, and we will confirm such acceptance
          to you by sending you a confirmation email to confirm your purchase.
          The contracts between us and you will only be formed when we send you
          the Order Confirmation Email.
        </p>
        <h2>1. Privacy and Data Policy</h2>
        <p>
          MedTrakr values privacy. We will never sell or distribute personal
          data to partners for profit through submission of your data on the
          Site. From time to time, data collected via direct campaigns with
          partner companies may be shared with these partners. These valuable
          partnerships do not allow for the sale or distribution of personal
          data. By using our Services, you agree that the MedTrakr.com can use
          data including, but not limited to, periodic emails announcing news
          and product updates.
        </p>
        <h2>1. Ownership, License</h2>
        <article>
          <p>
            Use unless otherwise stated, the Site and its contents are owned and
            operated by MedTrakr.com. This Site, and all materials appearing on
            them are protected by copyright and may not be used without prior
            written permission. Other trademarks or copyrighted materials
            appearing on the Site are the property of their respective owners
            and may not be used without permission.
          </p>
          <p>
            You may access and use the materials and information on our Site as
            they appear there for your own personal Use, educational
            advancement, or professional development. Any materials that you
            view for your own Use must maintain all copyright or other notices.
            Except as we expressly permit, any redistribution, retransmission,
            commercial exploitation, linking, or other uses are strictly
            prohibited. You shall not copy or edit the materials, or integrate
            them into any other media. You shall not claim ownership or
            authorship, or otherwise use the materials except as we expressly
            permit. You shall not hack into our Site or text messages, or
            otherwise gain unauthorized access to or make improper Use of our
            content or Site
          </p>
        </article>
        <h2>1. Registering an Account</h2>
        <p>
          Before you can use our services, website and software; you will have
          to register an account with us. We provide 3 type of account
          registration, namely;
        </p>
        <ul>
          <li>User Account</li>
          <li>Pharmacy Account</li>
          <li>Driver Account</li>
        </ul>
        <article>
          <p>
            To place a delivery on the platform, you will be required to create
            a user account on the website.
          </p>
          <p>
            If you are a driver you will have to create a Driver account with us
            to be provide a driver or delivery service on the platform. If you
            are a Pharmacy owner, you will be directed to purchase our monthly
            subscription package. When you create an account, we will ask you
            for certain data such as your name, mobile number, and email
            address, if you are a driver, we will collect your name, driver
            license, email address, vehicle particulars etc., which will be
            stored with MedTrakr.com to facilitate your transactions. Every
            Pharmacy account may hold patients data for eg. Name, address, nhs
            no,mobile no, email address etc.
          </p>
          <p>
            You may close your account at any time by requesting to do so in
            your account section of our website or contacting us using our
            contact details. We may suspend your access to your account, or
            close it permanently, if we believe that your account has been used
            by someone else. We may also close your account if in our opinion
            you are abusing our Service.
          </p>
        </article>
        <h2>1. Our Contractual Agreement</h2>
        <p>
          The Agreement is effective as from the moment the Customer finalizes
          the through the Platform.
        </p>
        <ol>
          <li>
            After receipt of the Order, MedTrakr.com will electronically confirm
            the Order made by the Customer.
          </li>
          <li>
            MedTrakr.com.com does not accept any liability relating to the
            execution of the Agreement.
          </li>
        </ol>
        <h2>1. Third-party Payment Processor</h2>
        <p>
          When you make a purchase on the MedTrakr.com site or software you will
          be directed to a third-party payment process who will collect your
          billing information, when you do this your billing information is not
          shared with MedTrakr.com but between you and the Payment Processor
          only. cc MedTrakr.com is not the seller of any product or service
          offered by a Pharmacy and is not a party to any Payment Transaction
          facilitated through the Services.
        </p>
        <h2>1. Acceptable Use</h2>
        <p>By using the Services, you agree that:</p>
        <ul>
          <li>
            You will not use the Services in any way that violates Applicable
            Law, these Terms, or any other Policies.
          </li>
          <li>
            The information that you upload or post in connection with the
            Services does not infringe on anyone else's intellectual property or
            proprietary rights or otherwise conflict with the law or the rights
            of others.
          </li>
          <li>
            You will not upload, post or otherwise transmit through the Services
            any content that contains any viruses, trojan horses, time bombs, or
            any other harmful programs or elements.
          </li>
          <li>
            You will not provide false information about yourself to us,
            impersonate any other person, collect information about other users,
            or otherwise attempt to mislead others about your identity or the
            truthfulness or accuracy of the data you transmit through the
            Services.
          </li>
          <li>
            You may only use the Services to make Purchases of a legitimate
            product or service that is purchased from a Pharmacy. The Services
            may not be used to transfer money or process a Payment Transaction
            that is unrelated to a purchase of a product or service from a
            Pharmacy.
          </li>
          <li>
            You will not damage, disable, disrupt, overburden, interfere with,
            or attempt to gain unauthorized access to any portion of our
            Services, computer systems, servers or networks, or any other
            person’s Use and enjoyment of the Services.
          </li>
          <li>
            MedTrakr.com may suspend or terminate your Use of the Services at
            its sole discretion for any actual or suspected violation of these
            limitations or other applicable policies or rules referenced in
            these Terms of Service.
          </li>
        </ul>
        <h2>1. Content Standards</h2>
        <p>
          These content standards apply to any and all material which you
          contribute to our Service (the "Contributions"), and to any
          interactive services associated with it. You must comply with the
          spirit of the following standards as well as the letter. The standards
          apply to each part of any Contributions as well as to its whole.
          Contributions must be accurate (where they state facts), be genuinely
          held (where they state opinions) and comply with applicable law the
          Israel and in any country from which they are posted. Contributions
          must not:
        </p>
        <ul>
          <li>
            contain any material which is defamatory of any person, obscene,
            offensive, hateful or inflammatory, promote sexually explicit
            material or promote violence or promote discrimination based on
            race, sex, religion, nationality, disability, sexual orientation or
            age;
          </li>
          <li>
            infringe any copyright, database right or trademark of any other
            person;
          </li>
          <li>
            be likely to deceive any person or be made in breach of any legal
            duty owed to a third party, such as a contractual duty or a duty of
            confidence or promote any illegal activity;
          </li>
          <li>
            be threatening, abuse or invade another's privacy, or cause
            annoyance, inconvenience or needless anxiety or be likely to harass,
            upset, embarrass, alarm or annoy any other person;
          </li>
          <li>
            be used to impersonate any person, or to misrepresent your identity
            or affiliation with any person or give the impression that they
            emanate from us, if this is not the case; or
          </li>
          <li>
            Advocate, promote or assist any unlawful act such as (by way of
            example only) copyright infringement or computer misuse.
          </li>
        </ul>
        <h2>1. Platform Guidelines</h2>
        <p>
          We created this set of rules to protect the activity on our website
          and services. Please read them carefully, for it makes up the
          conditions for using our Platform.
        </p>
        <ul>
          <li>
            <b>Zero tolerance to Illegal Activity:</b> we do not allow illegal
            activity on the Platform. Each of your actions and contents posted
            or uploaded via the Platform must not violate any applicable
            federal, state, local, or international law or regulation
            (including, without limitation, any laws regarding the export of
            data or software to and from the US or other countries)
          </li>
          <li>
            <b>No Fraud:</b> we do not tolerate Fraud in any capacity
          </li>
          <li>
            <b>No Interference.</b> You may not interfere with any other user's
            ability to use or enjoy our Services.
          </li>
          <li>
            <b>No Bullying.</b> You may not use our Services to threaten, abuse,
            harass, or invade the privacy of any third party.
          </li>
          <li>
            <b>Content Posted by You Must be yours. </b> may not upload, post,
            transmit or otherwise distribute or facilitate the distribution of
            content that is fraudulent or infringes on the rights of any third
            party, including, without limitation, patent, trademark, copyright,
            right of publicity, or other proprietary rights.
          </li>
          <li>
            <b>Your Materials Must Be Lawful and Appropriate.</b> You may not
            upload, post, or otherwise distribute or facilitate any unlawful,
            threatening, abusive, libelous, defamatory, obscene, pornographic,
            profane, indecent, or otherwise inappropriate information of any
            kind, including, without limitation, any images or other material of
            a sexual nature.
          </li>
          <li>
            <b>Don't Damage Our Services or Servers.</b> You may not post or
            facilitate the distribution of software viruses or any computer code
            designed to disrupt, damage, or limit our Services' or obtain
            unauthorized access to our Services or Content or any data or other
            information of any third party.
          </li>
          <li>
            <b>No Unauthorized Access.</b> You may not attempt to gain
            unauthorized access to other computer systems or networks connected
            to our Services or use our Services, Content, or any information
            contained therein for any unlawful purpose. MedTrakr.com, in its
            sole and absolute discretion, shall determine whether any content
            you transmit or receive, or your Use of our Services violates this
            provision.
          </li>
          <li>
            <b>No Collection of Personal Information from Other Users.</b> You
            may not collect information about other users of our Services or use
            any such information to transmit or facilitate the transmission of
            unauthorized or unsolicited advertising, junk or bulk Email, chain
            letters, or any other form of unauthorized solicitation.
          </li>
          <li>
            <b>Be Yourself.</b> You may not impersonate or falsely state or
            otherwise misrepresent your professional or other affiliation with
            any other person or entity.
          </li>
          <li>
            <b>No Criminal or Unlawful Conduct.</b> You may not use our
            Services, Content, or any information contained therein for any
            unlawful purpose, and you may not encourage conduct that would
            constitute a criminal offense or give rise to civil liability. You
            may not use any of our Services in connection with any site or other
            Use that contains or is associated with information or content
            prohibited by this section.
          </li>
          <li>
            <b>Follow These Terms of Use and Conduct Rules.</b> You may not take
            any action on or through our Services that violates any applicable
            law or these Terms of Use. .
          </li>
          <p>
            If you violate these platform guidelines, we reserve the right to
            remove you and any of Your Content from the MedTrakr.com Platform.
            Whether conduct violates, our rules of conduct will be determined in
            MedTrakr.com 's sole discretion.
          </p>
        </ul>
        <h2>1. Cancellation</h2>
        <p>
          Users, patients, or Pharmacy retains the right to cancel their
          purchase anytime without notice within 30 days after they place an
          order on the MedTrakr.com platform. All cancellations must be informed
          in writing to info@MedTrakr.com.
        </p>
        <h2>1. No Refunds Policy</h2>
        <article>
          <p>
            Because of the nature of our business, we do not offer a refund;
            however, you can cancel your subscription at any time within 30 days
            after your purchase, but you will lose all access to your software
            without a refund for the unused portion.
          </p>
          <p>
            If you dispute any payment you have made to us for our services (by,
            for example, initiating a chargeback), we now reserve the
            irrevocable right to:
          </p>
          <ul>
            <li>
              Temporarily suspend or permanently terminate your account with us;
              or
            </li>
            <li>Rescind any or all metrics you purchased from us.</li>
          </ul>
          <p>
            If we decide to cancel an order, your payment method will not be
            refunded. If you engage in any fraudulent activity through or
            concerning the website or our services (e.g., by placing orders
            using a stolen credit card's information), we will immediately
            terminate your account with us; no exceptions
          </p>
        </article>
        <h2>1. Intellectual Property Rights</h2>
        <p>
          We are the owner of or the licensee of all intellectual property
          rights in our Site and our Service, and in the material published on
          it (excluding your Contributions). Those works are protected by
          copyright laws and treaties around the world. All such rights are
          reserved. You may not copy, reproduce, republish, download, post,
          broadcast, transmit, make available to the public, or otherwise use
          any content on our site in any way except for your own personal,
          non-commercial Use.
        </p>
        <h2>1. Trademark License</h2>
        <article>
          <p>
            Subject to the express provisions of these terms and conditions. All
            trademarks, logos and service marks (“Marks”) displayed on the
            service are MedTrakr.com property or the property of other third
            parties. You are not permitted to use these Marks without our prior
            written consent or the consent of such third party which may own the
            Marks.
          </p>
          <p>
            Unless otherwise specified in these Terms, all Materials, including
            the arrangement of them in the Services are our sole property or the
            property of our licensors. All rights not expressly granted herein
            are reserved. Except as otherwise required or limited by applicable
            law, any reproduction, distribution, modification, retransmission,
            or publication of any copyrighted material is strictly prohibited
            without the express written consent of the copyright owner.
          </p>
        </article>
        <h2>1. Software Available On This Web Site</h2>
        <p>
          Any software that is made available from the Services ("MedTrakr.com
          or MedTrakr Software") is the work of MedTrakr.com and/or its
          suppliers and is protected by copyright, trademark and other
          applicable laws. The end user license agreement, if any, which
          accompanies or is included with the MedTrakr.com Software
          (“MedTrakr.com End User License Agreement") governs its Use. An end
          user will be unable to use any MedTrakr.com Software that is
          accompanied by or includes a MedTrakr.com End User License Agreement
          unless he or she first agrees to its terms. The Software is made
          available solely for Use by end users according to the terms of the
          License Agreement. Copying or reproducing MedTrakr.com software to any
          other location for further reproduction or redistribution is expressly
          prohibited. Violators will be prosecuted to the maximum extent of the
          law.
        </p>
        <h2>1. Application License</h2>
        <p>
          MedTrakr.com grants you the right to use the Application only for your
          personal use. You must comply with all applicable laws and third party
          terms of agreement when using the Application (e.g. your wireless data
          service agreement). Your download and use of the Application is at
          your own discretion, sometimes, you might experience a slow in device
          functions, and other computer or device malfunction; the MedTrakr.com
          application might not be cause of these. Please endeavor to use an up
          to date device that can carry all functions of the MedTrakr.com
          Application.
        </p>
        <h2>1. Third-Party website and Interactions</h2>
        <p>
          The Services may contain links to third-party websites (“Third-Party
          Websites”) and Software’s (“Third-Party Software’s”) and
          advertisements (“Third-Party Advertisements”) (collectively,
          “Third-Party Websites &amp; Advertisements”). When you click on a link
          to a Third-Party Website, Third-Party Software or Third-Party
          Advertisement, the MedTrakr.com will not warn you that you have left
          the MedTrakr.com and MedTrakr.com’s Website or Services and will not
          warn you that you are subject to the terms and conditions (including
          privacy policies) of another website or destination. Such Third-Party
          Websites &amp; Advertisements are not under the control of the
          MedTrakr.com website. The MedTrakr.com is not responsible for any
          Third-Party Websites, Third-Party Software’s or any Third-Party
          Advertisements. The MedTrakr.com provides these Third-Party Websites
          &amp; Advertisements only as a convenience and does not review,
          approve, monitor, endorse, warrant, or make any representations with
          respect to such Third-Party Websites &amp; Advertisements, or their
          products or services. You use all links in Third-Party Websites &amp;
          Advertisements at your own risk. You should review applicable terms
          and policies, including privacy and data gathering practices of any
          Third-Party Websites or Third-Party Software’s, and make whatever
          investigation you feel necessary or appropriate before proceeding with
          any transaction with any third party.
        </p>
        <h2>1. Customer or User Feedback</h2>
        <p>
          You agree that any submission of any ideas, suggestions, and/or
          proposals to the MedTrakr.com through its suggestion, feedback, wiki,
          forum or similar pages (“Feedback”) is at your own risk and that the
          MedTrakr.com has no obligations (including without limitation,
          obligations of confidentiality) with respect to such Feedback. You
          represent and warrant that you have all rights necessary to submit the
          Feedback and you hereby grant to MedTrakr.com and MedTrakr a
          perpetual, irrevocable, transferable, fully paid, royalty-free,
          non-exclusive, worldwide, fully sublicense able right and license to
          use, copy, display, publish, modify, remove, publicly perform,
          translate, create derivative works, distribute and/or otherwise use
          such Feedback.
        </p>
        <h2>1. Disclaimer of Warranties</h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT OF LAW,
          YOUR USE OF THE WEBSITE, SOFTWARE AND SERVICES IS ENTIRELY AT YOUR OWN
          RISK. CHANGES ARE PERIODICALLY MADE TO THE WEBSITE, SOFTWARE AND
          SERVICES AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO YOU. THE
          WEBSITE, SOFTWARE AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS
          WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE REPEATCLICK.COM MAKES NO
          WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY,
          COMPLETENESS OR TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE
          WEBSITE, SOFTWARE OR SERVICES, OR THE SERVICES, SOFTWARE, TEXT,
          GRAPHICS OR LINKS.
        </p>
        <p>
          THE MEDTRAKR.COM DOES NOT WARRANT THAT THE WEBSITE, SOFTWARE OR
          SERVICES WILL OPERATE ERROR-FREE OR THAT THE WEBSITE, SOFTWARE OR
          SERVICES ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL MALWARE. IF
          YOUR USE OF THE WEBSITE, SOFTWARE OR SERVICES RESULTS IN THE NEED FOR
          SERVICING OR REPLACING EQUIPMENT OR DATA, THE MEDTRAKR.COM SHALL NOT
          BE RESPONSIBLE FOR THOSE ECONOMIC COSTS.
        </p>
        <h2>1. Loss or Damage Suffered By You</h2>
        <p>
          MedTrakr.com and MedTrakr will not be liable for any loss or damage,
          whether in contract, tort (including negligence), breach of statutory
          duty, or otherwise, even if foreseeable, arising under or in
          connection with, Use of, or inability to use our Site/Services; or Use
          of or reliance on any content displayed on our website.
        </p>
        <p>In particular, we will not be liable for:</p>
        <ul>
          <li>Loss of profits, sales, business, or revenue;</li>
          <li>Business interruption;</li>
          <li>Loss of anticipated savings;</li>
          <li>Loss of business opportunity, goodwill or reputation; or</li>
          <li>Any indirect or consequential loss or damage.</li>
        </ul>
        <h2>1. Termination</h2>
        <p>
          Failure to comply with (Acceptable Use) and/or (Content Standards) in
          these Terms of Use constitutes a material breach of the Terms of Use,
          and may result in our taking all or any of the following actions:
        </p>
        <ul>
          <li>
            immediate, temporary or permanent withdrawal of your right to use
            our Service;
          </li>
          <li>
            immediate, temporary or permanent removal of any posting or material
            uploaded by you to our Service;
          </li>
          <li>issuing of a warning to you;</li>
          <li>
            legal action against you including proceedings for reimbursement of
            all costs on an (including, but not limited to, reasonable
            administrative and legal costs) resulting from the breach;
          </li>
          <li>
            • Disclosure of such information to law enforcement authorities as
            we reasonably feel is necessary.
          </li>
        </ul>
        <p>
          The responses described in this clause are not limited, and we may
          take any other action we reasonably deem appropriate.
        </p>
        <h2>1. Our Site and Software May Change Regularly</h2>
        <p>
          We aim to update our Site and Software regularly, and may change the
          content at any time. If the need arises, we may suspend access to our
          Site and our Service, or close them indefinitely. Any of the material
          on our Site or our Service may be out of date at any given time, and
          we are under no obligation to update such material.
        </p>
        <h2>1. Data Protection Policy</h2>
        <p>
          “Personal Data”, “Controller”, “Processing” take the same meanings as
          in the Regulation (EU) 2016/679 and the Directive 2002/58/EC, and any
          regulation or legislation that amends or replaces them (hereafter
          referred to as “Data Protection Legislation”)
        </p>
        <p>
          The parties acknowledge that the Customer’s database may contain
          Personal Data, for which the Customer is the Controller. This data
          will be processed by MedTrakr.com and MedTrakr when the Customer
          instructs so, by using any of the Services that require a database
          (e.g. the Cloud Hosting Services or the Database Upgrade Service), or
          if the Customer transfers their database or a part of their database
          to MedTrakr.com and MedTrakr for any reason pertaining to this
          Agreement. This processing will be performed in conformance with Data
          Protection Legislation. MedTrakr.com commits to:
        </p>
        <ul>
          <li>
            only process the Personal Data when and as instructed by the
            Customer, and for the purpose of performing one of the Services
            under this Agreement, unless required by law to do so, in which case
            MedTrakr.com and MedTrakr will provide prior notice to the Customer,
            unless the law forbids it.
          </li>
          <li>
            ensure that all persons within MedTrakr.com authorized to process
            the Personal Data have committed themselves to confidentiality;
          </li>
          <li>
            implement and maintain appropriate technical and organizational
            measures to protect the Personal Data against unauthorized or
            unlawful processing and against accidental loss, destruction,
            damage, theft, alteration or disclosure ;
          </li>
          <li>
            forward promptly to the Customer any Data Protection request that
            was submitted to MedTrakr.com and MedTrakr about the Customer’s
            database.
          </li>
          <li>
            notify the Customer promptly upon becoming aware of and confirming
            any accidental, unauthorized, or unlawful processing of, disclosure
            of, or access to the Personal Data ;
          </li>
          <li>
            notify the Customer if the processing instructions infringe
            applicable Data Protection Legislation, in the opinion of
            MedTrakr.com and MedTrakr.
          </li>
          <li>
            make available to the Customer all information necessary to
            demonstrate compliance with the Data Protection Legislation, allow
            for and contribute reasonably to audits, including inspections,
            conducted or mandated by the Customer;
          </li>
          <li>
            permanently delete all copies of the Customer’s database in
            possession of MedTrakr.com and MedTrakr,or return such data, at the
            Customer’s choice, upon termination of this Agreement, subject to
            the delays specified in MedTrakr.com and MedTrakr’s Privacy Policy ;
          </li>
        </ul>
        <p>
          With regard to points (d) to (f), the Customer agrees to provide
          MedTrakr.com and MedTrakr with accurate contact information at all
          times, as necessary to notify the Customer’s Data Protection
          responsible.
        </p>
        <h2>1. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless the MedTrakr.com and MedTrakr
          and its officers, directors, employees, agents and affiliates (each,
          an “Indemnified Party”), from and against any losses, claims, actions,
          costs, damages, penalties, fines and expenses, including without
          limitation attorneys’ fees and expenses, that may be incurred by an
          Indemnified Party arising out of, relating to or resulting from (a)
          your User Content; (b) your misuse of the Website, Software or
          Services; (c) your violation of this Agreement; or (d) your violation
          of any applicable laws, rules or regulations through or related to the
          Use of the Website, Software or Services. In the event of any claim,
          allegation, suit or proceeding alleging any matter potentially covered
          by the agreements in this section, you agree to pay for the defense of
          the Indemnified Party, including reasonable costs and attorneys’ fees
          incurred by the Indemnified Party. The MedTrakr.com reserves the
          right, at its own cost, to assume the exclusive defense and control of
          any matter otherwise subject to indemnification by you, in which event
          you will fully cooperate with the MedTrakr.com in asserting any
          available defenses. This provision does not require you to indemnify
          any Indemnified Party for any unconscionable commercial practice by
          such party, or for such party’s negligence, fraud, deception, false
          promise, misrepresentation or concealment, suppression or omission of
          any material fact in connection with the Website, Software or
          Services. You agree that the provisions in this section will survive
          any termination of your account, this Agreement, or your access to the
          Website, Software and/or Services
        </p>
        <h2>1. Privacy Policy</h2>
        <p>
          Our Privacy Policy details on how we collect and use your information.
          You should review it if you would like to know more about how we
          collect, use, and treat your information. We respects the privacy of
          all our website users. When you access or use the MedTrakr.com website
          or services, you signify your agreement to MedTrakr.com Privacy
          Policy. For more information please visit our privacy policy page
        </p>
        <h2>1. Claims of Copyright Infringement</h2>
        <p>
          MedTrakr.com and MedTrakr stand against anyone who is infringing upon
          another intellectual property. We are strongly against the
          exploitation of someone else’s intellectual property. MedTrakr have
          set out the following policy under the Copyright, Designs and Patents
          Act 1988 (the 1988 Act):
        </p>
        <p>
          Suppose MedTrakr has a good faith belief that any content (including
          User Content) appearing on its Platform violates any copyrights or has
          been illegally copyrighted. In that case, MedTrakr reserves the right
          to remove, block, or otherwise ‘takedown’ the content. MedTrakr also
          reserves the right to remove from the Platform any Users, Students, or
          other parties, who are repeat offenders of the MedTrakr.com Copyright
          policy
        </p>
        <h2>Copyright Infringement</h2>
        <article>
          <p>
            Suppose you believe that content appearing on the MedTrakr Platform
            violates your intellectual property. In that case, you should send a
            notice to MedTrakr at info@medtrakr.com
          </p>
          <p>
            Suppose you believe that anything on the Services infringes upon any
            copyright which you own or control. In that case, you may send us a
            notification of such infringement at info@medtrakr.com
          </p>
          <p>
            You must not knowingly misrepresent your information that the
            material is infringing when it is not. You will be liable for any
            damages, including costs and attorneys’ fees, incurred by us or the
            alleged infringer as the result of our relying upon such
            misrepresentation in removing or disabling access to the material or
            activity claimed to be infringing.
          </p>
        </article>
        <h2>Contents of Notice</h2>
        <p>
          If you send MedTrakr a notice regarding any claimed infringements,
          your notice must contain the following information:
        </p>
        <ul>
          <li>
            A physical and electronic signature from the owner of the copyright
            that has been allegedly infringed
          </li>
          <li>
            Identification of the copyrighted work claimed to have been
            infringed.
          </li>
          <li>
            Identify the material, including providing a URL and other
            information, which will help MedTrakr review and locate the
            infringed content.
          </li>
          <li>
            Please provide us with your contact information, including your
            telephone number, address, and email address, to be able to contact
            you
          </li>
          <li>
            A written statement by you revealing in good faith the material or
            content is not authorized by the copyright owner, its agent, or the
            law
          </li>
          <li>
            A written statement that all information in the Copyright
            notification is accurate, and under penalty of perjury, you are
            authorized to act on behalf of the copyright owner.
          </li>
        </ul>
        <h2>Counter-Notification</h2>
        <p>
          If any content (including User Content) is removed due to the receipt
          of a Copyright, Designs and Patents Act 1988 notice, you may send
          MedTrakr what is known as a Counter-Notice if you believe that your
          content was wrongly removed. Your Counter-Notice must contain;
        </p>
        <ul>
          <li>
            A physical or electronic signature that has been allegedly removed.
          </li>
          <li>
            Identify the removed material with the URL location of where it was
            and when it was removed.
          </li>
          <li>
            A written statement that all information in the Copyright material
            was removed as a result of mistake or misidentification
          </li>
          <li>
            Please provide us with your contact information, including your
            telephone number, address, email address, and a statement that you
            consent to the United Kingdom jurisdiction and that you will accept
            the Service of the process from the person who provided
            notification.
          </li>
          <li>
            We will be required to send a copy of any notices from you to the
            party who posted the content you report as infringing.
          </li>
          <li>Repeat Infringements:</li>
        </ul>
        <p>
          As we stated above, MedTrakr does not tolerate using someone else’s
          intellectual property without permission. Should MedTrakr receive two
          or more Copyright, Designs and Patents Act 1988 (the 1988 Act),
          complaints regarding any User, MedTrakr retains the right to remove
          such User from the MedTrakr Platform without further warning.
        </p>
        <h2>No Joint Venture or Partnership</h2>
        <p>
          No joint venture, partnership, employment, or agency relationship
          exists between you, the MedTrakr.com or any third-party provider as a
          result of this Agreement or Use of the Software or Services.
        </p>
        <h2>Communications</h2>
        <p>
          MedTrakr may send you communications regarding updates related to the
          Platform and services in the following ways.
        </p>
        <ol>
          <li>
            By sending you emails with the contact information, You provide in
            Your account registration,
          </li>
          <li>By posting a notice in the Platform's dashboard area,</li>
          <li>
            By posting the notice on the Platform website area, you consent to
            review the notice periodically.
          </li>
        </ol>
        <h2>Severability</h2>
        <p>
          Except as otherwise provided herein, if any provision of this
          Agreement is found to be invalid, the invalidity of such provision
          shall not affect the validity of the remaining provisions of this
          Agreement, which shall remain in full force and effect.
        </p>
        <h2>Dispute Resolution</h2>
        <p>
          This section sets forth the particular way you can resolve any dispute
          you may have with us. If you ever wish to seek any relief from us, you
          agree to do it only through arbitration, and you waive the ability to
          pursue a class action.
        </p>
        <p>
          If you are a user and habitually reside in a Member State of the
          European Union, the laws of that Member State will apply to any claim,
          cause of action, or dispute you have against us that arises out of or
          relates to these Terms ("claim"), and you may resolve your claim in
          any competent court in that Member State that has jurisdiction over
          the claim. In all other cases, you agree that the claim must be
          resolved in a competent Constitutional of the English Court will
          govern these Terms and any claim, without regard to conflict of law
          provisions.
        </p>
        <h2>Choice of Law</h2>
        <p>
          This Agreement and terms of service is governed by the English Laws
          and the required General Data Protection Regulation (GDPR), without
          giving effect to any principles of any other jurisdiction you agree to
          the exclusive jurisdiction of the courts of England and Wales.
        </p>
        <p>
          You agree that any claim or dispute you may have relating to this
          website must and be resolved by arbitration. You consent and submit to
          the English courts' exclusive personal jurisdiction to litigate any
          such action.
        </p>
        <h2>End User Agreement</h2>
        <p>
          When you create a User account or you sign up via authorize
          third-party platform to enable you access the MedTrakr.com and
          MedTrakr Software and services, you will be required to agree to an
          End User Agreement that will govern our license to you of the Software
          and your Use of the services we provide. Should there be a conflict
          between these Terms and Conditions and the End User Agreement with
          respect to any specific subject matter, the End. User Agreement will
          prevail for that specific subject matter.
        </p>
        <h2>Copyright License</h2>
        <p>
          Copyright © 2023 MedTrakr.com United Kingdom. All rights reserved.
        </p>
        <ol>
          <li>
            We, together with our licensors, own and control all the copyright
            and other intellectual property rights in MedTrakr.com and MedTrakr
            website and the Material on our website; and
          </li>
          <li>
            All the copyright and other intellectual property rights in
            MedTrakr.com and MedTrakr website and the Material on our website
            are reserved.
          </li>
        </ol>
        <h2>Complaints</h2>
        <p>
          If you have any question or complaints regarding our policy and terms
          please send us a e-mail on info@medtrakr.com
        </p>
      </div>
    </>
  );
}

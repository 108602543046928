import { COLLECTION_TYPES } from "../constants/collectionTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createCollection,
  updateCollection,
  deleteCollection,
  fetchCollectionsByPharmacy,
  switchCollection,
} from "../../api/collectionApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Collection Action
export const createCollectionAction = (collectionData) => async (dispatch) => {
  try {
    const res = await createCollection(collectionData);
    dispatch({
      type: COLLECTION_TYPES.CREATE_COLLECTION,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);
  }
};

// Update Collection Action
export const updateCollectionAction =
  (id, collectionData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateCollection(id, collectionData);

      dispatch({
        type: COLLECTION_TYPES.UPDATE_COLLECTION,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Collection Action
export const deleteCollectionAction = (id) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    await deleteCollection(id);

    dispatch({
      type: COLLECTION_TYPES.DELETE_COLLECTION,
      payload: id,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  }
};
// Fetch Collections Action
export const fetchCollectionsByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchCollectionsByPharmacy(pharmacyId);

      dispatch({
        type: COLLECTION_TYPES.FETCH_COLLECTIONS,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Switch Collection Action
export const switchCollectionType = (id) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    await switchCollection(id);

    dispatch({
      type: COLLECTION_TYPES.SWITCH_COLLECTION,
      payload: id,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

import { COLLECTION_TYPES } from "../constants/collectionTypes";

const initialState = {
  collections: [],
};

const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_TYPES.CREATE_COLLECTION:
      return {
        ...state,
        collections: [...state.collections, action.payload],
      };
    case COLLECTION_TYPES.UPDATE_COLLECTION:
      return {
        ...state,
        collections: state.collections.map((collection) =>
          collection._id === action.payload.id
            ? { ...collection, ...action.payload }
            : collection
        ),
      };
    case COLLECTION_TYPES.DELETE_COLLECTION:
      return {
        ...state,
        collections: state.collections.filter(
          (collection) => collection._id !== action.payload
        ),
      };
    case COLLECTION_TYPES.FETCH_COLLECTIONS:
      return {
        ...state,
        collections: action.payload,
      };
    case COLLECTION_TYPES.SWITCH_COLLECTION:
      return {
        ...state,
        collections: state.collections.map((collection) =>
          collection._id === action.payload
            ? {
                ...collection,
                collectionType:
                  collection.collectionType === "handout"
                    ? "driver"
                    : "handout",
              }
            : collection
        ),
      };
    default:
      return state;
  }
};

export default collectionReducer;

import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { loginAction } from "../../redux/actions/authActions";
import "./login.css";

const LoginComponentCard = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle email password login
  const handleLoginSubmit = (e) => {
    e.preventDefault();

    // Validate email regex
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email");
      return;
    }

    // Make sure the password is at least 6 characters
    if (password.length < 6) {
      alert("Please enter a valid password");
      return;
    }

    // Dispatch email, password, and history to action
    dispatch(
      loginAction({ email, password, setLoading, navigate, location })
    ).catch((errorMessage) => {
      console.error("Login Error: ", errorMessage); // Handle error
    });
  };

  return (
    <div className="login-container">
      <div>
        <h2>Login</h2>
        <div>
          <form onSubmit={handleLoginSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mt-4">
              <Form.Label>Password</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type={showPassword ? "text" : "password"} // Toggle between text and password type
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                  style={{ cursor: "pointer" }} // Make the icon clickable
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Button
              type="submit"
              className="login-button mt-4"
              disabled={loading}
            >
              Login
            </Button>

            <div className="login-footer mt-3">
              <Link to="/forgot-password">Forgot Password?</Link>
              <Link to="/signup">Sign Up</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginComponentCard;

import api from "./index";

// Check current user is super admin
export const currentSuperAdmin = async () => {
  return await api.post("/currentSuperAdmin", {});
};

// Get all admins
export const getAllAdmins = async () => {
  return await api.get("/admins");
};

import api from "./index";

// Create Patient
export const createVehicleByPharmacy = async (pharmacyId, vehicleData) => {
  return await api.post(`/${pharmacyId}/create-vehicle`, vehicleData);
};

// Update Patient
export const updateVehicleByPharmacy = async (pharmacyId, id, vehicleData) => {
  return await api.put(`/${pharmacyId}/update-vehicle/${id}`, vehicleData);
};
// Delete Patient
export const deleteVehicleByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-vehicle/${id}`);
};
// Fetch Patients
export const fetchVehiclesByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-vehicles`);
};

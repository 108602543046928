import { APPOINTMENT_TYPES } from "../constants/appointmentTypes";

const initialState = {
  appointments: [],
};

const appointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPOINTMENT_TYPES.CREATE_APPOINTMENT:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
      };
    case APPOINTMENT_TYPES.UPDATE_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.map((appointment) =>
          appointment._id === action.payload.id
            ? { ...appointment, ...action.payload }
            : appointment
        ),
      };
    case APPOINTMENT_TYPES.DELETE_APPOINTMENT:
      return {
        ...state,
        appointments: state.appointments.filter(
          (appointment) => appointment._id !== action.payload
        ),
      };
    case APPOINTMENT_TYPES.FETCH_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case APPOINTMENT_TYPES.DELETE_APPOINTMENTS:
      return {
        ...state,
        appointments: state.appointments.filter(
          (appointment) => appointment.repeatCycle !== action.payload
        ),
      };
    case APPOINTMENT_TYPES.UPDATE_APPOINTMENTS:
      // Assuming the payload contains the entire updated list of appointments
      // for a specific repeat cycle. Adjust the logic as needed.
      return {
        ...state,
        appointments: state.appointments.map((appointment) => {
          // If the appointment has the same repeatCycleId as the updated ones,
          // replace it. Otherwise, keep the original.
          if (appointment.repeatCycle === action.payload.repeatCycleId) {
            return (
              action.payload.updatedAppointments.find(
                (updatedAppointment) =>
                  updatedAppointment._id === appointment._id
              ) || appointment
            );
          }
          return appointment;
        }),
      };

    default:
      return state;
  }
};

export default appointmentReducer;

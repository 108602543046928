import { PLAN_TYPES } from "../constants/planTypes";

const initialState = {
  plans: [],
};

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAN_TYPES.CREATE_PLAN:
      return {
        ...state,
        plans: [...state.plans, action.payload],
      };
    case PLAN_TYPES.UPDATE_PLAN:
      return {
        ...state,
        plans: state.plans.map((plan) =>
          plan._id === action.payload.id ? { ...plan, ...action.payload } : plan
        ),
      };
    case PLAN_TYPES.DELETE_PLAN:
      return {
        ...state,
        plans: state.plans.filter((plan) => plan._id !== action.payload),
      };
    case PLAN_TYPES.FETCH_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    default:
      return state;
  }
};

export default planReducer;

import api from "./index";

// Create Notification
export const createNotificationByPharmacy = async (
  pharmacyId,
  notificationData
) => {
  return await api.post(`/${pharmacyId}/notification`, notificationData);
};
// Fetch Notification
export const fetchNotificationsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/notifications`);
};
// Update Notification
export const updateNotificationById = async (
  pharmacyId,
  id,
  notificationData
) => {
  return await api.put(`/${pharmacyId}/notification/${id}`, notificationData);
};
// Delete Notification
export const deleteNotificationById = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-notification/${id}`);
};

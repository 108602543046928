import api from "./index";

// Create Appointment
export const createAppointmentByPharmacy = async (
  pharmacyId,
  appointmentData
) => {
  return await api.post(`/${pharmacyId}/create-appointment`, appointmentData);
};
// Update Appointment
export const updateAppointmentByPharmacy = async (
  pharmacyId,
  id,
  appointmentData
) => {
  return await api.put(
    `/${pharmacyId}/update-appointment/${id}`,
    appointmentData
  );
};
export const updateAppointmentByPharmacyAndRepeatCycle = async (
  pharmacyId,
  id,
  appointmentData
) => {
  return await api.put(
    `/${pharmacyId}/update-appointments-by-repeat-cycle/${id}`,
    appointmentData
  );
};

// Fetch Appointments
export const fetchAppointmentsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-appointments`);
};
// Delete Appointment
export const deleteAppointmentByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-appointment/${id}`);
};
export const deleteAppointmentByPharmacyAndRepeatCycle = async (
  pharmacyId,
  repeatCycleId
) => {
  return await api.delete(
    `/${pharmacyId}/delete-appointments-by-repeat-cycle/${repeatCycleId}`
  );
};

import { VEHICLE_TYPES } from "../constants/vehicleTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createVehicleByPharmacy,
  updateVehicleByPharmacy,
  deleteVehicleByPharmacy,
  fetchVehiclesByPharmacy,
} from "../../api/vehicleApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Vehicle Action
export const createVehicleByPharmacyAction =
  (pharmacyId, vehicleData) => async (dispatch) => {
    try {
      const res = await createVehicleByPharmacy(pharmacyId, vehicleData);
      dispatch({
        type: VEHICLE_TYPES.CREATE_VEHICLE,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Vehicle Action
export const updateVehicleByPharmacyAction =
  (pharmacyId, id, vehicleData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateVehicleByPharmacy(pharmacyId, id, vehicleData);

      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Vehicle Action
export const deleteVehicleByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteVehicleByPharmacy(pharmacyId, id);

      dispatch({
        type: VEHICLE_TYPES.DELETE_VEHICLE,
        payload: id,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Vehicles Action
export const fetchVehiclesByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchVehiclesByPharmacy(pharmacyId);

      dispatch({
        type: VEHICLE_TYPES.FETCH_VEHICLES,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

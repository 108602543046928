import React from "react";
import "./gdpr.css";
export default function Gdpr() {
  return (
    <>
      <div className="container">
        <h1>GDPR Policy</h1>
        <article>
          <p>
            Welcome to MedTrakr website GDPR Policy. MedTrakr (“we”, “us”, or
            “Our”) respects the privacy rights of its users and understands the
            importance of protecting and handling information collected about
            you in accordance with both the law and best practice. This Privacy
            Notice has been composed for providing you with the information that
            you are entitled to (in case you are a private person, i.e., a data
            subject) according to Article 13 and Article 14 of EU General Data
            Protection Regulation, i.e., Regulation (EU) 2016/679 (“GDPR”)and
            The United Kingdom Data Protection Act 1998 (c. 29)
          </p>
          <p>
            This Privacy Policy and statement is created to explain to you how
            we (www.medtrakr.com, or one of our other website and web
            application) collect your data, how we process them, how we secure
            them, how we might share them as regards to providing our services
            and how we store them. This Privacy Statements also describe what
            right you have as regards to your personal data, how you give your
            consent and how you withdraw your consent. If you are user who
            resides in the European Union Region, we also explain what rights
            you have as a data subject.
          </p>
        </article>
        <ol>
          <li>Introduction</li>
          <li>Acceptance and Use</li>
          <li>Who We Are?</li>
          <li>Data Protection Principles</li>
          <li>Types of Data We Collect from You</li>
          <li>Non-Personal Information</li>
          <li>UK and EU Cookie Law</li>
          <li>Third Party-Payment Processors</li>
          <li>Information Collected Through Technology</li>
          <li>How We Use Your Data</li>
          <li>Remarketing on the App and website and web application</li>
          <li>Google AdWords</li>
          <li>With Whom We Share Your Data</li>
          <li>Do Not Track</li>
          <li>Interest-Based Advertising Technologies</li>
          <li>Data Retention</li>
          <li>EEA and Non-EEA Data Subjects</li>
          <li>Your Data Protection Rights</li>
          <li>Legal Bases for Processing (For EEA Users):</li>
          <li>Third-Party Site and Links</li>
          <li>Data Controller and Transfer</li>
          <li>Communications</li>
          <li>GDPR Statement for (EEA Users)</li>
          <li>Security of Personal Information</li>
          <li>Data retention and deletion</li>
          <li>Terms and Conditions</li>
          <li>Jurisdiction</li>
          <li>Changes to our Privacy Policy</li>
          <li>Contact Us</li>
        </ol>
        <h2>Acceptance and Use</h2>
        <p>
          This privacy policy covers MedTrakr and its subsidiaries including
          (collectively, " Medtrakr.com," "we" "us" or "our"). By using MedTrakr
          website and web application, you signify acceptance to the terms of
          this Privacy Policy. Where your acceptance via clicking on [ACCEPT]
          when this policy is displayed to you on the site, is considered as
          your official consent to process your personal information, your
          consent to the collection, use, and disclosure of your personal
          information as described further below. We may request you to provide
          additional disclosures or information about the data processing
          practices of specific Services. These notices may supplement or
          clarify our privacy practices or may provide you with additional
          choices about how we process your data.
        </p>
        <p>
          If you do not agree with or are not comfortable with any aspect of
          this Privacy Policy, you should immediately discontinue access or use
          of MedTrakr website and web application and its related services.
        </p>
        <h2>Who We Are?</h2>
        <p>
          MedTrakr.com provides a web application that connects pharmacy, users
          and drivers. We enable an easy-to-use platform interface for pharmacy
          owners and drivers to register and list their services and medication
          on the platform. We also provide a user an opportunity to search for
          and place an order for a medication of their choice which will be
          delivered by registered and approved drivers of the platform.
        </p>
        <h2>Data Protection Principles</h2>
        <p>
          We collect, process, and use personal data (under the definitions of
          The United Kingdom Data Protection Act 1998 (c. 29) and the required
          European Union GDPR Regulation to provide our services. Personal data
          means any information that enables us to identify you, directly or
          indirectly, such as name, email, address, telephone number, any form
          of identification number, or one or more factors specific to you or
          your beneficiary's identity.
        </p>
        <p>
          We are committed to complying with applicable data protection laws and
          will ensure that personal data is:
        </p>
        <ul>
          <li>Used lawfully, fairly and in a transparent way.</li>
          <li>
            Collected only for valid purposes that we have clearly explained to
            you and not used in any way that is incompatible with those
            purposes.
          </li>
          <li>
            Relevant to the purposes we have told you about and limited only to
            those purposes.
          </li>
          <li>Accurate and kept up to date.</li>
          <li>
            Kept only if necessary, for the purposes we have told you about.
          </li>
          <li>Kept securely.</li>
        </ul>
        <h2>Types of Data We Collect from You</h2>
        <p>
          Personal Identifiable Information (PII). We acquire PII that may
          include, in certain contexts, your
        </p>
        <ol>
          <li>Full name,</li>
          <li>date of birth,</li>
          <li>Driver’s License, (if you are a driver)</li>
          <li>Vehicle Information (if you are a driver)</li>
          <li>Insurance Information (if you are a driver)</li>
          <li>nationality,</li>
          <li>gender,</li>
          <li>delivery address,</li>
          <li>package delivery information and/or</li>
          <li>Email.</li>
        </ol>
        <p>
          Which certain jurisdictions consider to be PII because it could be
          used to identify an individual or Device if it were combined with
          other identifying information.
        </p>
        <ul>
          <li>
            User profile: We collect data when users create or update their
            MedTrakr accounts. This may include their name, email, phone number,
            login name and password, address, profile picture, payment or
            banking information (including related payment verification
            information), driver’s license and other government identification
            documents (which may indicate document numbers as well as birth
            date, gender, and photo). This also includes vehicle or insurance
            information of drivers and Pharmacy persons, emergency contact
            information, user settings, and evidence of health or fitness to
            provide services using MedTrakr platform. We may use the photos
            submitted by users to verify their identities, such as through
            facial recognition technologies. For more information, please see
            the section titled “How we use personal data.”
          </li>
          <li>
            Background check and identity verification: We collect background
            check and identity verification information for drivers and
            pharmacy. This may include information such as driver history or
            criminal record (where permitted by law), and right to work. This
            information may be collected by an authorized vendor on MedTrakr’s
            behalf. We also collect identity verification from MedTrakr users
            who request alcohol delivery.
          </li>
          <li>
            Demographic data: We may collect demographic data about users,
            including through user surveys.
          </li>
          <li>
            Correspondence: Survey responses, information provided to our
            support team or user research team.Such as requesting customer
            support; answering a questionnaire; participating in a study;
            entering contests or sweepstakes.
          </li>
        </ul>
        <h2>Non-Personal Information</h2>
        <p>
          Non-personal information refers to information that, by itself, does
          not identify you as a specific individual (e.g., demographic
          information or web site visitations). MedTrakr may collect
          non-personal information through any of the methods discussed above as
          well as automatically through use of industry standard technologies
          described further below.
        </p>
        <h2>UK and EU Cookie Law</h2>
        <p>
          The Privacy and Electronic Communications (EC Directive) Regulations
          2003 as amended by the Privacy and Electronic Communications (EC
          Directive) (Amendment) Regulations 2011; Cookies is a small text file
          placed on your computer by this Website and web application when you
          visit certain parts of the Website and web application and/or when you
          use certain features of the Website and web application.
        </p>
        <p>We use cookies and similar technologies for purposes such as:</p>
        <ul>
          <li>Authenticating users</li>
          <li>Remembering user preferences and settings</li>
          <li>Determining the popularity of content</li>
          <li>
            Delivering and measuring the effectiveness of advertising campaigns
          </li>
          <li>
            Analyzing site traffic and trends, and generally understanding the
            online behaviors and interests of people who interact with our
            services
          </li>
        </ul>
        <p>
          We may also allow others to provide audience measurement and analytics
          services for us, to serve advertisements on our behalf across the
          Internet, and to track and report on the performance of those
          advertisements. These entities may use cookies, web beacons, SDKs, and
          other technologies to identify the devices used by visitors to our
          websites, as well as when they visit other online sites and services.
        </p>
        <p>
          Details of the cookies used by this Website and web application are
          set out in the (Cookies policy page).
        </p>
        <h2>Third Party-Payment Processors</h2>
        <p>
          We use third-party payment processors to process payments via the
          MedTrakr website. For security purposes, MedTrakr does not collect or
          receive any sensitive payment information, such as your complete card
          number or authentication data. For additional Information about how
          your payment information is processed and stored, we advise you to
          review the applicable payment processor's Privacy Policy.
        </p>
        <h2>Information Collected Through Technology</h2>
        <p>
          While you are able to visit the MedTrakr site and remain anonymous,
          MedTrakr or its third-party service providers may still collect
          non-personal information about your use of the MedTrakr materials
          (e.g. Your internet browser, operating system, I P address, connection
          speed, and the domain name of your internet service provider). Such
          information may be gathered by the following methods:
        </p>
        <ol>
          <li>
            Cookies. Cookies, including local shared objects, are small pieces
            of information that are stored by your browser on your device’s hard
            drive which work by assigning to your computer a unique number that
            has no meaning outside of the MedTrakr site. Cookies do not
            generally contain any personal information. Most web browsers
            automatically accept cookies, but you can usually configure your
            browser to prevent this. Not accepting cookies may make certain
            features of the MedTrakr materials unavailable to you.
          </li>
          <li>
            IP address. You may visit many areas of the MedTrakr site
            anonymously without the need to become a registered user. Even in
            such cases, MedTrakr may collect ip addresses automatically. An IP
            address is a number that is automatically assigned to your computer
            whenever you begin services with an internet services provider. Each
            time you access the MedeTrakr site and each time you request one of
            MedTrakr’s pages, the server logs your ip address.
          </li>
          <li>
            Beacons. Beacons are small pieces of data that are embedded in web
            pages and e-mails. MedTrakr may use these technical methods in html
            e-mails that MedTrakr sends to users to determine whether they have
            opened those e-mails and/or clicked on links in those e-mails. The
            information from use of these technical methods may be collected in
            a form that constitutes personal information.
          </li>
          <li>
            Tracking content usage. If you use the MedTrakr services and you
            post audio visual materials including, without limitation, text,
            logos, artwork, graphics, pictures, advertisements, sound and other
            related intellectual property contained in such materials
            (collectively, “content”) to your app site or to a third-party site,
            MedTrakr tracks and captures non-personal information associated
            with user accounts and the use of content.
          </li>
        </ol>
        <h2>How We Use Your Data</h2>
        <p>
          For purposes of the General Data Protection Regulation (GDPR),
          MedTrakr is the "data processer". We will retain any Data you submit
          for a minimum of 12 months or the length of our services. Your Data
          may be required by us from time to time in order to provide you with
          the best possible service and experience when using our Website and
          web application. Specifically, Data may be used by us for the
          following reasons:
        </p>
        <ol>
          <li>We also use the data we collect:</li>
          <li>To enhance the safety and security of our users and services</li>
          <li>For customer support</li>
          <li>For research and development</li>
          <li>To enable communications between users</li>
          <li>To send marketing and non-marketing communications to users</li>
          <li>In connection with legal proceedings</li>
        </ol>
        <p>
          MedTrakr does not sell or share user personal data with third parties
          for their direct marketing, except with users’ consent. In each case,
          in accordance with this privacy policy. We may use your data for the
          following purposes:
        </p>
        <ul>
          <li>We use your data to verify you when you access your account.</li>
          <li>Create and update users’ accounts.</li>
          <li>
            Verify drivers’ and Pharmacy persons’ identity, background history,
            and Authenticity.
          </li>
          <li>
            Enable order placement, order process, deliveries, and other
            services.
          </li>
          <li>Offer, process, or facilitate payments for our services.</li>
          <li>
            Offer, obtain, provide, or facilitate insurance, vehicle, invoicing,
            or financing solutions in connection with our services.
          </li>
          <li>Track and share the progress of deliveries.</li>
          <li>
            We use your data when we communicate with you (e.g., when we respond
            to a customer support or other inquiry).
          </li>
          <li>
            We use your data to understand how our services are being used and
            how we can improve them. In general, we analyze aggregated data,
            rather than specific user data. We may, however, need to analyze a
            specific case to address a specific problem (e.g., a bug that
            affects only a few accounts).
          </li>
          <li>
            We use your data to personalize the service to you. This may include
            remembering your preferences for language or volume or displaying
            app that you might enjoy, based upon your viewing choices.
          </li>
          <li>
            We process your personal information in order to enhance security,
            monitor and verify identity or service access, combat spam or other
            malware or security risks and to comply with applicable security
            laws and regulations. The threat landscape on the internet is
            constantly evolving, which makes it more important than ever that we
            have accurate and up-to-date information about your use of MedTrakr
            Services.
          </li>
          <li>
            We use your data to display ads and send you offers. We may also use
            your data in delivering third-party advertisements to you. This may
            include "targeted ads" based upon your activities.
          </li>
          <li>
            We use your data where we are legally required to do so. For
            example, we may need to gather your data to respond to a subpoena or
            court order.
          </li>
          <li>
            We process your personal information to better understand the way
            you use and interact with MedTrakr 's Services. In addition, we use
            such information to customize, measure, and improve features and the
            content and layout of our site and applications, and to develop new
            services.
          </li>
        </ul>
        <p>
          We may use algorithms and other automated means to implement any of
          the above.
        </p>
        <h2>Remarketing On the website and web application</h2>
        <p>
          If you have provided your consent by accepting Targeting Cookies on
          the site, we may use Google AdWords (remarketing and Similar Audience
          features) and Facebook Ads. You can review the MedTrakr cookie policy
          which includes details on how to customize your cookie settings.
        </p>
        <h2>Google AdWords</h2>
        <p>
          Google AdWords enables Google, using cookies, to identify the fact
          that you have visited the site, to identify aspects of your usage of
          the App and combine that with what it knows about your usage of
          another site in the Google ad network. We use these services to
          advertise to visitors of Google ad network Apps who have previously
          visited our site or who Google deems to have shared interests with
          visitors of our site. Google’s collection and use of your personal
          data is covered by the Google privacy policy. You can set preferences
          for how Google advertises to you using the Google Ad Preferences page.
        </p>
        <h2>With Whom We Share Your Data</h2>
        <p>
          Some of our products, services, and features require that we share
          data with other users or at a user’s request. We may also share data
          with our affiliates, subsidiaries, and partners, for legal reasons or
          in connection with claims or disputes.
        </p>
        <ul>
          <li>
            With your consent: we may share your data with third parties where
            we have obtained your express consent to do so. You may revoke these
            consents.
          </li>
          <li>
            Authorized vendors: we may share your data with third-party vendors
            that help us operate our services, process payments, and comply with
            your instructions and our contractual obligations. This includes
            payment processors, content delivery networks (cdns), cloud-based
            hosting services, monitoring services, email service providers,
            quality assurance and testing vendors, fraud and abuse prevention
            vendors, customer relations management (crm) vendors
          </li>
          <li>
            Advertising: we may share your data with advertising companies to
            display relevant ads to you. Unless you expressly agree, we will not
            share or sell your name, email address, or physical address with
            such persons.
          </li>
          <li>
            With Service Providers: We may share personal Information with
            delivery drivers, consultants, payment processors, and other service
            providers that provide us with specific services and unique process
            information on our behalf. These services may include providing
            customer support, performing business and sales analysis, supporting
            our website functionality, facilitating payment processing, and
            supporting contests, surveys, and other features offered on our
            Platform. These service providers are not permitted to use your
            personal Information for any other purpose, and their use of such
            personal data will be subject to appropriate confidentiality and
            security measures.
          </li>
          <li>
            Analytics: we may share your data with persons who provide analytics
            showing how customers are using our services.
          </li>
          <li>
            Affiliates and advisors: we may share your data with our affiliates
            company, and our auditors and advisors for planning, financial
            reporting, accounting, auditing, tax filings, and legal compliance.
            Unless you expressly agree, we will not share your data with our
            parent or any affiliate for other purposes, such as direct
            marketing.
          </li>
          <li>
            Certain legal situations: we may share your data where we believe
            disclosure is necessary to comply with a legal obligation or in
            connection with a corporate transaction.
          </li>
          <li>
            Aggregated or anonymized information: we may publicly disclose
            non-personal aggregated or anonymized information such as our number
            of visitors and registered users.
          </li>
        </ul>
        <h2>Do Not Track</h2>
        <p>
          Do Not Track (“DNT”) is a privacy preference that users can set in
          certain web browsers and devices. DNT is a way for users to inform
          website and web applications and services that they do not want
          certain information about their webpage visits collected over time and
          across website and web applications or online services. Please note
          that we do not respond to or honor DNT signals or similar mechanisms
          transmitted by web browsers.
        </p>
        <h2>Interest-Based Advertising Technologies</h2>
        <p>
          As noted above, you may limit advertising tracking using advertising
          identifiers through your browser privacy settings.
        </p>
        <p>
          The online advertising industry also provides website and web
          applications from which you may opt-out of receiving targeted ads from
          our data partners and our other advertising partners that participate
          in self-regulatory programs. You can access these, and learn more
          about targeted advertising and consumer choice and privacy, at
          networkadvertising.org/managing/opt_out.asp, or youronlinechoices.eu/
          and aboutads.info/choices/
        </p>
        <h2>Data Retention</h2>
        <p>
          We retain your data for as long as you have an account. When you close
          an account, we will delete your personal information. We may retain
          logs of automatically collected information (for internal analytics);
          your email address; communications with you; and your transactional
          information (for auditing, tax, and financial purposes). When we no
          longer have a business reason for retaining data, we will delete or
          anonymize it.
        </p>
        <p>
          If we receive legal process pertaining to your account, we will retain
          your data for as long as we in good faith believe is necessary to
          comply with the legal process. Similarly, if we believe that your
          account has been involved in wrongdoing, we may preserve your data to
          defend or assert our rights.
        </p>
        <h2>EEA and Non-EEA Data Subjects</h2>
        <h3>Legal Bases for Processing your Information:</h3>
        <p>
          For individuals who are in the European Economic Area, United Kingdom
          or Switzerland (collectively “EEA Residents”) at the time their
          personal data is collected, we rely on legal bases for processing your
          information under Article 6 of the EU General Data Protection
          Regulation (“GDPR”). We generally only process your data where we are
          legally required to, where the processing is necessary to perform any
          contracts, we entered with you (or to take steps at your request prior
          to entering a contract with you), for our legitimate interests to
          operate our business or to protect MedTrakr website and web
          application or your, property, rights, or safety, or where we have
          obtained your consent to do so.
        </p>
        <h2>Your Data Protection Rights</h2>
        <p>
          We enable you to make numerous choices about your data: Below is the
          following data protection rights you have when you use our services.
        </p>
        <ul>
          <li>You may choose not to provide us with certain Information.</li>
          <li>
            Suppose you wish to access, correct, update or request deletion of
            your personal Information. In that case, you can do so at any time
            by contacting us through the customer services portal or at the
            Information provided at the bottom of this Privacy Policy.
          </li>
          <li>
            If you request access to your data, we will confirm whether we hold
            your Information and provide you with a copy, explaining how we
            process it and why, how long we store it for, and your rights
            associated with it.
          </li>
          <li>
            If you request the deletion of your personal Information, we will
            erase it. Please note that we will need to retain any information
            that we require to fulfil our legal obligations or establish,
            exercise, or defend a legal claim.
          </li>
          <li>
            You can object to your personal information processing, ask us to
            restrict the processing of your personal Information, or request
            portability of your personal Information. Again, you can exercise
            these rights by contacting us through the customer services portal
            or at the Information provided at the bottom of this Privacy Policy.
          </li>
          <li>
            You have the right to opt-out of email marketing communications we
            send you at any time. You can exercise this right by clicking on the
            "unsubscribe" or "opt-out" link in the marketing emails we send you.
          </li>
          <li>
            Opting out Of Messages from Us: To opt-out of any future promotional
            messages from us, you should unsubscribe in the body of the
            promotional message sent to you (found at the bottom of the email)
            or send an unsubscribe request to us. We will process your request
            within a reasonable time after receipt.
          </li>
          <li>
            Similarly, if we have collected and processed your personal
            Information with your consent, then you can withdraw your consent at
            any time. Withdrawing your consent will not affect the lawfulness of
            any processing we conducted before your withdrawal, nor will it
            affect the processing of your personal Information conducted in
            reliance on lawful processing grounds other than consent.
          </li>
          <li>
            You have the right to complain to a data protection authority about
            our collection and use of your personal Information. Please contact
            us for more Information, and we will send you a link or our data
            protection officer address.
          </li>
          <li>
            The easiest way to submit your request to us is through our Customer
            support email found at the bottom of this Privacy Policy. We respond
            to all requests we receive from individuals wishing to exercise
            their data protection rights under applicable data protection laws.
          </li>
        </ul>
        <h2>Legal Bases for Processing (For EEA Users):</h2>
        <p>
          If you are an individual in the European economic area (EEA), we
          collect and process information about you only where we have legal
          bases for doing so under applicable EU laws. The legal bases depend on
          the services you use and how you use them. This means we collect and
          use your information only where:
        </p>
        <ul>
          <li>
            We need it to provide you the services, including to operate the
            services, provide customer support and personalized features and to
            protect the safety and security of the services.
          </li>
          <li>
            It satisfies a legitimate interest (which is not overridden by your
            data protection interests), such as for research and development, to
            market and promote the services and to protect our legal rights and
            interests.
          </li>
          <li>You give us consent to do so for a specific purpose; or</li>
          <li>
            We need to process your data to comply with a legal obligation.
          </li>
        </ul>
        <p>
          If you have consented to our use of information about you for a
          specific purpose, you have the right to change your mind at any time,
          but this will not affect any processing that has already taken place.
          Where we are using your information because we or a third party (e.g.
          your employer) have a legitimate interest to do so, you have the right
          to object to that use though, in some cases, and this may mean no
          longer using the services
        </p>
        <h2>Third-Party Site and Links</h2>
        <p>
          MedTrakr contents may contain links to third parties who may collect
          personal information and non-personal information directly from you.
          Additionally, MedTrakr may use third parties to provide components of
          the MedTrakr contents. In either case, such third parties may have
          separate privacy policies and data collection practices, independent
          of MedTrakr. MedTrakr: (a) has no responsibility or liability for
          these independent policies or actions; (b) is not responsible for the
          privacy practices or the content of such site; and (c) does not make
          any warranties or representations about the contents, products or
          services offered on such App or the security of any information you
          provide to them
        </p>
        <h2>Data Processer and Transfer</h2>
        <p>
          MedTrakr are the data processer for the personal data collected in
          connection with use of MedTrakr’s services in the European Economic
          Area and the United Kingdom. MedTrakr is also the data processor for
          the personal data collected in connection with use of MedTrakr’s
          services anywhere else.
        </p>
        <p>
          MedTrakr operates, and processes data, globally. We may also transfer
          data to countries other than the one where our users live or use
          MedTrakr’s services. We do so in order to fulfil our agreements with
          users, such as our Terms of Use, or based on users’ prior consent,
          adequacy decisions for the relevant countries, or other transfer
          mechanisms as may be available under applicable law. For Questions,
          comments, and complaints about our data practices please send a
          message or complaints to info@medtrakr.com
        </p>
        <h2>Communications</h2>
        <p>
          By creating an account, you consent to receive commercial emails from
          us. This includes newsletters and offers. Users from certain countries
          may have the ability to opt out or opt in at the time of account
          creation. All users may decline to receive commercial messages in
          their account settings. Please note that any opt-out request may take
          several days to process, and you will continue to receive
          transactional emails from us (e.g., emails confirming transactions
          and/or providing information about your account).
        </p>
        <h2>GDPR Statement for (EEA Users)</h2>
        <p>
          This applies only to natural persons residing in the European economic
          area (for the purpose of this section only, "you" or "“your" shall be
          limited accordingly). It is MedTrakr’s policy to comply with the EU'S
          general data protection regulation (GDPR). In accordance with the
          GDPR, we may transfer your personal information from your home country
          to the United Kingdom (or other countries) based upon the following
          legal frameworks:
        </p>
        <ul>
          <li>
            Legitimate business interests: we could not provide our services or
            comply with our obligations to you without transferring your
            personal information to the United Kingdom
          </li>
          <li>
            Consent: we may transfer your personal information when we receive
            your express, revocable consent.
          </li>
          <li>
            Our use of standard contractual clauses (also known as "model
            clauses") where appropriate.
          </li>
          <li>
            You have the right to: opt out of non-essential cookies (see our
            cookie policy); access, correct, delete, restrict, or object to our
            use of your personal information; be forgotten; port your data; and
            withdraw consents.
          </li>
        </ul>
        <p>
          We enable exercise of these rights primarily through our services
          (which we reserve the right to modify). We also fulfil our obligations
          in response to direct requests. We will endeavor to process requests
          within one month. Please note that we may not be able to comply with
          requests to the extent that they would cause us to violate any law or
          infringe any other person's rights. We reserve the right to request
          appropriate identification. We will process requests free of charge
          unless they would impose an unreasonable cost on us.
        </p>
        <p>
          If you have a request, complaint or inquiry, please contact our data
          protection officer at the address listed below. We are committed to
          working with you to obtain a fair resolution of any issue. You also
          have the right to file a complaint with the supervisory data
          protection authority of your jurisdiction.
        </p>
        <h2>Security of Personal Information</h2>
        <p>
          The Site uses its best endeavors to store all Personal Information on
          servers with restricted access, and all electronic storage and
          transmission of Personal Information are secured with appropriate
          security technologies. Not to withstand the foregoing, the app cannot
          guarantee that such precautions would render the Site and its servers
          immune to security breaches.
        </p>
        <h2>Data retention and deletion</h2>
        <p>
          MedTrakr retains user data for as long as necessary for the purposes
          described above. Users may request deletion of their accounts at any
          time. MedTrakr may retain user data after a deletion request due to
          legal or regulatory requirements or for the reasons stated in this
          policy.
        </p>
        <p>
          This means that we retain different categories of data for different
          periods of time depending on the category of user to whom the data
          relates, the type of data, and the purposes for which we collected the
          data
        </p>
        <h2>Changes to our Privacy Policy</h2>
        <p>
          MedTrakr continually strives to implement new technologies and
          processes to better protect you, your privacy and your use of the
          MedTrakr service. As a result, changes to this privacy policy may be
          made by MedTrakr from time to time. In no event will these changes
          result in the degradation of any of the security measures designed to
          protect you from unauthorized disclosure, as such measures are
          described in this privacy policy.
        </p>
        <h2>Terms and Conditions</h2>
        <p>
          To learn more about our terms and conditions, please visit our Terms
          and Conditions page establishing the use, disclaimers, and limitations
          of liability governing the use of our website and web application.
        </p>
        <h2>Jurisdiction</h2>
        <p>
          This privacy policy, and terms of service, cookies, and other policies
          of MedTrakr Platform or services is governed by the English Laws.
        </p>
        <hr />
        <h2>Contact us</h2>
        <p>
          If you have any questions about this privacy policy, please contact us
          at info@medtrakr.com
        </p>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createRouteByPharmacyAction,
  updateRouteByPharmacyAction,
  deleteRouteByPharmacyAction,
  fetchRoutesByPharmacyAction,
} from "../../../redux/actions/routeAction";
import { fetchVehiclesByPharmacyAction } from "../../../redux/actions/vehicleAction";
import { fetchMembersByPharmacyAction } from "../../../redux/actions/memberAction";
import "./createRoutes.css";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
function CreateRoutes() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [selectedRoute, setSelectedRoute] = useState({});
  const [routeName, setRouteName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [assignedDriverId, setAssignedDriverId] = useState("");
  const [assignedVehicleId, setAssignedVehicleId] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const members = useSelector((state) => state?.member?.members);
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const routes = useSelector((state) => state.route.routes);

  const drivers = members.filter((member) => member.role === "driver");

  const fetchMembers = async () => {
    try {
      dispatch(fetchMembersByPharmacyAction(pharmacyId));
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };
  const getRoutes = () => {
    try {
      dispatch(fetchRoutesByPharmacyAction(pharmacyId));
    } catch (error) {
      console.log("Routes could not be fetched");
    }
  };

  const fetchVehicles = () => {
    try {
      dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
    } catch (error) {
      console.log("Vehicles could not be fetched");
    }
  };
  const handleRouteNameChange = (e) => {
    setRouteName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const handleAssignedDriverChange = (e) => {
    setAssignedDriverId(e.target.value);
  };

  const handleAssignedVehicleChange = (e) => {
    setAssignedVehicleId(e.target.value);
  };

  const handleShowDetails = (route) => {
    console.log(route, "route clicked");
    setSelectedRoute(route);
    setShowDetailsModal(true); // Assuming you're using this state to control the visibility
  };

  const findMember = (id) => {
    // Find the member with the matching ID
    const member = members.find((member) => member._id === id);
    // Return the member's name if found, otherwise return a default string
    return member ? member.name : "Not Assigned";
  };

  const findVehicle = (id) => {
    const vehicle = vehicles.find((vehicle) => vehicle._id === id);

    return vehicle ? vehicle.vehicleName : "No Vehicle Assigned";
  };

  const handleSave = async () => {
    console.log("Saving route");
    const newRoute = {
      name: routeName,
      description: description,
      activeStatus: isActive,
      assignedDriverId: assignedDriverId ? assignedDriverId : null,
      assignedVehicleId: assignedVehicleId ? assignedVehicleId : null,
    };

    dispatch(createRouteByPharmacyAction(pharmacyId, newRoute))
      .then(() => {
        setRouteName("");
        setDescription("");
        setIsActive(false);
        setAssignedDriverId("");
        setAssignedVehicleId("");
        setShowCreateModal(false);
      })
      .catch((error) => {
        console.error("Error creating route:", error);
      });
  };

  const handleEditSave = () => {
    const updatedRoute = {
      name: routeName,
      description: description,
      activeStatus: isActive,
      assignedDriverId: assignedDriverId ? assignedDriverId : null,
      assignedVehicleId: assignedVehicleId ? assignedVehicleId : null,
    };

    dispatch(
      updateRouteByPharmacyAction(pharmacyId, selectedRoute._id, updatedRoute)
    )
      .then(() => {
        setRouteName("");
        setDescription("");
        setIsActive(false);
        setAssignedDriverId("");
        setAssignedVehicleId("");
        setShowEditModal(false);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  const handleDelete = () => {
    dispatch(deleteRouteByPharmacyAction(pharmacyId, selectedRoute._id))
      .then(() => {
        setSelectedRoute({});
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  // Fetch drivers when the component mounts
  useEffect(() => {
    fetchMembers();
    getRoutes();
    fetchVehicles();
  }, []);

  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <div className="col-xs-12" style={{ margin: "1rem" }}>
            <button
              type="button"
              className="medtrakr-btn"
              onClick={() => setShowCreateModal(true)}
            >
              Create Route
            </button>
          </div>
          <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
            <table className="table table-bordered w-100">
              <thead>
                <tr>
                  <th>Route Name</th>
                  <th>Description</th>
                  <th>Active</th>
                  <th>Assigned Vehicle</th>
                  <th>Assigned Driver</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {routes.map((route, index) => {
                  return (
                    <tr key={index}>
                      <td
                        data-toggle="modal"
                        data-target="#routeDetailsModalCenter"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedRoute(route);
                        }}
                      >
                        {route.name}
                      </td>
                      <td
                        data-toggle="modal"
                        data-target="#routeDetailsModalCenter"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedRoute(route);
                        }}
                      >
                        {route.description}
                      </td>
                      <td
                        data-toggle="modal"
                        data-target="#routeDetailsModalCenter"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedRoute(route);
                        }}
                      >
                        {route.activeStatus ? "Yes" : "No"}
                      </td>
                      <td
                        data-toggle="modal"
                        data-target="#routeDetailsModalCenter"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedRoute(route);
                        }}
                      >
                        {findVehicle(route.assignedVehicleId)}
                      </td>
                      <td
                        data-toggle="modal"
                        data-target="#routeDetailsModalCenter"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedRoute(route);
                        }}
                      >
                        {findMember(route.assignedDriverId)}
                      </td>
                      <td>
                        <button
                          className="btn view"
                          style={{ marginRight: "0.5rem" }}
                          onClick={() => handleShowDetails(route)} // Pass the 'route' object correctly
                        >
                          View
                        </button>
                        <button
                          className="btn update"
                          style={{ marginRight: "0.5rem" }}
                          onClick={() => {
                            setSelectedRoute(route);
                            setRouteName(route.name);
                            setDescription(route.description);
                            setIsActive(route.activeStatus);
                            setAssignedDriverId(route.assignedDriverId);
                            setAssignedVehicleId(route.assignedVehicleId);
                            setShowEditModal(true);
                          }}
                        >
                          Edit
                        </button>

                        <button
                          className="btn delete"
                          onClick={() => {
                            setSelectedRoute(route);
                            setShowDeleteModal(true);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Create a Route Modal */}
      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a Route</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="routeName">Route Name:</label>
            <input
              type="text"
              className="form-control"
              id="routeName"
              required
              value={routeName}
              onChange={handleRouteNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <input
              type="text"
              className="form-control"
              id="description"
              required
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="assignedDriver">Assigned Driver:</label>
            <select
              className="form-control"
              id="assignedDriver"
              value={assignedDriverId}
              required
              onChange={handleAssignedDriverChange}
            >
              <option value="">Select a driver</option>
              {drivers.map((driver) => (
                <option key={driver._id} value={driver._id}>
                  {driver.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="assignedVehicle">Assigned Vehicle:</label>
            <select
              className="form-control"
              id="assignedVehicle"
              value={assignedVehicleId}
              required
              onChange={handleAssignedVehicleChange}
            >
              <option value="">Select a Vehicle</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {vehicle.vehicleName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActive"
              checked={isActive}
              onChange={handleIsActiveChange}
            />
            <label className="form-check-label" htmlFor="isActive">
              Active
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setShowCreateModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Create
          </button>
        </Modal.Footer>
      </Modal>

      {/* Route Details Modal */}
      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Route Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRoute && (
            <>
              <p>
                <strong>Route Name:</strong> {selectedRoute.name}
              </p>
              <p>
                <strong>Description:</strong> {selectedRoute.description}
              </p>
              <p>
                <strong>Active:</strong>{" "}
                {selectedRoute.activeStatus ? "Yes" : "No"}
              </p>
              <p>
                <strong>Assigned Driver:</strong>{" "}
                {findVehicle(selectedRoute.assignedVehicleId)}
              </p>
              <p>
                <strong>Assigned Vehicle:</strong>{" "}
                {findMember(selectedRoute.assignedDriverId)}
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this route?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn delete"
            onClick={() => {
              handleDelete();
              setShowDeleteModal(false);
            }}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Route</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="editRouteName">Route Name:</label>
            <input
              type="text"
              className="form-control"
              id="editRouteName"
              required
              value={routeName}
              onChange={handleRouteNameChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="editDescription">Description:</label>
            <input
              type="text"
              className="form-control"
              id="editDescription"
              required
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="editAssignedDriver">Assigned Driver:</label>
            <select
              className="form-control"
              id="editAssignedDriver"
              value={assignedDriverId}
              required
              onChange={handleAssignedDriverChange}
            >
              <option value="">Select a driver</option>
              {drivers.map((driver) => (
                <option key={driver._id} value={driver._id}>
                  {driver.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="editAssignedVehicle">Assigned Vehicle:</label>
            <select
              className="form-control"
              id="editAssignedVehicle"
              value={assignedVehicleId}
              required
              onChange={handleAssignedVehicleChange}
            >
              <option value="">Select a Vehicle</option>
              {vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {vehicle.vehicleName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="editIsActive"
              checked={isActive}
              onChange={handleIsActiveChange}
            />
            <label className="form-check-label" htmlFor="editIsActive">
              Active
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setShowEditModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleEditSave}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateRoutes;

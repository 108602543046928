import React, { useEffect, useState } from "react";
import "./handouts.css";
import {
  fetchCollectionsByPharmacyAction,
  switchCollectionType,
} from "../../../redux/actions/collectionActions";
import {
  fetchShelvesByPharmacyAction,
  removeShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useParams } from "react-router-dom";

function Handouts() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [newCollectionType, setNewCollectionType] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [collectionDetails, setCollectionDetails] = useState({
    collectedName: "",
    collectedPhone: "",
    collectedDate: new Date().toISOString().slice(0, 10), // Default to today's date, formatted as YYYY-MM-DD
  });

  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;
  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };
  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const openDetailsModal = (collectionId) => {
    setCurrentCollectionId(collectionId); // Assuming you have this state from earlier
    setShowDetailsModal(true);
  };

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setCollectionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const submitCollectionDetails = async () => {
    try {
      await dispatch(
        removeShelfAction(
          pharmacyId,
          currentCollectionId,
          "Collected",
          collectionDetails
        )
      );
      setShowDetailsModal(false); // Close the modal
      dispatch(fetchCollectionsByPharmacyAction(pharmacyId)); // Refresh the collections list
    } catch (error) {
      console.error("Error updating collection:", error);
      ErrorNotification("Failed to update collection details");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Unknown Shelf";
  };

  const promptSwitchCollectionType = (collectionId, collectionType) => {
    setCurrentCollectionId(collectionId);
    setNewCollectionType(collectionType);
    setShowConfirmationModal(true);
  };

  const confirmSwitchCollectionType = () => {
    if (currentCollectionId && newCollectionType) {
      dispatch(switchCollectionType(currentCollectionId, newCollectionType));
      setShowConfirmationModal(false); // Close the modal after confirming
    }
  };

  // Filter collections based on 'handout' collectionType and search query by patient name
  const filteredCollections = collections.filter((collection) => {
    // First, filter by collectionType being 'handout'
    return (
      collection.collectionType === "handout" &&
      collection.status === "Pending" &&
      // Then, if there's a search query, further filter by matching the patient name
      (searchQuery
        ? `${collection.patient?.firstName ?? ""} ${
            collection.patient?.middleName ?? ""
          } ${collection.patient?.lastName ?? ""}`
            .toLowerCase()
            .includes(searchQuery)
        : true)
    );
  });

  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching careHomes", err);
    });
  }, [dispatch]);
  return (
    <>
      <div className="mt-100">
        {userCurrentPlan === "Free" ? (
          <div className="information-text">
            <p>
              {" "}
              Setting Collection type to "delivery is disabled for Free Plan
            </p>
          </div>
        ) : (
          <div></div>
        )}
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-md-4">
              <div className="search-input-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
            <table className="table table-bordered w-100">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>NHS Number</th>
                  <th>Address</th>
                  <th>status</th>
                  <th>Shelf Name</th>
                  <th>Collection Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  // Render something when filteredPatients is empty
                  filteredCollections?.length > 0 ? (
                    filteredCollections.map((collection, index) => (
                      <tr key={index}>
                        <td>
                          {collection.patient?.title +
                            " " +
                            collection.patient?.firstName +
                            " " +
                            collection.patient?.lastName}
                        </td>
                        <td>{collection.patient?.nhsNumber}</td>
                        <td>
                          {collection.patient.careHome ? (
                            <div>
                              <h6>Care Home Address:</h6>
                              {/* Display Care Home Address */}
                              <div>
                                <span className="fw-bold">Care Home Name:</span>{" "}
                                {careHomeName(collection.patient.careHome)}
                              </div>
                              <div>
                                <span className="fw-bold">Address:</span>{" "}
                                {careHomeAddress(collection.patient.careHome)}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {/* Display Patient's Address */}
                              <div className="row">
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">
                                    Address Line 1:
                                  </span>{" "}
                                  {collection.patient.addressLine1}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">
                                    Address Line 2:
                                  </span>{" "}
                                  {collection.patient.addressLine2}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">Town Name:</span>{" "}
                                  {collection.patient.townName}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">Post Code:</span>{" "}
                                  {collection.patient.postCode}
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>{collection?.status}</td>
                        <td>{getShelfNameByCollection(collection._id)}</td>
                        <td>
                          <select
                            value={collection.collectionType}
                            onChange={(e) =>
                              promptSwitchCollectionType(
                                collection._id,
                                e.target.value
                              )
                            }
                          >
                            <option value="handout">Handout</option>
                            {userCurrentPlan === "Free" ? (
                              <option value="delivery" disabled>
                                delivery
                              </option>
                            ) : (
                              <option value="delivery">delivery</option>
                            )}
                          </select>
                        </td>

                        <td>
                          <button
                            className="medtrakr-btn"
                            onClick={() => openDetailsModal(collection._id)}
                          >
                            Collected By
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Render something when filteredPatient is also empty
                    <tr>
                      <td colSpan="6">No patients to display</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div
          className="custom-modal"
          style={{
            transition: "ease",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            minWidth: "300px",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
            zIndex: 1050, // Ensure it's above other content
          }}
        >
          <h5>Confirm Switch</h5>
          <p>Are you sure you want to switch the collection type?</p>
          <div className="text-right">
            <button
              onClick={confirmSwitchCollectionType}
              className="btn btn-primary"
            >
              Confirm
            </button>
            <button
              onClick={() => setShowConfirmationModal(false)}
              className="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {showDetailsModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Collection Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDetailsModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="collectedName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="collectedName"
                      name="collectedName"
                      value={collectionDetails.collectedName}
                      onChange={handleDetailChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="collectedPhone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="collectedPhone"
                      name="collectedPhone"
                      value={collectionDetails.collectedPhone}
                      onChange={handleDetailChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="collectedDate" className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="collectedDate"
                      name="collectedDate"
                      value={collectionDetails.collectedDate}
                      onChange={handleDetailChange}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDetailsModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={submitCollectionDetails}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${showDetailsModal ? "modal-backdrop fade show" : ""}`}
      ></div>
    </>
  );
}

export default Handouts;

import React from "react";
import "./adminDashboardBox.css";

function AdminDashboardBox({ indicators }) {
  return (
    <div
      className="container-fluid"
      style={{
        padding: 0,
      }}
    >
      <div className="dashboardMainBox">
        {indicators.map((indicator, index) => (
          <div
            key={index}
            className="dashSubBox"
            style={{ backgroundColor: indicator?.backgroundColor }}
          >
            <div
              className="dashIconContainer"
              style={{ backgroundColor: indicator?.iconBackgroundColor }}
            >
              {React.cloneElement(indicator?.icon, {
                style: { fontSize: "2.5rem", color: indicator?.iconColor },
              })}
            </div>
            <div className="dashInfoBox" style={{ color: indicator.textColor }}>
              <p className="dashCount">{indicator?.count}</p>
              <p className="dashTitle">{indicator?.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboardBox;

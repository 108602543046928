// Reducers/pharmacyReducer.js

import { PHARMACY_TYPES } from "../constants/pharmacyTypes";

const initialState = {
  pharmacy: [],
};

const pharmacyReducer = (state = initialState, action) => {
  switch (action.type) {
    case PHARMACY_TYPES.FETCH_PHARMACY:
      return {
        ...state,
        pharmacy: action.payload,
      };
    case PHARMACY_TYPES.FETCH_ALL_PHARMACY:
      return {
        ...state,
        pharmacy: action.payload,
      };
    default:
      return state;
  }
};

export default pharmacyReducer;

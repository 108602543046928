import { MEMBER_TYPES } from "../constants/memberTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createMemberByPharmacy,
  updateMemberByPharmacy,
  deleteMemberByPharmacy,
  fetchMembersByPharmacy,
} from "../../api/memberApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Member Action
export const createMemberByPharmacyAction =
  (pharmacyId, memberData) => async (dispatch) => {
    try {
      const res = await createMemberByPharmacy(pharmacyId, memberData);
      dispatch({
        type: MEMBER_TYPES.CREATE_MEMBER,
        payload: res.data,
      });
      SuccessNotification("Member created successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Member Action
export const updateMemberByPharmacyAction =
  (pharmacyId, id, memberData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });
    console.log(memberData, "inside redux action");
    try {
      const res = await updateMemberByPharmacy(pharmacyId, id, memberData);

      dispatch({
        type: MEMBER_TYPES.UPDATE_MEMBER,
        payload: { id, ...res.data },
      });

      SuccessNotification("Member updated successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Member Action
export const deleteMemberByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteMemberByPharmacy(pharmacyId, id);

      dispatch({
        type: MEMBER_TYPES.DELETE_MEMBER,
        payload: id,
      });

      SuccessNotification("Member deleted successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Members Action
export const fetchMembersByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchMembersByPharmacy(pharmacyId);

      dispatch({
        type: MEMBER_TYPES.FETCH_MEMBERS,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

import { CARE_HOME_TYPES } from "../constants/careHomeTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createCareHomeByPharmacy,
  fetchCareHomesByPharmacy,
  updateCareHomeByPharmacy,
  deleteCareHomeByPharmacy,
} from "../../api/careHomeApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";
// Create Care Home
export const createCareHomeByPharmacyAction =
  (pharmacyId, careHomeData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await createCareHomeByPharmacy(pharmacyId, careHomeData);

      dispatch({
        type: CARE_HOME_TYPES.CREATE_CARE_HOME,
        payload: res.data,
      });

      // Any other logic needed after successful creation
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Update Care Home
export const updateCareHomeByPharmacyAction =
  (pharmacyId, id, careHomeData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateCareHomeByPharmacy(pharmacyId, id, careHomeData);

      dispatch({
        type: CARE_HOME_TYPES.UPDATE_CARE_HOME,
        payload: { id, ...res.data },
      });

      SuccessNotification("Care Home updated successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Care Home
export const deleteCareHomeByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteCareHomeByPharmacy(pharmacyId, id);
      dispatch({
        type: CARE_HOME_TYPES.DELETE_CARE_HOME,
        payload: id,
      });

      SuccessNotification("Care Home Deleted");
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Care Home
export const fetchCareHomesByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchCareHomesByPharmacy(pharmacyId);

      dispatch({
        type: CARE_HOME_TYPES.FETCH_CARE_HOME,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

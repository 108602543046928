import React from "react";
import "./overlay.css";

const Overlay = () => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>Access Denied</h2>
        <p>Please upgrade your plan to access the page</p>
      </div>
    </div>
  );
};

export default Overlay;

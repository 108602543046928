import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import LoginComponentCard from "../../../Components/LoginComponentCard";
import LoginAnimation from "../../../Components/animations/LoginAnimation.js";
import "./login.css";
export default function Login() {
  return (
    <Container>
      <Row className="main-row">
        <Col
          xs={{ span: 12, order: 2 }} // Animation goes second on smaller screens
          md={{ span: 7, order: 1 }} // Animation goes first on medium and larger screens
          className="row-centered corn-blue"
        >
          <LoginAnimation />
        </Col>
        <Col
          xs={{ span: 12, order: 1 }} // LoginComponentCard goes first on smaller screens
          md={{ span: 5, order: 2 }} // LoginComponentCard goes second on medium and larger screens
          className="row-centered"
        >
          <LoginComponentCard />
        </Col>
      </Row>
    </Container>
  );
}

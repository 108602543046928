import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import "./deliveryListGraph.css";

const COLORS = ["#ff9999", "#66b3ff", "#99ff99", "#ffcc99"];

const DeliveryListGraph = ({ deliveries, members, shelves }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const findMemberName = (id) => {
    const member = members.find((val) => val._id === id);
    return member ? member.name : "noo driver found";
  };
  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Unknown Shelf";
  };
  const data = useMemo(() => {
    const initialData = {
      Today: 0,
      Past: 0,
      "Upcoming 2-3 Days": 0,
      "Later than 5 Days": 0,
    };

    deliveries.forEach((delivery) => {
      const deliveryDate = new Date(delivery.deliveryDate);
      deliveryDate.setHours(0, 0, 0, 0);
      const diffTime = deliveryDate - today;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      if (diffDays === 0) {
        initialData.Today++;
      } else if (diffDays < 0) {
        initialData.Past++;
      } else if (diffDays > 0 && diffDays <= 3) {
        initialData["Upcoming 2-3 Days"]++;
      } else if (diffDays > 5) {
        initialData["Later than 5 Days"]++;
      }
    });

    return Object.entries(initialData).map(([name, value]) => ({
      name,
      value,
    }));
  }, [deliveries]);

  const todaysDeliveries = deliveries.filter((delivery) => {
    const deliveryDate = new Date(delivery.deliveryDate);
    deliveryDate.setHours(0, 0, 0, 0);
    return deliveryDate.getTime() === today.getTime();
  });

  return (
    <div className="graphs-container">
      <div className="title-box">
        <div className="dashboard-titles">Upcoming Deliveries Overview</div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div className="title-box">
        <div className="dashboard-titles">Today's Deliveries</div>
      </div>
      <div className="table-box">
        <Table className="table table-bordered w-100">
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>NHS Number</th>
              <th>Driver Assigned</th>
              <th>Route Assigned</th>
              <th>Delivery Type</th>
              <th>Status</th>
              <th>Rack</th>
              <th>Delivery Date</th>
            </tr>
          </thead>
          <tbody>
            {todaysDeliveries.map((delivery) => (
              <tr key={delivery._id}>
                <td>
                  {delivery.patientRef.displayName ||
                    `${delivery.patientRef.title} ${delivery.patientRef.firstName} ${delivery.patientRef.lastName}`}
                </td>
                <td>{delivery.patientRef.nhsNumber}</td>
                <td>{findMemberName(delivery.routeRef?.assignedDriverId)}</td>
                <td>{delivery.routeRef?.description}</td>
                <td>{delivery.deliveryType}</td>
                <td>{delivery.deliveryStatus}</td>
                <td>{getShelfNameByCollection(delivery.collectionRef)}</td>
                <td>
                  {new Date(delivery.deliveryDate).toLocaleDateString("en-GB")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default DeliveryListGraph;

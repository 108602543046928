import api from "./index";

// Create Plan
export const createPlan = async (planData) => {
  return await api.post(`/create-plan`, planData);
};
// Update Plan
export const updatePlan = async (id, planData) => {
  return await api.put(`/update-plan/${id}`, planData);
};
// Delete Plan
export const deletePlan = async (id) => {
  return await api.delete(`/delete-plan/${id}`);
};
// Fetch Plans
export const fetchPlans = async () => {
  return await api.get(`/plans`);
};

import { DELIVERY_TYPES } from "../constants/deliveryTypes";

const initialState = {
  deliveries: [],
  deliveryReports: [],
};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_TYPES.CREATE_DELIVERY:
      return {
        ...state,
        deliveries: [...state.deliveries, action.payload],
      };
    case DELIVERY_TYPES.UPDATE_DELIVERY:
      return {
        ...state,
        deliveries: state.deliveries.map((delivery) =>
          delivery._id === action.payload._id
            ? { ...delivery, ...action.payload }
            : delivery
        ),
      };
    case DELIVERY_TYPES.DELETE_DELIVERY:
      return {
        ...state,
        deliveries: state.deliveries.filter(
          (delivery) => delivery._id !== action.payload
        ),
      };
    case DELIVERY_TYPES.FETCH_DELIVERIES:
      return {
        ...state,
        deliveries: action.payload,
      };
    case DELIVERY_TYPES.FETCH_DELIVERY_REPORTS:
      return {
        ...state,
        deliveryReports: action.payload,
      };
    default:
      return state;
  }
};

export default deliveryReducer;

import api from "./index";

// Create Mds
export const createMdsByPharmacy = async (pharmacyId, mdsData) => {
  return await api.post(`/${pharmacyId}/create-mds`, mdsData);
};
// Create Mds
export const createMdsWithoutRepeatCycleByPharmacy = async (
  pharmacyId,
  mdsData
) => {
  return await api.post(`/${pharmacyId}/create-mds-no-cycle`, mdsData);
};
// Update Mds
export const updateMdsByPharmacy = async (pharmacyId, id, mdsData) => {
  return await api.put(`/${pharmacyId}/update-mds/${id}`, mdsData);
};
// Update MdsStatus
export const updateMdsStatusByPharmacy = async (pharmacyId, id, status) => {
  return await api.put(`/${pharmacyId}/update-mds-status/${id}`, status);
};
export const updateMdsByPharmacyAndRepeatCycle = async (
  pharmacyId,
  id,
  mdsData
) => {
  return await api.put(
    `/${pharmacyId}/update-mds-by-repeat-cycle/${id}`,
    mdsData
  );
};

// Fetch Mds
export const fetchMdsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-mds`);
};
// Fetch Mds For Reports
export const fetchMdsForReportsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-mds-reports`);
};
// Delete Mds
export const deleteMdsByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-mds/${id}`);
};
export const deleteMdsByPharmacyAndRepeatCycle = async (
  pharmacyId,
  repeatCycleId
) => {
  return await api.delete(
    `/${pharmacyId}/delete-mds-by-repeat-cycle/${repeatCycleId}`
  );
};

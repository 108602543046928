import React, { useMemo, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from "recharts";
import { format, subMonths } from "date-fns";
import "./collectionGraph.css";

const COLORS = ["#b4b1e5", "#b0dbc2"];
const CollectionGraph = ({ collections }) => {
  const [monthsToShow, setMonthsToShow] = useState(3);

  const getMonths = (monthsBack) => {
    let months = [];
    let currentDate = new Date();

    for (let i = monthsBack - 1; i >= 0; i--) {
      let pastDate = subMonths(currentDate, i);
      months.push(format(pastDate, "MMM yyyy"));
    }

    return months;
  };

  const pieData = useMemo(() => {
    const counts = collections.reduce(
      (acc, collection) => {
        if (collection.collectionType === "handout") acc.handout++;
        else if (collection.collectionType === "delivery") acc.delivery++;
        return acc;
      },
      { handout: 0, delivery: 0 }
    );

    return [
      { name: "Handout", value: counts.handout },
      { name: "Delivery", value: counts.delivery },
    ];
  }, [collections]);

  const areaData = useMemo(() => {
    const months = getMonths(monthsToShow);
    const initialData = months.map((month) => ({
      month,
      handout: 0,
      delivery: 0,
    }));

    collections.forEach((collection) => {
      const collectionMonthYear = format(
        new Date(collection.createdAt),
        "MMM yyyy"
      );
      const dataIndex = initialData.findIndex(
        (data) => data.month === collectionMonthYear
      );

      if (dataIndex !== -1) {
        if (collection.collectionType === "handout")
          initialData[dataIndex].handout++;
        else if (collection.collectionType === "delivery")
          initialData[dataIndex].delivery++;
      }
    });

    return initialData;
  }, [collections, monthsToShow]);

  // Function to assign "active" class based on the current monthsToShow state
  const getButtonClass = (months) => {
    return monthsToShow === months ? "active-months" : "";
  };

  return (
    <div className="graphs-container">
      <div className="title-box">
        <div className="dashboard-titles">Collections Overview : </div>{" "}
        <div className="lineGraphButton">
          <button
            className={getButtonClass(3)}
            onClick={() => setMonthsToShow(3)}
          >
            3 Months
          </button>
          <button
            className={getButtonClass(6)}
            onClick={() => setMonthsToShow(6)}
          >
            6 Months
          </button>
          <button
            className={getButtonClass(12)}
            onClick={() => setMonthsToShow(12)}
          >
            12 Months
          </button>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, percent }) =>
              `${name}: ${(percent * 100).toFixed(0)}%`
            }
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={areaData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="handout"
            stackId="1"
            stroke="#8884d8"
            fill="#8884d8"
          />
          <Area
            type="monotone"
            dataKey="delivery"
            stackId="1"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CollectionGraph;

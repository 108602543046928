import React, { useEffect, useState } from "react";
import { fetchAllUsersAction } from "../../../redux/actions/userActions";
import { fetchAllPharmacyAction } from "../../../redux/actions/pharmacyActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Table,
  Form,
  Row,
  Col,
  Pagination,
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import "./userList.css";
export default function UsersList() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [userShowModal, setUserShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const users = useSelector((state) => state.users.users);
  const pharmacies = useSelector((state) => state?.pharmacy.pharmacy) || [];

  const fetchUsers = () => {
    dispatch(fetchAllUsersAction());
  };

  const fetchPharmacies = () => {
    dispatch(fetchAllPharmacyAction());
  };

  useEffect(() => {
    fetchUsers();
    fetchPharmacies();
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const findPharmacyNameById = (pharmacyId) => {
    const pharmacy = pharmacies.find((pharmacy) => pharmacy._id === pharmacyId);
    return pharmacy ? pharmacy.pharmacyName : "Unknown";
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setUserShowModal(true);
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
    users.sort((a, b) => {
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      return 0;
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    if (sortOrder === "asc") return <FaSortUp />;
    return <FaSortDown />;
  };

  return (
    <div className="mt-80 container">
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search by name or email"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("name")}>
              Name {renderSortIcon("name")}
            </th>
            <th onClick={() => handleSort("email")}>
              Email {renderSortIcon("email")}
            </th>
            <th onClick={() => handleSort("currentPlan")}>
              Plan {renderSortIcon("currentPlan")}
            </th>
            <th onClick={() => handleSort("pharmacy")}>
              Pharmacy {renderSortIcon("pharmacy")}
            </th>
            <th onClick={() => handleSort("role")}>
              Role {renderSortIcon("role")}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.currentPlan}</td>
              <td>{findPharmacyNameById(user.pharmacy)}</td>
              <td>{user.role}</td>
              <td>
                <Button variant="primary" onClick={() => handleUserClick(user)}>
                  Details
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-box">
        <Pagination>
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => paginate(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
        <DropdownButton
          title={`Items per page: ${usersPerPage}`}
          onSelect={(e) => setUsersPerPage(Number(e))}
          className="d-inline mx-2"
        >
          {[10, 20, 50, 100].map((number) => (
            <Dropdown.Item key={number} eventKey={number}>
              {number}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      <Modal show={userShowModal} onHide={() => setUserShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser ? (
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedUser.name || ""}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedUser.email || ""}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          ) : (
            <div>Loading user details...</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUserShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

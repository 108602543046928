import api from "./index";

// Create Patient
export const createPatientByPharmacy = async (pharmacyId, patientData) => {
  return await api.post(`/${pharmacyId}/create-patient`, patientData);
};
// Update Patient
export const updatePatientByPharmacy = async (pharmacyId, id, patientData) => {
  return await api.put(`/${pharmacyId}/update-patient/${id}`, patientData);
};
// Delete Patient
export const deletePatientByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-patient/${id}`);
};
// Fetch Patients
export const fetchPatientsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-patients`);
};

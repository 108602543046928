import { toast } from "react-toastify";
import {
  RiCloseFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
} from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";

export const SuccessNotification = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: <RiCheckboxCircleFill style={{ color: "#00F7BF" }} />,
    closeButton: <RiCloseFill style={{ fontSize: "20px" }} />,
  });
};

export const InfoNotification = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: <RiErrorWarningFill style={{ color: "#1BE7FF" }} />,
    closeButton: <RiCloseFill style={{ fontSize: "20px" }} />,
  });
};

export const WarningNotification = (message) => {
  toast.warn(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: <RiErrorWarningFill style={{ color: "#FFC700" }} />,
    closeButton: <RiCloseFill style={{ fontSize: "20px" }} />,
  });
};

export const ErrorNotification = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
    closeButton: <RiCloseFill style={{ fontSize: "20px" }} />,
  });
};

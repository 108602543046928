import React, { useEffect, useState } from "react";
import "./handouts.css";
import {
  fetchCollectionsByPharmacyAction,
  switchCollectionType,
} from "../../../redux/actions/collectionActions";
import {
  fetchShelvesByPharmacyAction,
  removeShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useParams } from "react-router-dom";
import { MdHome, MdLocalHospital } from "react-icons/md";
import { Button, Accordion, Modal } from "react-bootstrap";

function Handouts() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [newCollectionType, setNewCollectionType] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [collectionDetails, setCollectionDetails] = useState({
    collectedName: "",
    collectedPhone: "",
    collectedDate: new Date().toISOString().slice(0, 10),
  });

  // Pagination for overall patients
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Pagination for individual collections inside accordion
  const [patientPagination, setPatientPagination] = useState({});

  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };

  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const openDetailsModal = (collectionId) => {
    setCurrentCollectionId(collectionId);
    setShowDetailsModal(true);
  };

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setCollectionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const submitCollectionDetails = async () => {
    try {
      await dispatch(
        removeShelfAction(
          pharmacyId,
          currentCollectionId,
          "Collected",
          collectionDetails
        )
      );
      setShowDetailsModal(false);
      dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
    } catch (error) {
      console.error("Error updating collection:", error);
      ErrorNotification("Failed to update collection details");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
    setCurrentPage(1); // Reset to first page on search
  };

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Unknown Shelf";
  };

  const promptSwitchCollectionType = (collectionId, collectionType) => {
    setCurrentCollectionId(collectionId);
    setNewCollectionType(collectionType);
    setShowConfirmationModal(true);
  };

  const confirmSwitchCollectionType = () => {
    if (currentCollectionId && newCollectionType) {
      dispatch(switchCollectionType(currentCollectionId, newCollectionType));
      setShowConfirmationModal(false);
    }
  };

  const filteredCollections = collections.filter((collection) => {
    const fullName = `${collection.patient?.firstName ?? ""} ${
      collection.patient?.middleName ?? ""
    } ${collection.patient?.lastName ?? ""}`.toLowerCase();

    const patientAddress = `${collection.patient?.addressLine1 ?? ""} ${
      collection.patient?.addressLine2 ?? ""
    } ${collection.patient?.townName ?? ""} ${
      collection.patient?.postCode ?? ""
    }`.toLowerCase();

    const careHome = careHomes.find(
      (home) => home._id === collection.patient?.careHome
    );
    const careHomeAddress = careHome
      ? `${careHome.name ?? ""} ${careHome.address ?? ""}`.toLowerCase()
      : "";

    const nhsNumber = collection.patient?.nhsNumber?.toLowerCase() ?? "";

    return (
      collection.collectionType === "handout" &&
      collection.status === "Pending" &&
      (fullName.includes(searchQuery) ||
        patientAddress.includes(searchQuery) ||
        careHomeAddress.includes(searchQuery) || // Include care home address in the search
        nhsNumber.includes(searchQuery))
    );
  });

  const groupCollectionsByPatient = (collections) => {
    return collections.reduce((acc, collection) => {
      const patientId = collection.patient._id;
      if (!acc[patientId]) {
        acc[patientId] = {
          patient: collection.patient,
          collections: [],
        };
      }
      acc[patientId].collections.push(collection);
      return acc;
    }, {});
  };

  const groupedCollections = groupCollectionsByPatient(filteredCollections);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on changing items per page
  };

  const handlePatientPageChange = (patientId, pageNumber) => {
    setPatientPagination((prev) => ({
      ...prev,
      [patientId]: {
        ...prev[patientId],
        currentPage: pageNumber,
      },
    }));
  };

  const handlePatientItemsPerPageChange = (patientId, itemsPerPage) => {
    setPatientPagination((prev) => ({
      ...prev,
      [patientId]: {
        ...prev[patientId],
        itemsPerPage,
        currentPage: 1, // Reset to first page on changing items per page
      },
    }));
  };

  const getPaginatedCollections = (patientId, collections) => {
    const patientCurrentPage = patientPagination[patientId]?.currentPage || 1;
    const patientItemsPerPage = patientPagination[patientId]?.itemsPerPage || 5;
    const indexOfLastItem = patientCurrentPage * patientItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - patientItemsPerPage;
    return collections.slice(indexOfFirstItem, indexOfLastItem);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentGroupedCollections = Object.entries(groupedCollections).slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(
    Object.keys(groupedCollections).length / itemsPerPage
  );

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching careHomes", err);
    });
  }, [dispatch, pharmacyId]);

  return (
    <>
      <div className="mt-100">
        {userCurrentPlan === "Free" && (
          <div className="information-text">
            <p>
              Setting Collection type to "delivery" is disabled for Free Plan
            </p>
          </div>
        )}
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="search-input-box">
              <input
                type="text"
                className="form-control"
                placeholder="Search By Name, NHS, Address"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="icon-ledger-container">
            <div>
              <div className="d-flex align-items-center">
                <MdHome
                  size={24}
                  color="green"
                  style={{ marginRight: "8px" }}
                />
                <h6>Patient Address:</h6>
              </div>
              <div className="d-flex align-items-center">
                <MdLocalHospital
                  size={24}
                  color="blue"
                  style={{ marginRight: "8px" }}
                />
                <h6>Care Home Address:</h6>
              </div>
            </div>
          </div>

          <div
            className="accordion-container"
            style={{ margin: "1.5rem 0 0 0" }}
          >
            <Accordion>
              {currentGroupedCollections.map(([patientId, data], index) => {
                const paginatedCollections = getPaginatedCollections(
                  patientId,
                  data.collections
                );
                const totalPatientPages = Math.ceil(
                  data.collections.length /
                    (patientPagination[patientId]?.itemsPerPage || 5)
                );

                return (
                  <Accordion.Item eventKey={index.toString()} key={patientId}>
                    <Accordion.Header>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          {data.patient.firstName} {data.patient.lastName}{" "}
                        </div>
                        <div>NHS: {data.patient.nhsNumber}</div>
                        <div>
                          {data.patient?.careHome ? (
                            <div className="create-patient-address">
                              <div className="d-flex align-items-center">
                                <MdLocalHospital
                                  size={24}
                                  color="blue"
                                  style={{ marginRight: "8px" }}
                                />
                              </div>
                              <div>
                                <p className="create-patient-carehome-name">
                                  {careHomeName(data.patient?.careHome)}
                                </p>
                                <div>
                                  {careHomeAddress(data.patient?.careHome)}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="create-patient-address">
                              <div className="d-flex align-items-center">
                                <MdHome
                                  size={24}
                                  color="green"
                                  style={{ marginRight: "8px" }}
                                />
                              </div>
                              <div>
                                {data.patient?.addressLine1 &&
                                  `${
                                    data.patient?.addressLine1
                                      .trim()
                                      .endsWith(",")
                                      ? data.patient?.addressLine1
                                      : data.patient?.addressLine1 + ","
                                  } `}
                                {data.patient?.addressLine2 &&
                                  `${
                                    data.patient?.addressLine2
                                      .trim()
                                      .endsWith(",")
                                      ? data.patient?.addressLine2
                                      : data.patient?.addressLine2 + ","
                                  } `}
                                {data.patient?.townName &&
                                  `${data.patient?.townName}, `}
                                {data.patient?.postCode}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <table className="table table-bordered w-100">
                        <thead>
                          <tr>
                            <th>Shelf Name</th>
                            <th>Created At</th>
                            <th>Collection Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedCollections.map(
                            (collection, collectionIndex) => (
                              <tr key={collectionIndex}>
                                <td>
                                  {getShelfNameByCollection(collection._id)}
                                </td>
                                <td>{formatDate(collection.createdAt)}</td>
                                <td>
                                  <select
                                    value={collection.collectionType}
                                    onChange={(e) =>
                                      promptSwitchCollectionType(
                                        collection._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="handout">Handout</option>
                                    {userCurrentPlan === "Free" ? (
                                      <option value="delivery" disabled>
                                        delivery
                                      </option>
                                    ) : (
                                      <option value="delivery">delivery</option>
                                    )}
                                  </select>
                                </td>
                                <td>
                                  <Button
                                    className="medtrakr-btn"
                                    onClick={() =>
                                      openDetailsModal(collection._id)
                                    }
                                  >
                                    Collected By
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      <div className="page-number-box">
                        <div>
                          <select
                            className="form-select"
                            value={
                              patientPagination[patientId]?.itemsPerPage || 5
                            }
                            onChange={(e) =>
                              handlePatientItemsPerPageChange(
                                patientId,
                                Number(e.target.value)
                              )
                            }
                          >
                            {[5, 10, 15, 20].map((number) => (
                              <option key={number} value={number}>
                                {number} Collections/page
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="pagination">
                          {[...Array(totalPatientPages).keys()].map(
                            (pageNumber) => (
                              <Button
                                key={pageNumber}
                                onClick={() =>
                                  handlePatientPageChange(
                                    patientId,
                                    pageNumber + 1
                                  )
                                }
                                className={`page-link ${
                                  (patientPagination[patientId]?.currentPage ||
                                    1) ===
                                  pageNumber + 1
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {pageNumber + 1}
                              </Button>
                            )
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>

          <div className="page-number-box">
            <div>
              <select
                className="form-select"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                {[5, 10, 15, 20].map((number) => (
                  <option key={number} value={number}>
                    {number} Patients/page
                  </option>
                ))}
              </select>
            </div>
            <div className="pagination">
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <Button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber + 1)}
                  className={`page-link ${
                    currentPage === pageNumber + 1 ? "active" : ""
                  }`}
                >
                  {pageNumber + 1}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Switch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to switch the collection type?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmSwitchCollectionType}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Details Modal */}
      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Collection Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="collectedName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="collectedName"
                name="collectedName"
                value={collectionDetails.collectedName}
                onChange={handleDetailChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="collectedPhone" className="form-label">
                Phone
              </label>
              <input
                type="number"
                className="form-control"
                id="collectedPhone"
                name="collectedPhone"
                value={collectionDetails.collectedPhone}
                onChange={handleDetailChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="collectedDate" className="form-label">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="collectedDate"
                name="collectedDate"
                value={collectionDetails.collectedDate}
                onChange={handleDetailChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDetailsModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={submitCollectionDetails}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Handouts;

import { PLAN_TYPES } from "../constants/planTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
// API methods
import {
  createPlan,
  updatePlan,
  deletePlan,
  fetchPlans,
} from "../../api/planApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Plan Action
export const createPlanAction = (planData) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.ALERT, payload: { loading: true } });
  try {
    const res = await createPlan(planData);
    dispatch({
      type: PLAN_TYPES.CREATE_PLAN,
      payload: res.data,
    });
    SuccessNotification("Plan created successfully");
  } catch (error) {
    ErrorNotification(error.response.data?.message);
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false, message: error.response.data?.message },
    });
  }
};

// Update Plan Action
export const updatePlanAction = (id, planData) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.ALERT, payload: { loading: true } });
  try {
    const res = await updatePlan(id, planData);
    dispatch({
      type: PLAN_TYPES.UPDATE_PLAN,
      payload: { id, ...res.data },
    });
    SuccessNotification("Plan updated successfully");
  } catch (error) {
    ErrorNotification(error.response.data?.message);
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false, message: error.response.data?.message },
    });
  }
};

// Delete Plan Action
export const deletePlanAction = (id) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.ALERT, payload: { loading: true } });
  try {
    await deletePlan(id);
    dispatch({
      type: PLAN_TYPES.DELETE_PLAN,
      payload: id,
    });
    SuccessNotification("Plan deleted successfully");
  } catch (error) {
    ErrorNotification(error.response.data?.message);
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false, message: error.response.data?.message },
    });
  }
};

// Fetch Plans Action
export const fetchPlansAction = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.ALERT, payload: { loading: true } });
  try {
    const res = await fetchPlans();
    dispatch({
      type: PLAN_TYPES.FETCH_PLANS,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false, message: error.response.data?.message },
    });
  }
};

import { APPOINTMENT_TYPES } from "../constants/appointmentTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createAppointmentByPharmacy,
  updateAppointmentByPharmacy,
  updateAppointmentByPharmacyAndRepeatCycle,
  fetchAppointmentsByPharmacy,
  deleteAppointmentByPharmacy,
  deleteAppointmentByPharmacyAndRepeatCycle,
} from "../../api/appointmentApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Appointment Action
export const createAppointmentByPharmacyAction =
  (pharmacyId, appointmentData) => async (dispatch) => {
    try {
      const res = await createAppointmentByPharmacy(
        pharmacyId,
        appointmentData
      );
      dispatch({
        type: APPOINTMENT_TYPES.CREATE_APPOINTMENT,
        payload: res.data,
      });
      SuccessNotification("Appointment created successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Appointment Action
export const updateAppointmentByPharmacyAction =
  (pharmacyId, id, appointmentData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateAppointmentByPharmacy(
        pharmacyId,
        id,
        appointmentData
      );

      dispatch({
        type: APPOINTMENT_TYPES.UPDATE_APPOINTMENT,
        payload: { id, ...res.data },
      });

      SuccessNotification("Appointment updated successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Action to update appointments by repeat cycle
export const updateAppointmentsByPharmacyAndRepeatCycleAction =
  (pharmacyId, repeatCycleId, appointmentData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateAppointmentByPharmacyAndRepeatCycle(
        pharmacyId,
        repeatCycleId,
        appointmentData
      );

      dispatch({
        type: APPOINTMENT_TYPES.UPDATE_APPOINTMENTS,
        payload: res.data,
      });

      SuccessNotification(
        "All appointments in the series updated successfully"
      );
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Delete Appointment Action
export const deleteAppointmentByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteAppointmentByPharmacy(pharmacyId, id);

      dispatch({
        type: APPOINTMENT_TYPES.DELETE_APPOINTMENT,
        payload: id,
      });

      SuccessNotification("Appointment deleted successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Appointments Action
export const fetchAppointmentsByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchAppointmentsByPharmacy(pharmacyId);

      // Map the fetched data to format dates using moment
      const formattedAppointments = res.data.map((appointment) => ({
        ...appointment,
        start: new Date(appointment.start),
        end: new Date(appointment.end),
        isDraggable: true,
      }));

      dispatch({
        type: APPOINTMENT_TYPES.FETCH_APPOINTMENTS,
        payload: formattedAppointments,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Delete Appointments By Pharmacy and Repeat Cycle Action
export const deleteAppointmentsByPharmacyAndRepeatCycleAction =
  (pharmacyId, repeatCycleId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteAppointmentByPharmacyAndRepeatCycle(
        pharmacyId,
        repeatCycleId
      );

      dispatch({
        type: APPOINTMENT_TYPES.DELETE_APPOINTMENTS,
        payload: repeatCycleId,
      });

      SuccessNotification("All appointments deleted successfully");
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };

import React, { useMemo, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { format, subMonths } from "date-fns";
import "./patientLineGraph.css";

const PatientLineGraph = ({ patients }) => {
  const [monthsToShow, setMonthsToShow] = useState(3);

  const getMonths = (monthsBack) => {
    let months = [];
    let currentDate = new Date();

    for (let i = monthsBack - 1; i >= 0; i--) {
      let pastDate = subMonths(currentDate, i);
      months.push(format(pastDate, "MMM yyyy"));
    }

    return months;
  };

  const data = useMemo(() => {
    const months = getMonths(monthsToShow);
    const initialData = months.map((month) => ({ month, normal: 0, mds: 0 }));

    if (patients && patients.length > 0) {
      // Ensure patients is defined and has elements
      patients.forEach((patient) => {
        const patientMonthYear = format(
          new Date(patient.createdAt),
          "MMM yyyy"
        );
        const dataIndex = initialData.findIndex(
          (data) => data.month === patientMonthYear
        );

        if (dataIndex !== -1) {
          const patientType = patient.patientType || "normal"; // Default to 'normal' if patientType is not defined
          initialData[dataIndex][patientType]++;
        }
      });
    }

    return initialData;
  }, [patients, monthsToShow]);

  // Function to assign "active" class based on the current monthsToShow state
  const getButtonClass = (months) => {
    return monthsToShow === months ? "active-months" : "";
  };

  return (
    <div className="line-graph-container">
      <div className="title-box">
        <div className="dashboard-titles">Patients Added : </div>{" "}
        <div className="lineGraphButton">
          <button
            className={getButtonClass(3)}
            onClick={() => setMonthsToShow(3)}
          >
            3 Months
          </button>
          <button
            className={getButtonClass(6)}
            onClick={() => setMonthsToShow(6)}
          >
            6 Months
          </button>
          <button
            className={getButtonClass(12)}
            onClick={() => setMonthsToShow(12)}
          >
            12 Months
          </button>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="normal" stroke="#8884d8" />
          <Line type="monotone" dataKey="mds" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PatientLineGraph;

import api from "./index";

// Create Delivery
export const createDeliveryByPharmacy = async (pharmacyId, deliveryData) => {
  return await api.post(`/${pharmacyId}/create-delivery`, deliveryData);
};
// Update Delivery
export const updateDeliveryByPharmacy = async (
  pharmacyId,
  id,
  deliveryData
) => {
  return await api.put(`/${pharmacyId}/update-delivery/${id}`, deliveryData);
};
// Delete Delivery
export const deleteDeliveryByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-delivery/${id}`);
};
// Fetch Deliveries
export const fetchDeliveriesByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-deliveries`);
};
// Fetch Delivery Reports
export const fetchDeliveryReportsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-delivery-reports`);
};

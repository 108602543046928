import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RoleBasedRedirectWithUser } from "../../utils/roleBasedRedirect";
import { useSelector } from "react-redux";

const LoadingToRedirectCard = () => {
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState(5);
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currCount) => --currCount);
    }, 1000);
    // redirect once count is 0
    count === 0 && RoleBasedRedirectWithUser(user, navigate, location); // Pass location here
    // cleanup
    return () => clearInterval(interval);
  }, [count, navigate, user, location]); // Add location to dependencies

  return (
    <div className="loader">
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
      <div className="bar4"></div>
      <div className="bar5"></div>
      <div className="bar6"></div>
    </div>
  );
};

export default LoadingToRedirectCard;

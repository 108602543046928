import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/signupOne.json";
import "./loginAnimation.css";
export default class SignupAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false,
      currentQuoteIndex: 0,
      quotes: [
        "Empowering your health journey with easy medicine dispensing.",
        "Book your appointments seamlessly and stay on top of your health schedule.",
        "From shelf to handout, ensuring your wellness is our priority.",
      ],
    };
  }
  componentDidMount() {
    this.quoteInterval = setInterval(() => {
      this.setState((prevState) => ({
        currentQuoteIndex:
          (prevState.currentQuoteIndex + 1) % prevState.quotes.length,
      }));
    }, 6000); // Changes quote seconds
  }

  componentWillUnmount() {
    clearInterval(this.quoteInterval);
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const { quotes, currentQuoteIndex } = this.state;

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        <div className="quote">{quotes[currentQuoteIndex]}</div>
      </div>
    );
  }
}

import { MDS_TYPES } from "../constants/mdsTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createMdsByPharmacy,
  updateMdsByPharmacy,
  updateMdsStatusByPharmacy,
  updateMdsByPharmacyAndRepeatCycle,
  fetchMdsByPharmacy,
  fetchMdsForReportsByPharmacy,
  deleteMdsByPharmacy,
  deleteMdsByPharmacyAndRepeatCycle,
  createMdsWithoutRepeatCycleByPharmacy,
} from "../../api/mdsApi";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Create Mds Action
export const createMdsByPharmacyAction =
  (pharmacyId, mdsData) => async (dispatch) => {
    try {
      const res = await createMdsByPharmacy(pharmacyId, mdsData);
      dispatch({
        type: MDS_TYPES.CREATE_MDS,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };
// Create Mds Action
export const createMdsWithoutRepeatCycleByPharmacyAction =
  (pharmacyId, mdsData) => async (dispatch) => {
    try {
      const res = await createMdsWithoutRepeatCycleByPharmacy(
        pharmacyId,
        mdsData
      );
      dispatch({
        type: MDS_TYPES.CREATE_MDS_NON_CYCLE,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Mds Action
export const updateMdsByPharmacyAction =
  (pharmacyId, id, mdsData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateMdsByPharmacy(pharmacyId, id, mdsData);

      dispatch({
        type: MDS_TYPES.UPDATE_MDS,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Action to update mds by repeat cycle
export const updateMdsByPharmacyAndRepeatCycleAction =
  (pharmacyId, repeatCycleId, mdsData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateMdsByPharmacyAndRepeatCycle(
        pharmacyId,
        repeatCycleId,
        mdsData
      );

      dispatch({
        type: MDS_TYPES.UPDATE_MULTI_MDS,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Update Mds Status Action
export const updateMdsStatusByPharmacyAction =
  (pharmacyId, id, status) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await updateMdsStatusByPharmacy(pharmacyId, id, { status });

      // fetch all MDS records again to update the state,
      dispatch(fetchMdsByPharmacyAction(pharmacyId));
    } catch (error) {
      ErrorNotification(error.response?.data?.message || "An error occurred");

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response?.data?.message || "An error occurred",
        },
      });
    }
  };

// Delete Mds Action
export const deleteMdsByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteMdsByPharmacy(pharmacyId, id);

      dispatch({
        type: MDS_TYPES.DELETE_MDS,
        payload: id,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Mds Action
export const fetchMdsByPharmacyAction = (pharmacyId) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchMdsByPharmacy(pharmacyId);
    // Filter out events without a patient
    const validMds = res.data.filter((mds) => mds.patient !== null);
    const formattedMds = validMds.map((mds) => {
      const patientName = mds.patient.firstName + " " + mds.patient.lastName;
      return {
        ...mds,
        start: new Date(mds.start),
        end: new Date(mds.end),
        isDraggable: true,
        title: `${patientName}`,
      };
    });

    dispatch({
      type: MDS_TYPES.FETCH_MDS, // Make sure this matches with the reducer case
      payload: formattedMds,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

// Fetch Mds Reports Action
export const fetchMdsReportsByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchMdsForReportsByPharmacy(pharmacyId);
      // Filter out MDS reports with no patient
      const validReports = res.data.filter((report) => report.patient !== null);

      dispatch({
        type: MDS_TYPES.FETCH_MDS_REPORTS,
        payload: validReports,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Delete Mds By Pharmacy and Repeat Cycle Action
export const deleteMdsByPharmacyAndRepeatCycleAction =
  (pharmacyId, repeatCycleId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteMdsByPharmacyAndRepeatCycle(pharmacyId, repeatCycleId);

      dispatch({
        type: MDS_TYPES.DELETE_MULTI_MDS,
        payload: repeatCycleId,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };

import api from "./index";

// Create Patient
export const createRouteByPharmacy = async (pharmacyId, routeData) => {
  return await api.post(`/${pharmacyId}/create-route`, routeData);
};

// Update Patient
export const updateRouteByPharmacy = async (pharmacyId, id, routeData) => {
  return await api.put(`/${pharmacyId}/update-route/${id}`, routeData);
};
// Delete Patient
export const deleteRouteByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-route/${id}`);
};
// Fetch Patients
export const fetchRoutesByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-routes`);
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import { fetchDeliveryReportsByPharmacyAction } from "../../../redux/actions/deliveryAction";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import "./deliveryReports.css"; // Assuming you have a CSS file for styles

function DeliveryReports() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortStates, setSortStates] = useState({});
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    dispatch(fetchDeliveryReportsByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);

  const deliveryReports = useSelector(
    (state) => state.delivery.deliveryReports
  );

  const applyFilters = (data) => {
    // Filter by dates first
    let filteredData = data.filter((delivery) => {
      let deliveryDate = new Date(delivery.deliveryDate);
      let filterFromDate = fromDate
        ? new Date(fromDate)
        : new Date("1900-01-01");
      let filterToDate = toDate ? new Date(toDate) : new Date("2100-01-01");
      return deliveryDate >= filterFromDate && deliveryDate <= filterToDate;
    });

    // Apply search query filter
    filteredData = filteredData.filter((delivery) => {
      const fullName =
        `${delivery.patientRef?.firstName} ${delivery.patientRef?.lastName}`.toLowerCase();
      return (
        searchQuery.toLowerCase() === "" ||
        fullName.includes(searchQuery.toLowerCase()) ||
        delivery.patientRef.nhsNumber.toString().includes(searchQuery)
      );
    });

    return filteredData;
  };

  const filteredReports = applyFilters(deliveryReports);

  const groupDeliveryByPatient = (deliveryEntries) => {
    return deliveryEntries.reduce((acc, delivery) => {
      const patientKey = delivery.patientRef ? delivery.patientRef._id : null;
      if (patientKey) {
        if (!acc[patientKey]) {
          acc[patientKey] = {
            patient: delivery.patientRef,
            deliveries: [],
            sortField: "deliveryDate",
            sortDirection: "asc",
            currentPage: 1,
            itemsPerPage: 5,
          };
        }
        acc[patientKey].deliveries.push(delivery);
      }
      return acc;
    }, {});
  };

  const groupedData = groupDeliveryByPatient(filteredReports);

  const handlePageChange = (patientId, page) => {
    setPagination((prev) => ({
      ...prev,
      [patientId]: { ...prev[patientId], currentPage: page },
    }));
  };

  const handleItemsPerPageChange = (patientId, items) => {
    setPagination((prev) => ({
      ...prev,
      [patientId]: { ...prev[patientId], itemsPerPage: items, currentPage: 1 },
    }));
  };

  const handleSortChange = (patientId, field) => {
    setSortStates((prevStates) => {
      const newDirection =
        prevStates[patientId]?.sortField === field &&
        prevStates[patientId]?.sortDirection === "asc"
          ? "desc"
          : "asc";
      return {
        ...prevStates,
        [patientId]: { sortField: field, sortDirection: newDirection },
      };
    });
  };

  const sortDeliveries = (deliveries, sortField, sortDirection) => {
    return deliveries.slice().sort((a, b) => {
      let compareValue = 0;
      if (sortField === "deliveryDate") {
        compareValue = new Date(a.deliveryDate) - new Date(b.deliveryDate);
      } else if (sortField === "deliveryStatus") {
        compareValue = a.deliveryStatus.localeCompare(b.deliveryStatus);
      }
      return sortDirection === "asc" ? compareValue : -compareValue;
    });
  };

  const applyPagination = (entries, cycleId) => {
    const page = pagination[cycleId]?.currentPage || 1;
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const offset = (page - 1) * itemsPerPage;
    return Array.isArray(entries)
      ? entries.slice(offset, offset + itemsPerPage)
      : [];
  };

  const resetFilters = () => {
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
    setItemsPerPage(5); // Reset items per page for the entire list
  };

  const renderCyclePagination = (patientId, totalEntries) => {
    const itemsPerPage = pagination[patientId]?.itemsPerPage || 5;
    const currentPage = pagination[patientId]?.currentPage || 1;
    const totalPageNumbers = Math.ceil(totalEntries / itemsPerPage);

    const pageNumbers = [];
    for (let number = 1; number <= totalPageNumbers; number++) {
      pageNumbers.push(
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <button
            onClick={() => handlePageChange(patientId, number)}
            className="page-link"
          >
            {number}
          </button>
        </li>
      );
    }

    return (
      <nav className="">
        <ul className="custom-table-pagination">{pageNumbers}</ul>
      </nav>
    );
  };

  const renderItemsPerPageSelector = (patientId) => {
    return (
      <div className="items-per-page-selector">
        <select
          value={pagination[patientId]?.itemsPerPage || 5}
          onChange={(e) =>
            handleItemsPerPageChange(patientId, Number(e.target.value))
          }
        >
          {[5, 10, 15, 20, 25].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const totalPages = Math.ceil(Object.keys(groupedData).length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? "active" : ""}`}
        >
          <a href="#!" onClick={() => setCurrentPage(i)} className="page-link">
            {i}
          </a>
        </li>
      );
    }

    return (
      <nav>
        <ul className="pagination">{pageNumbers}</ul>
      </nav>
    );
  };

  return (
    <div className="mt-100">
      <div className="container-fluid">
        <div className="search-input-box">
          <input
            type="text"
            placeholder="Search by name or NHS number..."
            className="form-control mb-3 search-filter-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="date-filter-box">
            <input
              type="date"
              className="form-control mb-3"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <input
              type="date"
              className="form-control mb-3"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
            <button className="btn btn-warning mb-3" onClick={resetFilters}>
              Reset Filters
            </button>
          </div>
        </div>
        <Accordion>
          {Object.entries(groupedData)
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map(([patientId, { patient, deliveries }]) => {
              const { sortField, sortDirection } = sortStates[patientId] || {};
              const sortedDeliveries = sortDeliveries(
                deliveries,
                sortField,
                sortDirection
              );

              return (
                <Accordion.Item eventKey={patientId} key={patientId}>
                  <Accordion.Header className="accordion-patient-name">
                    {patient.firstName} {patient.lastName} - Total Deliveries:{" "}
                    {deliveries.length}
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={patientId}>
                    <Card.Body>
                      <table className="table table-bordered w-100">
                        <thead>
                          <tr>
                            <th>Delivery Type</th>
                            <th>Storage</th>
                            <th>
                              <button
                                className="btn btn-link p-0 sort-button"
                                onClick={() =>
                                  handleSortChange(patientId, "deliveryStatus")
                                }
                              >
                                Status{" "}
                                {sortField === "deliveryStatus" &&
                                  (sortDirection === "asc" ? (
                                    <FaSortUp />
                                  ) : (
                                    <FaSortDown />
                                  ))}
                                {sortField !== "deliveryStatus" && <FaSort />}
                              </button>
                            </th>
                            <th>
                              <button
                                className="btn btn-link p-0 sort-button"
                                onClick={() =>
                                  handleSortChange(patientId, "deliveryDate")
                                }
                              >
                                Delivery Date{" "}
                                {sortField === "deliveryDate" &&
                                  (sortDirection === "asc" ? (
                                    <FaSortUp />
                                  ) : (
                                    <FaSortDown />
                                  ))}
                                {sortField !== "deliveryDate" && <FaSort />}
                              </button>
                            </th>
                            <th>Route</th>
                          </tr>
                        </thead>
                        <tbody>
                          {applyPagination(sortedDeliveries, patientId).map(
                            (delivery) => (
                              <tr key={delivery._id}>
                                <td>{delivery.deliveryType}</td>
                                <td>{delivery.deliveryStorage}</td>
                                <td>{delivery.deliveryStatus}</td>
                                <td>{formatDate(delivery.deliveryDate)}</td>
                                <td>
                                  {delivery.routeRef
                                    ? delivery.routeRef.name
                                    : "-"}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                      <div className="mds-table-pagination-nav-box">
                        <div>
                          {renderCyclePagination(patientId, deliveries.length)}
                        </div>
                        <div>{renderItemsPerPageSelector(patientId)}</div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Accordion.Item>
              );
            })}
        </Accordion>
        <div className="page-number-box">
          {renderPageNumbers()}
          <select
            className="form-select"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
          >
            {[5, 10, 15, 20, 25].map((number) => (
              <option key={number} value={number}>
                Show {number}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default DeliveryReports;

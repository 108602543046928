import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchDeliveriesByPharmacyAction,
  updateDeliveryByPharmacyAction,
} from "../../../redux/actions/deliveryAction";
import { MdArrowUpward, MdArrowDownward, MdEditCalendar } from "react-icons/md";
import { fetchCollectionsByPharmacyAction } from "../../../redux/actions/collectionActions";
import {
  fetchShelvesByPharmacyAction,
  reassignShelfAction,
} from "../../../redux/actions/shelveAction";
import { fetchMembersByPharmacyAction } from "../../../redux/actions/memberAction";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { Modal } from "react-bootstrap";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import Overlay from "../../../Components/Overlay";
import "./delivery.css";
export default function DeliveryList() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const deliveries = useSelector((state) => state.delivery.deliveries);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deliveriesPerPage] = useState(5);
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(false);

  // States for modal visibility and selected delivery
  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [editableDelivery, setEditableDelivery] = useState(null);
  // Assign Shelf
  const [showReassignShelfModal, setShowReassignShelfModal] = useState(false);
  const [reassignCapacityNeeded, setReassignCapacityNeeded] = useState(1);
  const [reassignShelfId, setReassignShelfId] = useState("");

  //  useSelectors
  const routes = useSelector((state) => state.route.routes);
  const members = useSelector((state) => state.member.members);
  const shelves = useSelector((state) => state.shelve.shelves);
  const user = useSelector((state) => state.auth.user);
  const userCurrentPlan = user.currentPlan;
  const sortDeliveries = (a, b) => {
    const dateA = new Date(a.deliveryDate);
    const dateB = new Date(b.deliveryDate);
    if (sortDirection === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  };

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const findMemberName = (id) => {
    const member = members.find((val) => val._id === id);
    return member ? member.name : "noo driver found";
  };

  const filteredDeliveries =
    searchTerm.length > 0
      ? deliveries
          .filter(
            (delivery) =>
              delivery.patientRef.displayName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              delivery.patientRef.nhsNumber.includes(searchTerm)
          )
          .filter(
            (delivery) => delivery.deliveryStatus !== "delivery-completed"
          )
          .sort(sortDeliveries)
      : deliveries
          .filter(
            (delivery) => delivery.deliveryStatus !== "delivery-completed"
          )
          .sort(sortDeliveries);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredDeliveries.length / deliveriesPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Removed";
  };

  const getDeliveryStyle = (deliveryDate) => {
    let style = {
      borderRadius: "10px",
      padding: "5px 10px", // Adjust padding as needed
      fontWeight: "bold",
      textAlign: "center",
    };

    // Current date at 00:00:00 for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Delivery date
    const delivery = new Date(deliveryDate);
    delivery.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    const diffTime = delivery - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    if (diffDays === 0) {
      // Today
      return {
        ...style,
        background: "#def2d0", // Light green background
        color: "#245900", // Dark green text
      };
    } else if (diffDays < 0) {
      // Past
      return {
        ...style,
        background: "#ffdcdc", // Light red background
        color: "#900", // Dark red text
      };
    } else if (diffDays > 0 && diffDays <= 3) {
      // Upcoming 2-3 days
      return {
        ...style,
        background: "#fff3cd", // Light yellow background
        color: "#856404", // Dark yellow text
      };
    } else if (diffDays > 5) {
      // Later than 5 days
      return {
        ...style,
        background: "#d9ecff", // Light blue background
        color: "#004b9a", // Dark blue text
      };
    }

    return style; // Default style if none of the cases match
  };

  // Legend Item Component
  const LegendItem = ({ color, text }) => (
    <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: color,
          marginRight: 5,
        }}
      ></div>
      <span>{text}</span>
    </div>
  );

  // Date Changes
  const handleShowEditModal = (delivery) => {
    setEditableDelivery({
      ...delivery,
      deliveryDate: delivery.deliveryDate.slice(0, 10),
      routeRef: delivery.routeRef?._id,
      paymentOnDelivery: {
        method: delivery.paymentOnDelivery?.method || "exempt",
        amount: delivery.paymentOnDelivery?.amount || 0,
        paymentType: delivery.paymentOnDelivery?.paymentType || "cash",
      },
      userRef: user?._id,
    });
    setShowEditDeliveryModal(true);
  };

  const handleUpdateDelivery = async () => {
    if (!editableDelivery) return;

    setLoading(true);

    try {
      await dispatch(
        updateDeliveryByPharmacyAction(pharmacyId, editableDelivery._id, {
          deliveryDate: editableDelivery.deliveryDate,
          routeRef: editableDelivery.routeRef,
          paymentOnDelivery: editableDelivery.paymentOnDelivery,
          userRef: editableDelivery.userRef,
        })
      );

      // Wait for the update to complete, then refetch deliveries
      await dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
    } catch (error) {
      ErrorNotification("Failed to update delivery. Please try again.");
    } finally {
      setLoading(false);
      setShowEditDeliveryModal(false); // Close the modal
    }
  };

  const handleReassignShelf = () => {
    if (
      !reassignShelfId ||
      !editableDelivery ||
      !editableDelivery.collectionRef
    ) {
      ErrorNotification(
        "Please select a valid shelf and specify the capacity needed."
      );
      return;
    }

    dispatch(
      reassignShelfAction(
        pharmacyId,
        editableDelivery.collectionRef,
        reassignShelfId,
        reassignCapacityNeeded
      )
    )
      .then(() => {
        // Assuming reassignShelfAction returns a Promise, fetch the latest data only after the promise resolves
        dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        setShowReassignShelfModal(false); // Close modal on successful dispatch
      })
      .catch((err) => {
        ErrorNotification(
          "Error during shelf reassignment: " + (err.message || "Unknown error")
        );
      });
  };

  useEffect(() => {
    dispatch(fetchDeliveriesByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);
  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchMembersByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    dispatch(fetchRoutesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching routes", err);
    });
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      } else {
        document.body.classList.remove("no-interaction");
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userCurrentPlan]);

  useEffect(() => {
    const handleClicks = () => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      }
    };

    document.addEventListener("click", handleClicks);

    return () => document.removeEventListener("click", handleClicks);
  }, [userCurrentPlan]);

  return (
    <div className="mt-100">
      {userCurrentPlan === "Free" && <Overlay />}
      <div className="container-fluid">
        {loading ? (
          <div className="overlay-loader">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="row mb-3">
              <div className="search-input-box">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search by patient name or NHS number..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",
              }}
            >
              {/* Legend Container */}
              <div
                className="legend-container"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <LegendItem color="#def2d0" text="Today" />
                <LegendItem color="#ffdcdc" text="Past" />
                <LegendItem color="#fff3cd" text="Upcoming 2-3 Days" />
                <LegendItem color="#d9ecff" text="Later than 5 Days" />
              </div>
            </div>
            <div className="table-container mt-4">
              {loading && (
                <div className="overlay-loader">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <div className={`col-xs-12 ${loading ? "blurred" : ""}`}>
                <table className="table  table-bordered w-100">
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>NHS Number</th>
                      <th>Driver Assigned</th>
                      <th>Route Assigned</th>
                      <th>Delivery Type</th>
                      <th>Status</th>
                      <th>Rack</th>
                      <th>Payment Status</th>
                      <th
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Delivery Date{" "}
                        <button
                          onClick={toggleSortDirection}
                          className="medtrakr-btn"
                        >
                          {sortDirection === "asc" ? (
                            <MdArrowUpward />
                          ) : (
                            <MdArrowDownward />
                          )}
                        </button>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDeliveries
                      .slice(
                        (currentPage - 1) * deliveriesPerPage,
                        currentPage * deliveriesPerPage
                      )
                      .map((delivery) => (
                        <tr key={delivery._id}>
                          <td>
                            {delivery.patientRef?.displayName ||
                              `${delivery.patientRef?.title} ${delivery.patientRef?.firstName} ${delivery.patientRef?.lastName}`}
                          </td>
                          <td>{delivery.patientRef.nhsNumber}</td>
                          <td>
                            {findMemberName(
                              delivery.routeRef?.assignedDriverId
                            )}
                          </td>
                          <td>{delivery.routeRef?.description}</td>
                          <td>{delivery?.deliveryType}</td>
                          <td>{delivery?.deliveryStatus}</td>
                          <td>
                            {getShelfNameByCollection(delivery?.collectionRef)}
                          </td>
                          <td>
                            {delivery.paymentOnDelivery
                              ? delivery.paymentOnDelivery?.method +
                                ": " +
                                delivery.paymentOnDelivery?.amount +
                                " - " +
                                delivery.paymentOnDelivery?.paymentType
                              : ""}
                          </td>
                          <td style={getDeliveryStyle(delivery?.deliveryDate)}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: " center",
                              }}
                            >
                              {new Date(
                                delivery?.deliveryDate
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })}
                            </div>
                          </td>

                          <td>
                            <button
                              onClick={() => handleShowEditModal(delivery)}
                              className="btn update"
                            >
                              <MdEditCalendar />
                            </button>
                            {delivery?.deliveryStatus === "delivery-failed" ? (
                              <button
                                onClick={() => {
                                  setShowReassignShelfModal(true);
                                  setEditableDelivery(delivery); // Set the editableDelivery to the current delivery for reference
                                }}
                                className="btn btn-warning"
                              >
                                <MdEditCalendar /> Reassign Shelf
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <nav>
              <ul className="pagination">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item">
                    <button
                      onClick={() => paginate(number)}
                      className="page-link"
                    >
                      {number}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </>
        )}
      </div>
      {/* Modal To update Delivery Date */}

      <Modal
        show={showEditDeliveryModal}
        onHide={() => setShowEditDeliveryModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Delivery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-3">
              <label htmlFor="editDeliveryDate">Delivery Date</label>
              <input
                type="date"
                className="form-control"
                id="editDeliveryDate"
                value={editableDelivery?.deliveryDate || ""}
                onChange={(e) =>
                  setEditableDelivery({
                    ...editableDelivery,
                    deliveryDate: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="editRouteRef">Route</label>
              <select
                className="form-control"
                id="editRouteRef"
                value={editableDelivery?.routeRef || ""}
                onChange={(e) =>
                  setEditableDelivery({
                    ...editableDelivery,
                    routeRef: e.target.value,
                  })
                }
              >
                <option value="">Select a Route</option>
                {routes.map((route) => (
                  <option key={route._id} value={route._id}>
                    {route.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Payment Method</label>
              <div>
                <input
                  type="radio"
                  id="exempt"
                  name="paymentMethod"
                  value="exempt"
                  checked={
                    editableDelivery?.paymentOnDelivery.method === "exempt"
                  }
                  onChange={() =>
                    setEditableDelivery({
                      ...editableDelivery,
                      paymentOnDelivery: {
                        ...editableDelivery.paymentOnDelivery,
                        method: "exempt",
                        amount: 0,
                      },
                    })
                  }
                />
                <label htmlFor="exempt" className="mr-3">
                  Exempt
                </label>
                <input
                  type="radio"
                  id="collect"
                  name="paymentMethod"
                  value="collect"
                  checked={
                    editableDelivery?.paymentOnDelivery.method === "collect"
                  }
                  onChange={() =>
                    setEditableDelivery({
                      ...editableDelivery,
                      paymentOnDelivery: {
                        ...editableDelivery.paymentOnDelivery,
                        method: "collect",
                      },
                    })
                  }
                />
                <label htmlFor="collect">Collect</label>
              </div>
            </div>
            {editableDelivery?.paymentOnDelivery.method === "collect" && (
              <div className="form-group mb-3">
                <label htmlFor="editPaymentAmount">Amount</label>
                <input
                  type="number"
                  className="form-control"
                  id="editPaymentAmount"
                  value={editableDelivery.paymentOnDelivery.amount || ""}
                  onChange={(e) =>
                    setEditableDelivery({
                      ...editableDelivery,
                      paymentOnDelivery: {
                        ...editableDelivery.paymentOnDelivery,
                        amount: e.target.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowEditDeliveryModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleUpdateDelivery}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      {/* Reassign Shelf Modal */}
      <Modal
        show={showReassignShelfModal}
        onHide={() => setShowReassignShelfModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reassign Shelf</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-3">
              <label htmlFor="newShelfSelect">Select New Shelf:</label>
              <select
                className="form-control"
                id="newShelfSelect"
                value={reassignShelfId}
                onChange={(e) => setReassignShelfId(e.target.value)}
              >
                <option value="">Select a Shelf</option>
                {shelves.map((shelf) => (
                  <option key={shelf._id} value={shelf._id}>
                    {shelf.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="capacityNeeded">Capacity Needed:</label>
              <input
                type="number"
                className="form-control"
                id="capacityNeeded"
                value={reassignCapacityNeeded}
                onChange={(e) =>
                  setReassignCapacityNeeded(Number(e.target.value))
                }
                min="1"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowReassignShelfModal(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleReassignShelf()}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

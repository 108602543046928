import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { resetPassword } from "../../../api/auth";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../../Components/Notifications/ToastNotifications";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle Reset Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    // Validate Email
    if (!email) {
      ErrorNotification("Please enter your email");
      return;
    }

    setLoading(true);
    resetPassword(email)
      .then((res) => {
        SuccessNotification(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        ErrorNotification(err.response.data.type[0].message);
        setLoading(false);
      });
  };

  return (
    <Row className="justify-content-md-center">
      <Col md={6}>
        <Form onSubmit={handleSubmit}>
          <h2 className="text-center mb-4">Reset Password</h2>
          <p className="text-center text-muted">
            We’ll email you instructions on how to reset your password.
          </p>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </Form.Group>

          <Button variant="primary" type="submit" block disabled={loading}>
            Reset Password
          </Button>

          <div className="text-center mt-3">
            <Link to="/login">Go back to Login</Link>
          </div>

          <div className="text-center mt-4">
            <Link to="/privacy">Privacy Policy</Link>
            <span className="mx-2">·</span>
            <Link to="/terms">Terms of Use</Link>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPassword;

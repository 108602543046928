import api from "./index";

// Check if the user is admin
export const currentRole = async () => {
  return await api.post("/currentRole", {});
};

// Send team invite
export const sendTeamInvite = async (pharmacyId, values) => {
  return await api.post(`/${pharmacyId}/admin/team/invite`, values);
};

// Accept team invite
export const acceptTeamInvite = async (values) => {
  return await api.post("/admin/team/account/activate", values);
};

// Get all team members
export const getTeamMembers = async () => {
  return await api.get("/admin/team/members");
};

// Get admin subscription
export const getAdminSubscription = async () => {
  return await api.get("/admin/subscription");
};

// Get admin dashboard analytics
export const getAdminDashboardAnalytics = async () => {
  return await api.get("/admin/dashboard/analytics");
};

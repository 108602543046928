import React from "react";
import ProfileDropdown from "../../Components/ProfileDropdown";
import NotificationDropdown from "../../Components/NotificationDropdown";
import "./menuHeader.css";
const MenuHeader = () => {
  return (
    <header className="navbar navbar-expand-lg navbar-custom">
      <div className="navbar-container">
        <div className="notification-container">
          <NotificationDropdown />
        </div>
        <div className="profile-container">
          <ProfileDropdown />
        </div>
      </div>
    </header>
  );
};

export default MenuHeader;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const SuperAdminDashboard = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <section>
        <div className="mt-80">
          <Row>
            <Col span={24}>
              <h2
                style={{
                  marginLeft: "10px",
                }}
              >
                Welcome back Super Admin, {user?.name.split(" ")[0]} 👋
              </h2>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default SuperAdminDashboard;

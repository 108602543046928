import { PATIENT_TYPES } from "../constants/patientTypes";

const initialState = {
  patients: [],
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATIENT_TYPES.CREATE_PATIENT:
      return {
        ...state,
        patients: [...state.patients, action.payload],
      };
    case PATIENT_TYPES.UPDATE_PATIENT:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient._id === action.payload.id
            ? { ...patient, ...action.payload }
            : patient
        ),
      };
    case PATIENT_TYPES.DELETE_PATIENT:
      return {
        ...state,
        patients: state.patients.filter(
          (patient) => patient._id !== action.payload
        ),
      };
    case PATIENT_TYPES.FETCH_PATIENTS:
      return {
        ...state,
        patients: action.payload,
      };
    default:
      return state;
  }
};

export default patientReducer;

import React, { useState, useEffect, useRef } from "react";
import "./collectionShelves.css";
import { toast } from "react-toastify";
import {
  createShelveByPharmacyAction,
  updateShelveByPharmacyAction,
  deleteShelveByPharmacyAction,
  fetchShelvesByPharmacyAction,
} from "../../../redux/actions/shelveAction";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";

const CollectionShelves = () => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newShelf, setNewShelf] = useState({
    name: "",
    packageSize: "",
    shelfCapacity: "",
  });
  const [mode, setMode] = useState("add");
  const [selectedShelf, setSelectedShelf] = useState(null);
  const shelves = useSelector((state) => state.shelve.shelves);
  const user = useSelector((state) => state.auth.user);

  const handleChange = (e) => {
    setNewShelf({ ...newShelf, [e.target.name]: e.target.value });
  };

  const handleDelete = (shelf) => {
    setSelectedShelf(shelf);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedShelf(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(createShelveByPharmacyAction(pharmacyId, newShelf))
        .then(() => {
          setNewShelf({
            name: "",
            shelfCapacity: "",
            packageSize: "",
          });
          dispatch(fetchShelvesByPharmacyAction(pharmacyId));
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `New shelf '${newShelf.name}' has been created.`,
              user: user._id, // Replace with the actual user ID variable
              forRole: ["admin", "manager"],
              priority: "medium",
            })
          );
          // Reset form values
          setNewShelf({
            name: "",
            shelfCapacity: "",
            packageSize: "",
          });
          // Close modal
          setShowModal(false);
        })
        .catch((error) => {
          console.error(error);
          ErrorNotification("Failed to create shelf.");
        });
    }
  };

  const validateForm = () => {
    const { name, shelfCapacity, packageSize } = newShelf;
    if (!name || !shelfCapacity || !packageSize) {
      ErrorNotification("All fields are required.");
      return false;
    }
    return true;
  };

  const handleConfirmDelete = () => {
    dispatch(deleteShelveByPharmacyAction(pharmacyId, selectedShelf._id))
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Shelf '${selectedShelf.name}' has been deleted.`,
            user: user._id, // Make sure user._id is correctly retrieved
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
        handleCloseDeleteModal();
      })
      .catch((error) => {
        console.error("Error deleting shelf:", error);
      });
  };

  const getShelves = () => {
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching shelves:", error);
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (
      selectedShelf &&
      selectedShelf._id &&
      newShelf.name &&
      newShelf.shelfCapacity
    ) {
      const shelfData = {
        name: newShelf.name,
        shelfCapacity: newShelf.shelfCapacity,
      };

      dispatch(
        updateShelveByPharmacyAction(pharmacyId, selectedShelf._id, shelfData)
      )
        .then(() => {
          setNewShelf({ name: "", packageSize: "", shelfCapacity: "" });
          setSelectedShelf(null);
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `Shelf '${shelfData.name}' details updated successfully.`,
              user: user._id, // Replace with the actual user ID variable
              forRole: ["admin", "manager"],
              priority: "medium",
            })
          );
          // close modal
          setShowModal(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.warning("All values not provided", { position: "top-right" });
    }
  };

  useEffect(() => {
    getShelves();
  }, []); // Add dispatch to dependency array

  const sortedShelves = shelves?.sort((a, b) => {
    const nameA = a.name ? a.name.toUpperCase() : "";
    const nameB = b.name ? b.name.toUpperCase() : "";
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const remainingCapacity = (shelfCapacity, occupiedCapacity) => {
    return shelfCapacity - occupiedCapacity;
  };

  return (
    <div className="mt-100">
      <div className="container-fluid">
        {/* <div className="page-heading">Shelves</div> */}
        <div className="row mb-20">
          <div className="col-xs-12">
            <Button
              className="medtrakr-btn"
              onClick={() => {
                setNewShelf({
                  name: "",
                  shelfCapacity: "",
                  packageSize: "",
                });
                setMode("add");
                setShowModal(true);
              }}
            >
              Create Shelf
            </Button>
          </div>
          <div
            class="modal fade"
            id="shelfModalCenter"
            tabindex="-1"
            role="dialog"
            aria-labelledby="shelfModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Create a shelf
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body row">
                  <div className="col-md-6 col-xs-12 form-field">
                    <label>Package Size:</label>
                    <select
                      name="packageSize"
                      value={newShelf.packageSize}
                      onChange={handleChange}
                      required
                      style={{ padding: "0.5rem", width: "80%" }}
                    >
                      <option>Select Shelf Category</option>
                      <option value="Very Small">Very Small</option>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                      <option value="Very Large">Very Large</option>
                      <option value="Fridge">Fridge</option>
                      <option value="CD">CD</option>
                      <option value="CD+fridge">CD+fridge</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-xs-12 form-field">
                    <label> Shelf Capacity</label>
                    <input
                      type="number"
                      name="shelfCapacity"
                      required
                      value={newShelf.shelfCapacity}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 col-xs-12 form-field">
                    <label>Shelf Name:</label>
                    <input
                      type="text"
                      name="name"
                      required
                      value={newShelf.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn save"
                    data-dismiss="modal"
                    onClick={(e) => {
                      mode === "add" ? handleSubmit(e) : handleEdit(e);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>Shelf Capacity</th>
                <th>Occupied</th>
                <th>Remaining</th>
                <th>Package size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedShelves.map((shelf) => (
                <tr key={shelf._id}>
                  <td>{shelf.name}</td>
                  <td>{shelf.shelfCapacity}</td>
                  <td>{shelf.occupiedCapacity}</td>
                  <td>
                    {remainingCapacity(
                      shelf.shelfCapacity,
                      shelf.occupiedCapacity
                    )}
                  </td>
                  <td>{shelf.packageSize}</td>
                  <td className="button-box">
                    <button
                      size="sm"
                      className="btn update"
                      style={{ marginRight: "0.5rem" }}
                      onClick={() => {
                        setNewShelf({
                          name: shelf.name,
                          shelfCapacity: shelf.shelfCapacity,
                          packageSize: shelf.packageSize,
                        });
                        setSelectedShelf(shelf);
                        setMode("edit");
                        setShowModal(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="btn delete"
                      onClick={() => handleDelete(shelf)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Delete Confirmation Modal */}
          <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Shelf</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this shelf?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showModal} // This should be controlled by a state indicating whether to show the modal
            onHide={() => {
              setShowModal(false);
              setSelectedShelf(null);
              setNewShelf({
                name: "",
                shelfCapacity: "",
                packageSize: "",
              });
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {mode === "add" ? "Create Shelf" : "Edit Shelf"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Package Size:</Form.Label>
                  <Form.Control
                    as="select"
                    name="packageSize"
                    value={newShelf.packageSize}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Shelf Category</option>
                    <option value="Very Small">Very Small</option>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Very Large">Very Large</option>
                    <option value="Fridge">Fridge</option>
                    <option value="CD">CD</option>
                    <option value="CD+fridge">CD+fridge</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Shelf Capacity:</Form.Label>
                  <Form.Control
                    type="number"
                    name="shelfCapacity"
                    value={newShelf.shelfCapacity}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Shelf Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={newShelf.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowModal(false);
                  setSelectedShelf(null);
                  setNewShelf({
                    name: "",
                    shelfCapacity: "",
                    packageSize: "",
                  });
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  mode === "add" ? handleSubmit(e) : handleEdit(e);
                }}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CollectionShelves;

// actions/notificationActions.js
import { NOTIFICATION_TYPES } from "../constants/notificationTypes";
import {
  createNotificationByPharmacy,
  fetchNotificationsByPharmacy,
  updateNotificationById,
  deleteNotificationById,
} from "../../api/notificationsApi";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Create Notification Action
export const createNotificationByPharmacyAction =
  (pharmacyId, notificationData) => async (dispatch) => {
    try {
      const res = await createNotificationByPharmacy(
        pharmacyId,
        notificationData
      );
      dispatch({
        type: NOTIFICATION_TYPES.CREATE_NOTIFICATION,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Fetch Notifications Action
export const fetchNotificationsByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    try {
      const res = await fetchNotificationsByPharmacy(pharmacyId);
      dispatch({
        type: NOTIFICATION_TYPES.FETCH_NOTIFICATIONS,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Notification Action
export const updateNotificationByIdAction =
  (pharmacyId, id, notificationData) => async (dispatch) => {
    try {
      const res = await updateNotificationById(
        pharmacyId,
        id,
        notificationData
      );
      dispatch({
        type: NOTIFICATION_TYPES.UPDATE_NOTIFICATION,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Delete Notification Action
export const deleteNotificationByIdAction =
  (pharmacyId, id) => async (dispatch) => {
    try {
      await deleteNotificationById(pharmacyId, id);
      dispatch({
        type: NOTIFICATION_TYPES.DELETE_NOTIFICATION,
        payload: id,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };
// Update a notification to mark it as read
export const markNotificationAsRead =
  (pharmacyId, notificationId) => async (dispatch) => {
    try {
      const notificationData = { isRead: true }; // Set the isRead flag to true
      const res = await updateNotificationById(
        pharmacyId,
        notificationId,
        notificationData
      );
      dispatch({
        type: NOTIFICATION_TYPES.UPDATE_NOTIFICATION,
        payload: { id: notificationId, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

import { MDS_REPEAT_CYCLE_TYPES } from "../constants/mdsRepeatCycleTypes";

const initialState = {
  mdsRepeatCycles: [],
};

const mdsRepeatCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    case MDS_REPEAT_CYCLE_TYPES.FETCH_MDS_REPEAT_CYCLES:
      return {
        ...state,
        mdsRepeatCycles: action.payload,
      };
    default:
      return state;
  }
};

export default mdsRepeatCycleReducer;

export const MDS_TYPES = {
  CREATE_MDS: "CREATE_MDS",
  CREATE_MDS_NON_CYCLE: "CREATE_MDS_NON_CYCLE",
  FETCH_MDS: "FETCH_MDS",
  FETCH_MDS_REPORTS: "FETCH_MDS_REPORTS",
  UPDATE_MDS: "UPDATE_MDS",
  UPDATE_MDS_STATUS: "UPDATE_MDS_STATUS",
  UPDATE_MULTI_MDS: "UPDATE_MULTI_MDS", // for repeat cycle
  DELETE_MDS: "DELETE_MDS",
  DELETE_MULTI_MDS: "DELETE_MULTI_MDS", // for repeat cycle
};

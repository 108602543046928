import React from "react";
import MyBigCalendar from "../../../Components/BigCalendar";
import "./appointment.css";
export default function Appointment() {
  return (
    <>
      <div className="mt-80">
        <div className="container-fluid">
          <MyBigCalendar />
        </div>
      </div>
    </>
  );
}

import { REPEAT_CYCLE_TYPES } from "../constants/repeatCycleTypes";
import { ACTION_TYPES } from "../constants/actionTypes";

import { fetchRepeatCyclesByPharmacy } from "../../api/repeatCycleApi";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Fetch RepeatCycles Action
export const fetchRepeatCyclesByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchRepeatCyclesByPharmacy(pharmacyId);

      dispatch({
        type: REPEAT_CYCLE_TYPES.FETCH_REPEAT_CYCLES,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

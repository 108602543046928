import React from "react";
import { Row, Col } from "react-bootstrap";

const PaymentCancel = () => {
  return (
    <Row className="mt-60">
      <Col className="" span={24}>
        <Row className="" align="middle" justify="center">
          <Col>
            <h2 className="h1 hp-mb-sm-0 hp-mb-16">Payment failed</h2>
            <p className="hp-mb-32 hp-p1-body">Payment was not successful</p>
          </Col>
        </Row>
      </Col>

      <Col span={24} className="hp-py-24">
        <p className="hp-mb-0 hp-badge-text">
          COPYRIGHT {new Date().getFullYear()} MedTrakr Pvt. Ltd
        </p>
      </Col>
    </Row>
  );
};

export default PaymentCancel;

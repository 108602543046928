import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { fetchMdsReportsByPharmacyAction } from "../../../redux/actions/mdsActions";
import "./mdsReports.css";

export default function MdsReports() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedMds, setSelectedMds] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activeKey, setActiveKey] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("date");
  const mdsReports = useSelector((state) => state.mds.mdsReports);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    dispatch(fetchMdsReportsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching MDS reports", err);
    });
  }, [dispatch, pharmacyId]);

  const groupMdsByPatient = (mdsEntries) => {
    return mdsEntries.reduce((acc, mds) => {
      const patientKey = mds.patient ? mds.patient._id : null;
      const repeatCycleKey = mds.mdsRepeatCycle ? mds.mdsRepeatCycle._id : null;
      if (patientKey) {
        if (!acc[patientKey]) {
          acc[patientKey] = {
            name: `${mds.patient.firstName} ${mds.patient.lastName}`,
            mdsEntries: {},
          };
        }
        if (!acc[patientKey].mdsEntries[repeatCycleKey]) {
          acc[patientKey].mdsEntries[repeatCycleKey] = {
            entries: [],
            currentPage: 1,
            itemsPerPage: 5,
          };
        }
        acc[patientKey].mdsEntries[repeatCycleKey].entries.push(mds);
      }
      return acc;
    }, {});
  };

  const applyFiltersAndSorting = (data) => {
    // Filter by dates first
    let filteredData = data.filter((mds) => {
      let startDate = new Date(mds.start);
      let filterFromDate = fromDate
        ? new Date(fromDate)
        : new Date("1900-01-01");
      let filterToDate = toDate ? new Date(toDate) : new Date("2100-01-01");
      return startDate >= filterFromDate && startDate <= filterToDate;
    });

    // Apply search query filter
    filteredData = filteredData.filter((mds) => {
      const fullName =
        `${mds.patient?.firstName} ${mds.patient?.lastName}`.toLowerCase();
      return (
        searchQuery.toLowerCase() === "" ||
        fullName.includes(searchQuery.toLowerCase()) ||
        mds.patient.nhsNumber.toString().includes(searchQuery)
      );
    });

    // Sort based on the current sort settings
    return filteredData.sort((a, b) => {
      let compareValue = 0;
      if (sortBy === "patientName") {
        compareValue = (a.patient?.firstName ?? "").localeCompare(
          b.patient?.firstName ?? ""
        );
      } else if (sortBy === "nhsNumber") {
        compareValue = (a.patient?.nhsNumber?.toString() ?? "").localeCompare(
          b.patient?.nhsNumber?.toString() ?? ""
        );
      } else if (sortBy === "date") {
        compareValue = new Date(a.start) - new Date(b.start);
      } else if (sortBy === "status") {
        compareValue = (a.status ?? "").localeCompare(b.status ?? "");
      }
      return sortDirection === "asc" ? compareValue : -compareValue;
    });
  };

  const applyPagination = (entries, cycleId) => {
    const page = pagination[cycleId]?.currentPage || 1;
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const offset = (page - 1) * itemsPerPage;
    return Array.isArray(entries)
      ? entries.slice(offset, offset + itemsPerPage)
      : [];
  };

  const handlePageChange = (cycleId, page) => {
    setPagination((prev) => ({
      ...prev,
      [cycleId]: { ...prev[cycleId], currentPage: page },
    }));
  };

  const handleItemsPerPageChange = (cycleId, items) => {
    setPagination((prev) => ({
      ...prev,
      [cycleId]: { ...prev[cycleId], itemsPerPage: items, currentPage: 1 },
    }));
  };

  const groupedData = groupMdsByPatient(applyFiltersAndSorting(mdsReports));

  const totalPages = Math.ceil(Object.keys(groupedData).length / itemsPerPage);

  const renderCyclePagination = (cycleId, totalEntries) => {
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const currentPage = pagination[cycleId]?.currentPage || 1;
    const totalPageNumbers = Math.ceil(totalEntries / itemsPerPage);

    const pageNumbers = [];
    for (let number = 1; number <= totalPageNumbers; number++) {
      pageNumbers.push(
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <button
            onClick={() => handlePageChange(cycleId, number)}
            className="page-link"
          >
            {number}
          </button>
        </li>
      );
    }

    return (
      <nav className="">
        <ul className="custom-table-pagination">{pageNumbers}</ul>
      </nav>
    );
  };

  const renderItemsPerPageSelector = (cycleId) => {
    return (
      <div className="items-per-page-selector">
        <select
          value={pagination[cycleId]?.itemsPerPage || 5}
          onChange={(e) =>
            handleItemsPerPageChange(cycleId, Number(e.target.value))
          }
        >
          {[5, 10, 15, 20, 25].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const handlePageClick = (e, number) => {
    e.preventDefault();
    setCurrentPage(number);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.min(10, totalPages); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <a
                onClick={(e) => handlePageClick(e, number)}
                href="#!"
                className="page-link"
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  const getStatusDate = (statusHistory, statusType) => {
    const status = statusHistory.find((s) => s.status === statusType);
    return status
      ? new Date(status.date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
      : "-";
  };
  const resetFilters = () => {
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
  };

  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <div className="search-input-box">
            <input
              type="text"
              placeholder="Search by name or NHS number..."
              className="form-control mb-3 search-filter-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="date-filter-box">
              <input
                type="date"
                className="form-control mb-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control mb-3"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <button className="btn btn-warning mb-3" onClick={resetFilters}>
                Reset Filters
              </button>
            </div>
          </div>
          <Accordion
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
          >
            {Object.entries(groupedData).map(
              ([patientId, patientData], index) => (
                <Accordion.Item eventKey={patientId} key={patientId}>
                  <Accordion.Header
                    onClick={() =>
                      setActiveKey(activeKey === patientId ? null : patientId)
                    }
                    className="accordion-patient-name"
                  >
                    {patientData.name} - Total Cycles:{" "}
                    {Object.keys(patientData.mdsEntries).length}
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={patientId}>
                    <Card.Body>
                      {Object.entries(patientData.mdsEntries).map(
                        ([cycleId, { entries }], cycleIndex) => (
                          <div
                            key={cycleId}
                            className="table-container"
                            style={{ margin: "1.5rem 0 0 0" }}
                          >
                            <div className="mds-reports-table-title-box">
                              <h5>
                                Number of Entries -{" "}
                                <span className="mds-reports-span">
                                  {entries.length}
                                </span>
                              </h5>
                            </div>
                            <table className="table table-bordered w-100">
                              <thead>
                                <tr>
                                  <th>Medication Name (Dosage)</th>
                                  <th>Rx Requested Date</th>
                                  <th>Prescription Received</th>
                                  <th>Prescription Processed</th>
                                  <th>Dosette Ready Date</th>
                                  <th>Rack</th>
                                  <th>Collection/Delivery Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {applyPagination(entries, cycleId).map(
                                  (mds) => (
                                    <tr key={mds._id}>
                                      <td>
                                        {mds.medicationDetails
                                          .map(
                                            (detail) =>
                                              `${detail.name} (${detail.dosage})`
                                          )
                                          .join(", ")}
                                      </td>
                                      <td>
                                        {getStatusDate(
                                          mds.statusHistory,
                                          "MailSent"
                                        )}
                                      </td>
                                      <td>
                                        {getStatusDate(
                                          mds.statusHistory,
                                          "PrescriptionReceived"
                                        )}
                                      </td>
                                      <td>
                                        {getStatusDate(
                                          mds.statusHistory,
                                          "PrescriptionProcessed"
                                        )}
                                      </td>
                                      <td>
                                        {getStatusDate(
                                          mds.statusHistory,
                                          "DosettePrepared"
                                        )}
                                      </td>
                                      <td>-</td>
                                      <td>-</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            <div className="mds-table-pagination-nav-box">
                              <div>
                                {renderCyclePagination(cycleId, entries.length)}
                              </div>
                              <div>{renderItemsPerPageSelector(cycleId)}</div>
                            </div>
                          </div>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Accordion.Item>
              )
            )}
          </Accordion>
          <div className="page-number-box">
            {renderPageNumbers()}
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              {[5, 10, 15, 20, 25].map((number) => (
                <option key={number} value={number}>
                  Show {number}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

import api from "./index";

// Create Care-home
export const createCareHomeByPharmacy = async (pharmacyId, careHomeData) => {
  return await api.post(`/${pharmacyId}/care-home`, careHomeData);
};
// Update Care-home
export const updateCareHomeByPharmacy = async (
  pharmacyId,
  id,
  careHomeData
) => {
  return await api.put(`/${pharmacyId}/care-home/${id}`, careHomeData);
};
// Delete Care-home
export const deleteCareHomeByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/care-home/${id}`);
};
// Fetch Care=home
export const fetchCareHomesByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/care-homes`);
};

import api from "./index";

// Create Patient
export const createShelveByPharmacy = async (pharmacyId, shelveData) => {
  return await api.post(`/${pharmacyId}/create-shelve`, shelveData);
};

// Update Patient
export const updateShelveByPharmacy = async (pharmacyId, id, shelveData) => {
  return await api.put(`/${pharmacyId}/update-shelve/${id}`, shelveData);
};
// Delete Patient
export const deleteShelveByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-shelve/${id}`);
};
// Fetch Patients
export const fetchShelvesByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-shelves`);
};

// Assign Shelf
export const assignShelf = async (
  pharmacyId,
  shelfId,
  capacityNeeded,
  selectedPatientId,
  collectionType
) => {
  return await api.post(`/${pharmacyId}/shelves/assign`, {
    shelfId,
    capacityNeeded,
    selectedPatientId,
    collectionType,
  });
};
// Re-Assign Shelf
export const reassignShelf = async (
  pharmacyId,
  collectionId,
  newShelfId,
  capacityNeeded
) => {
  return await api.post(`/${pharmacyId}/shelves/reassign`, {
    collectionId,
    newShelfId,
    capacityNeeded,
  });
};

// Remove Shelf
export const removeShelf = async (
  pharmacyId,
  collectionId,
  newStatus,
  collectionDetails
) => {
  return await api.post(`/${pharmacyId}/shelves/remove`, {
    collectionId,
    newStatus,
    collectionDetails,
  });
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Pagination,
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { fetchAllPharmacyAction } from "../../../redux/actions/pharmacyActions";
import "./pharmacyList.css";

export default function PharmacyList() {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pharmaciesPerPage, setPharmaciesPerPage] = useState(10);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    dispatch(fetchAllPharmacyAction());
  }, [dispatch]);

  const pharmacies = useSelector((state) => state.pharmacy.pharmacy) || [];

  const sortedPharmacies = () => {
    return [...pharmacies].sort((a, b) => {
      if (!sortField) return 0;
      if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  };

  const filteredPharmacies = sortedPharmacies().filter(
    (pharmacy) =>
      pharmacy.pharmacyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pharmacy.pharmacyEmail.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredPharmacies.length / pharmaciesPerPage);
  const indexOfLastPharmacy = currentPage * pharmaciesPerPage;
  const indexOfFirstPharmacy = indexOfLastPharmacy - pharmaciesPerPage;
  const currentPharmacies = filteredPharmacies.slice(
    indexOfFirstPharmacy,
    indexOfLastPharmacy
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="mt-80 container">
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Search by pharmacy name or email"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("pharmacyName")}>
              Name {renderSortIcon("pharmacyName")}
            </th>
            <th onClick={() => handleSort("pharmacyAddress")}>
              Address {renderSortIcon("pharmacyAddress")}
            </th>
            <th onClick={() => handleSort("pharmacyEmail")}>
              Email {renderSortIcon("pharmacyEmail")}
            </th>
            <th onClick={() => handleSort("pharmacyPhone")}>
              Phone {renderSortIcon("pharmacyPhone")}
            </th>
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>
          {currentPharmacies.map((pharmacy) => (
            <tr key={pharmacy._id}>
              <td>{pharmacy.pharmacyName}</td>
              <td>{pharmacy.pharmacyAddress}</td>
              <td>{pharmacy.pharmacyEmail}</td>
              <td>{pharmacy.pharmacyPhone}</td>
              <td>{pharmacy.admin ? pharmacy.admin.name : "No Admin"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-box">
        <Pagination>
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => paginate(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
        <DropdownButton
          title={`Items per page: ${pharmaciesPerPage}`}
          onSelect={(e) => setPharmaciesPerPage(Number(e))}
          className="d-inline mx-2"
        >
          {[10, 20, 30, 50].map((number) => (
            <Dropdown.Item key={number} eventKey={number}>
              {number}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    </div>
  );
}

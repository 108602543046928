import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';


i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    createUser: 'Create User',
                    search: 'Search',
                    complaints: 'Complaints',
                    username: 'Username',
                    email: 'Email',
                    role: 'Role',
                    edit: 'Edit',
                    delete: 'Delete',
                    close: 'Close',
                    userDeletedSuccessfully: 'User Deleted Successfully',
                    failedToDeleteUser: 'Failed to delete user',
                    failedToFetchUserList: 'Failed to fetch user list',
                    userDetailsUpdatedSuccessfully: 'User Details Updated Successfully',
                    failedToUpdateUserDetails: 'Failed to update user details',
                    dashboard: "Dashboard",
                    signOut: "Sign Out",
                    complaintId: 'Complaint ID',
                    userId: 'User ID',
                    complaint: 'Complaint',
                    userName: 'User Name',
                    email: 'Email',
                    complaints: 'Complaints',
                    helpDesk: 'Help Desk',
                    enterComplaint: 'Enter your complaint',
                    submit: 'Submit',
                    lowestProduct: 'lowest Product',
                    orders: "Orders",
                    selectedDates: "Selected Dates",
                    selectDateRange: "Select Date Range",
                    help: "Help",
                    ordersChart: "Orders Chart",
                    bestSellingProducts: "Best Selling Products",
                    productName: "Product Name",
                    category: "Category",
                    order: "Order",
                    sales: "Sales",
                    time: "Time",
                    orderSummary: "Order Summary",
                    product: "Product",
                    status: "Status",
                    orderDate: "Order Date",
                    shipped: "Shipped",
                    pending: "Pending",
                    bestSellingCategories: "Best Selling Categories",
                    categories: "Categories",
                    numberOfProducts: "No. of Products",
                    inventorySettings: "Inventory Settings",
                    buyBoxProducts: "Buy Box Products",
                    lowestProduct: "Lowest Product",
                            TopOrders: "Top Orders",
                            Date: "Date",
                            OrdersNotShipped: "Orders Not Shipped",
                            OrderReceived: "Order Received",
                            TotalProductsSold: "Total Products Sold",
                            TotalOrders: "Total Orders",
                            profit:"Profit",
                            SalesTimestamp:"Sales Timestamp",
                            productLoadingStatus: "Product Loading Status",
                            storeTraffic: "Store Traffic",
                            totalProductsSold: "Total Products Sold",
                            StoreTraffic:"Store Traffic",
                            TotalSale:"Total Sale",
                            totalProfit:"Total Profit"

                },
            },
            tr: {
                translation: {
                    createUser: 'Kullanıcı Oluştur',
                    search: 'Ara',
                    complaints: 'Şikayetler',
                    username: 'Kullanıcı Adı',
                    email: 'E-posta',
                    role: 'Rol',
                    edit: 'Düzenle',
                    delete: 'Sil',
                    close: 'Kapat',
                    userDeletedSuccessfully: 'Kullanıcı Başarıyla Silindi',
                    failedToDeleteUser: 'Kullanıcı silinemedi',
                    failedToFetchUserList: 'Kullanıcı listesi alınamadı',
                    userDetailsUpdatedSuccessfully: 'Kullanıcı Detayları Başarıyla Güncellendi',
                    failedToUpdateUserDetails: 'Kullanıcı detayları güncellenemedi',
                    dashboard: "Panel",
                    signOut: "Çıkış Yap",
                    complaintId: 'Şikayet ID',
                    userId: 'Kullanıcı ID',
                    complaint: 'Şikayet',
                    userName: 'Kullanıcı Adı',
                    email: 'E-posta',
                    complaints: 'Şikayetler',
                    helpDesk: 'Yardım Masası',
                    enterComplaint: 'Şikayetinizi girin',
                    submit: 'Gönder',
                    lowestProduct: 'Lowest Product',
                    orders: "Siparişler",
                    selectedDates: "Seçilen Tarihler",
                    selectDateRange: "Tarih Aralığı Seçin",
                    help: "Yardım",
                    ordersChart: "Sipariş Grafiği",
                    bestSellingProducts: "En Çok Satan Ürünler",
                    productName: "Ürün Adı",
                    category: "Kategori",
                    order: "Sipariş",
                    sales: "Satışlar",
                    time: "Zaman",
                    orderSummary: "Sipariş Özeti",
                    product: "Ürün",
                    status: "Durum",
                    orderDate: "Sipariş Tarihi",
                    shipped: "Gönderildi",
                    pending: "Beklemede",
                    bestSellingCategories: "En Çok Satan Kategoriler",
                    categories: "Kategoriler",
                    numberOfProducts: "Ürün Sayısı",
                    inventorySettings: "Envanter Ayarları",
                    buyBoxProducts: "Buy Box Ürünleri",
                    lowestProduct: "En Düşük Ürün",
                        profit:"kâr",
                        TopOrders: "En İyi Siparişler",
                        Date: "Tarih",
                        OrdersNotShipped: "Gönderilmeyen Siparişler",
                        OrderReceived: "Sipariş Alındı",
                        TotalProductsSold: "Toplam Satılan Ürünler",
                        TotalOrders: "Toplam Siparişler",
                        SalesTimestamp:"Satış Zaman Damgası",
                        StoreTraffic:"MağazaTrafik",
                        productLoadingStatus: "Ürün Yükleme Durumu",
                        storeTraffic: "Mağaza Trafik",
                        totalProductsSold: "Toplam Satılan Ürünler",
                        TotalSale:"Toplam satış",
                        totalProfit:"toplam kar"
                },
            },
        },
    });

export default i18n;

import React, { useEffect, useState } from "react";
import "./delivery.css";
import {
  fetchCollectionsByPharmacyAction,
  switchCollectionType,
} from "../../../redux/actions/collectionActions";
import { Modal } from "react-bootstrap";
import { fetchShelvesByPharmacyAction } from "../../../redux/actions/shelveAction";
import { fetchRoutesByPharmacyAction } from "../../../redux/actions/routeAction";
import { createDeliveryByPharmacyAction } from "../../../redux/actions/deliveryAction";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { useDispatch, useSelector } from "react-redux";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useParams } from "react-router-dom";
import Overlay from "../../../Components/Overlay";

function Delivery() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentCollectionId, setCurrentCollectionId] = useState(null);
  const [newCollectionType, setNewCollectionType] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryType, setDeliveryType] = useState("regular");
  const [deliveryStorage, setDeliveryStorage] = useState("ambient");
  const [deliveryStatus, setDeliveryStatus] = useState("assigned-driver");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [patientRef, setPatientRef] = useState("");
  const [routeRef, setRouteRef] = useState("");
  const [collectionRef, setCollectionRef] = useState("");
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  // State for paymentOnDelivery
  const [paymentMethod, setPaymentMethod] = useState("exempt");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentType, setPaymentType] = useState("cash");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const collections = useSelector((state) => state.collection.collections);
  const shelves = useSelector((state) => state.shelve.shelves);
  const routes = useSelector((state) => state.route.routes);
  const user = useSelector((state) => state.auth.user);
  const careHomes = useSelector((state) => state.careHome.careHomes);

  const careHomeName = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.name : "Care name not set";
  };
  const careHomeAddress = (id) => {
    const careHome = careHomes.find((obj) => obj._id === id);
    return careHome ? careHome.address : "Care address not set";
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const getShelfNameByCollection = (collectionId) => {
    const shelf = shelves.find((shelf) =>
      shelf.collectionRef.includes(collectionId)
    );
    return shelf ? shelf.name : "Unknown Shelf";
  };

  const promptSwitchCollectionType = (collectionId, collectionType) => {
    setCurrentCollectionId(collectionId);
    setNewCollectionType(collectionType);
    setShowConfirmationModal(true);
  };

  const confirmSwitchCollectionType = () => {
    if (currentCollectionId && newCollectionType) {
      dispatch(switchCollectionType(currentCollectionId, newCollectionType));
      setShowConfirmationModal(false); // Close the modal after confirming
    }
  };

  // Filter collections based on 'delivery' collectionType and search query by patient name
  const filteredCollections = collections.filter((collection) => {
    // First, filter by collectionType being 'handout'
    return (
      collection.collectionType === "delivery" &&
      !collection.deliveryRef &&
      // Then, if there's a search query, further filter by matching the patient name
      (searchQuery
        ? `${collection.patient?.firstName ?? ""} ${
            collection.patient?.middleName ?? ""
          } ${collection.patient?.lastName ?? ""}`
            .toLowerCase()
            .includes(searchQuery)
        : true)
    );
  });

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(date - timeZoneOffset)
      .toISOString()
      .slice(0, 16);
    return localISOTime;
  };

  const openDeliveryModal = (collection = null) => {
    if (collection) {
      // If a collection is passed, set up the state for editing
      setDeliveryDate(formatDateForInput(collection.deliveryDate)); // Assuming deliveryDate is stored in ISO format
      setDeliveryType(collection.deliveryType);
      setDeliveryStorage(collection.deliveryStorage);
      setDeliveryStatus(collection.status);
      setDeliveryNote(collection.deliveryNote || "");
      setPatientRef(collection.patient?._id || "");
      setCollectionRef(collection._id);
      // Set payment state if editing
      setPaymentMethod(collection.paymentOnDelivery?.method || "exempt");
      setPaymentAmount(collection.paymentOnDelivery?.amount || 0);
      setPaymentType(collection.paymentOnDelivery?.paymentType || "cash");
    } else {
      // Reset the state for adding a new delivery
      setDeliveryDate("");
      setDeliveryType("regular");
      setDeliveryStorage("ambient");
      setDeliveryStatus("assigned-driver");
      setDeliveryNote("");
      setPatientRef("");
      setCollectionRef("");
      setPaymentMethod("exempt");
      setPaymentAmount(0);
      setPaymentType("cash");
    }
    setShowDeliveryModal(true);
  };

  const handleSave = () => {
    const deliveryData = {
      deliveryDate,
      deliveryType,
      deliveryStorage,
      deliveryNote,
      status: deliveryStatus,
      patientRef,
      collectionRef,
      routeRef,
      paymentOnDelivery: {
        method: paymentMethod,
        status: paymentMethod === "exempt" ? "collected" : "pending",
        amount: paymentAmount,
        paymentType,
      },
    };
    dispatch(createDeliveryByPharmacyAction(pharmacyId, deliveryData))
      .then(() => {
        setShowDeliveryModal(false); // Close the modal on success
        // Optionally, clear the form or refresh delivery data
        dispatch(fetchCollectionsByPharmacyAction(pharmacyId));
      })
      .catch((error) => {
        console.error("Error creating/updating delivery:", error);
      });
  };

  const getRoutes = () => {
    try {
      dispatch(fetchRoutesByPharmacyAction(pharmacyId));
    } catch (error) {
      console.log("Routes could not be fetched");
    }
  };

  const getCareHomes = () => {
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
  };

  // Calculate the currently displayed items and total pages
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCollections = filteredCollections.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredCollections.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  useEffect(() => {
    dispatch(fetchCollectionsByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching collection", err);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((err) => {
      ErrorNotification("Error fetching shelves", err);
    });
    getCareHomes();
    getRoutes();
  }, [dispatch]);

  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="search-input-box">
              <input
                type="text"
                placeholder="Search By Name or NHS"
                onChange={handleSearchChange}
                className="search-input"
              />
            </div>
          </div>
          <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
            <table className="table table-bordered w-100">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>NHS Number</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Shelf Name</th>
                  <th>Collection Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  // Render something when filteredPatients is empty
                  currentCollections?.length > 0 ? (
                    currentCollections.map((collection, index) => (
                      <tr key={index}>
                        <td>
                          {collection.patient?.title +
                            " " +
                            collection.patient?.firstName +
                            " " +
                            collection.patient?.lastName}
                        </td>
                        <td>{collection.patient?.nhsNumber}</td>
                        <td>
                          {collection.patient?.careHome ? (
                            <div>
                              <h6>Care Home Address:</h6>
                              {/* Display Care Home Address */}
                              <div>
                                <span className="fw-bold">Care Home Name:</span>{" "}
                                {careHomeName(collection.patient?.careHome)}
                              </div>
                              <div>
                                <span className="fw-bold">Address:</span>{" "}
                                {careHomeAddress(collection.patient?.careHome)}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {/* Display Patient's Address */}
                              <div className="row">
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">
                                    Address Line 1:
                                  </span>{" "}
                                  {collection.patient?.addressLine1}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">
                                    Address Line 2:
                                  </span>{" "}
                                  {collection.patient?.addressLine2}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">Town Name:</span>{" "}
                                  {collection.patient?.townName}
                                </div>
                                <div className="col-md-6 mt-1">
                                  <span className="fw-bold">Post Code:</span>{" "}
                                  {collection.patient?.postCode}
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>{collection?.status}</td>
                        <td>{getShelfNameByCollection(collection._id)}</td>
                        <td>
                          <select
                            value={collection.collectionType}
                            onChange={(e) =>
                              promptSwitchCollectionType(
                                collection._id,
                                e.target.value
                              )
                            }
                          >
                            <option value="delivery">Delivery</option>
                            <option value="handout">Handout</option>
                          </select>
                        </td>

                        <td>
                          <button
                            className="medtrakr-btn"
                            onClick={() => openDeliveryModal(collection)}
                          >
                            Create Delivery
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Render something when filteredPatient is also empty
                    <tr>
                      <td colSpan="6">No patients to display</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
            <div className="page-number-box">
              <div>
                <select
                  className="form-select"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  {[5, 10, 15, 20].map((number) => (
                    <option key={number} value={number}>
                      {number} items/page
                    </option>
                  ))}
                </select>
              </div>
              <div className="pagination">
                {[...Array(totalPages).keys()].map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber + 1)}
                    className={`page-link ${
                      currentPage === pageNumber + 1 ? "active" : ""
                    }`}
                  >
                    {pageNumber + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div
          className="custom-modal"
          style={{
            transition: "ease",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            minWidth: "300px",
            backgroundColor: "white",
            borderRadius: "5px",
            padding: "20px",
            zIndex: 1050, // Ensure it's above other content
          }}
        >
          <h5>Confirm Switch</h5>
          <p>Are you sure you want to switch the collection type?</p>
          <div className="text-right">
            <button
              onClick={confirmSwitchCollectionType}
              className="btn btn-primary"
            >
              Confirm
            </button>
            <button
              onClick={() => setShowConfirmationModal(false)}
              className="btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Create Delivery Modal */}
      <Modal
        show={showDeliveryModal}
        onHide={() => setShowDeliveryModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {collectionRef ? "Edit Delivery" : "Create Delivery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-3">
              <label htmlFor="deliveryDate">Delivery Date</label>
              <input
                type="date"
                className="form-control"
                id="deliveryDate"
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="deliveryType">Delivery Type</label>
              <select
                className="form-control"
                id="deliveryType"
                value={deliveryType}
                onChange={(e) => setDeliveryType(e.target.value)}
              >
                <option value="regular">Regular</option>
                <option value="express">Express</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="deliveryStorage">Delivery Storage</label>
              <select
                className="form-control"
                id="deliveryStorage"
                value={deliveryStorage}
                onChange={(e) => setDeliveryStorage(e.target.value)}
              >
                <option value="ambient">Ambient</option>
                <option value="refrigerated">Refrigerated</option>
                <option value="frozen">Frozen</option>
                <option value="controlled substance">
                  Controlled Substance
                </option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="routeRef">Assign Route</label>
              <select
                className="form-control"
                id="routeRef"
                value={routeRef}
                required
                onChange={(e) => setRouteRef(e.target.value)}
              >
                <option value="">Select a Route</option>
                {routes.map((route) => (
                  <option key={route._id} value={route._id}>
                    {route.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Payment Method</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="exempt"
                  name="paymentMethod"
                  value="exempt"
                  checked={paymentMethod === "exempt"}
                  onChange={() => setPaymentMethod("exempt")}
                />
                <label
                  htmlFor="exempt"
                  className="mr-3"
                  style={{ marginRight: "10px" }}
                >
                  Exempt
                </label>
                <input
                  type="radio"
                  id="collect"
                  name="paymentMethod"
                  value="collect"
                  checked={paymentMethod === "collect"}
                  onChange={() => setPaymentMethod("collect")}
                />
                <label htmlFor="collect">Collect</label>
              </div>
            </div>
            {paymentMethod === "collect" && (
              <>
                <div className="form-group mb-3">
                  <label htmlFor="paymentAmount">Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="paymentAmount"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="paymentType">Payment Type</label>
                  <select
                    className="form-control"
                    id="paymentType"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    <option value="cash">Cash</option>
                    {/* Add more options as needed for future phases */}
                  </select>
                </div>
              </>
            )}

            <div className="form-group mb-3">
              <label htmlFor="deliveryNote">Delivery Note</label>
              <textarea
                className="form-control"
                id="deliveryNote"
                value={deliveryNote}
                onChange={(e) => setDeliveryNote(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeliveryModal(false)}
          >
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Delivery;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { sendTeamInviteActionByPharmacy } from "../../../redux/actions/authActions";
import {
  fetchMembersByPharmacyAction,
  updateMemberByPharmacyAction,
  deleteMemberByPharmacyAction,
} from "../../../redux/actions/memberAction";
import "./createMember.css";
import { useParams } from "react-router-dom";
import Overlay from "../../../Components/Overlay";

function CreateMember() {
  const { pharmacyId } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("manager");
  const [roleType, setRoleType] = useState("");
  const [currentMemberId, setCurrentMemberId] = useState(null);
  const [currentMemberRole, setCurrentMemberRole] = useState("");
  const [currentMemberRoleStatus, setCurrentMemberRoleStatus] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [memberModalShow, setMemberModalShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleMemberClose = () => setMemberModalShow(false);

  const user = useSelector((state) => state.auth.user);
  const userId = user._id;
  const userCurrentPlan = user.currentPlan;
  const members = useSelector((state) => state.member.members);

  const handleTeamInvite = () => {
    const inviteData = { email, role, adminUserId: userId, roleType };
    dispatch(sendTeamInviteActionByPharmacy(pharmacyId, inviteData));
    clearFields();
    handleClose();
  };

  const clearFields = () => {
    setEmail("");
    setRole("manager");
    setRoleType("");
  };

  const handleMemberShow = (member) => () => {
    setCurrentMemberId(member._id);
    setCurrentMemberRole(member.role);
    setCurrentMemberRoleStatus(member.roleStatus);
    setMemberModalShow(true);
  };

  const updateMemberDetails = async () => {
    dispatch(
      updateMemberByPharmacyAction(pharmacyId, currentMemberId, {
        role: currentMemberRole,
        roleStatus: currentMemberRoleStatus,
      })
    );
    handleMemberClose();
    fetchMembers();
  };

  const fetchMembers = () => {
    dispatch(fetchMembersByPharmacyAction(pharmacyId));
  };

  const showDeleteModal = (memberId) => () => {
    setMemberToDelete(memberId);
    setShowDeleteConfirm(true);
  };

  const confirmDeleteMember = () => {
    if (memberToDelete) {
      dispatch(deleteMemberByPharmacyAction(pharmacyId, memberToDelete));
      setMemberToDelete(null);
      setShowDeleteConfirm(false);
      fetchMembers();
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      } else {
        document.body.classList.remove("no-interaction");
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userCurrentPlan]);

  useEffect(() => {
    const handleClicks = () => {
      if (userCurrentPlan === "Free") {
        document.body.classList.add("no-interaction");
      }
    };

    document.addEventListener("click", handleClicks);

    return () => document.removeEventListener("click", handleClicks);
  }, [userCurrentPlan]);

  return (
    <>
      <div className="mt-100">
        {userCurrentPlan === "Free" && <Overlay />}
        <div className="container-fluid">
          <div>
            <button className="medtrakr-btn" onClick={handleShow}>
              Invite Team Member
            </button>

            <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
              <table className="table table-bordered w-100">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Mail Status</th>
                    <th>Role Status</th>
                    <th>Update</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                {members &&
                  members.map((member) => (
                    <tbody className="align-items-center" key={member._id}>
                      <tr>
                        <td
                          data-toggle="modal"
                          data-target="#memberDetailsModalCenter"
                          style={{ cursor: "pointer" }}
                        >
                          {member?.name}
                        </td>

                        <td
                          data-toggle="modal"
                          data-target="#memberDetailsModalCenter"
                          style={{ cursor: "pointer" }}
                        >
                          {member?.email}
                        </td>
                        <td
                          data-toggle="modal"
                          data-target="#memberDetailsModalCenter"
                          style={{ cursor: "pointer" }}
                        >
                          {/* Capitalize Role */}
                          {member?.role.charAt(0).toUpperCase() +
                            member?.role.slice(1)}
                        </td>
                        <td
                          data-toggle="modal"
                          data-target="#memberDetailsModalCenter"
                          style={{ cursor: "pointer" }}
                        >
                          {member?.status.charAt(0).toUpperCase() +
                            member?.status.slice(1)}
                        </td>
                        <td
                          data-toggle="modal"
                          data-target="#memberDetailsModalCenter"
                          style={{ cursor: "pointer" }}
                        >
                          {member?.roleStatus.charAt(0).toUpperCase() +
                            member?.roleStatus.slice(1)}
                        </td>
                        <td>
                          <button
                            className="btn update"
                            onClick={handleMemberShow(member)}
                          >
                            Update
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn delete"
                            onClick={showDeleteModal(member._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role Category</Form.Label>
              <Form.Select
                aria-label="Select Role Category"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="manager">Manager</option>
                <option value="driver">Driver</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Purpose: Assistant / Pharmacist"
                value={roleType}
                onChange={(e) => setRoleType(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleTeamInvite();
              handleClose();
            }}
          >
            Send Invite
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={memberModalShow} onHide={handleMemberClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="updateRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={currentMemberRole}
                onChange={(e) => setCurrentMemberRole(e.target.value)}
              >
                <option value="">Select Role</option>
                <option value="manager">Manager</option>
                <option value="driver">Driver</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="updateStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                value={currentMemberRoleStatus}
                onChange={(e) => setCurrentMemberRoleStatus(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleMemberClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => updateMemberDetails()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteConfirm}
        onHide={() => setShowDeleteConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteMember}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateMember;

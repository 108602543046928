import React, { useState } from "react";
import { Container, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signupAction } from "../../redux/actions/authActions";
import { Link } from "react-router-dom";
import { ErrorNotification } from "../Notifications/ToastNotifications";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the icons from react-icons
import "./signup.css";

const SignUpComponentCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    pharmacyName: "",
    pharmacyAddress: "",
    pharmacyEmail: "",
    pharmacyPhone: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(40, "Name must be less than 40 characters")
      .matches(/^[^<>]*$/, "Invalid characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    pharmacyName: Yup.string().required("Pharmacy Name is required"),
    pharmacyAddress: Yup.string().required("Pharmacy Address is required"),
    pharmacyEmail: Yup.string().email("Invalid pharmacy email address"),
    pharmacyPhone: Yup.string().matches(/^[0-9]+$/, "Invalid phone number"),
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      return {};
    } catch (err) {
      const formattedErrors = err.inner.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.path]: curr.message,
        }),
        {}
      );
      return formattedErrors;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = await validateForm();
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        dispatch(signupAction({ ...formData, setLoading }));
        navigate("/login");
      } catch (error) {
        ErrorNotification(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const getFieldInputType = (key) => {
    if (key === "password") {
      return showPassword ? "text" : "password"; // Toggle between text and password type
    } else if (key === "confirmPassword") {
      return showConfirmPassword ? "text" : "password"; // Toggle between text and password type
    }
    switch (key) {
      case "email":
      case "pharmacyEmail":
        return "email";
      case "pharmacyPhone":
        return "tel";
      default:
        return "text";
    }
  };

  return (
    <Container className="signup-container">
      <div className="signup-box">
        <Form onSubmit={handleSubmit}>
          <h4>Admin Details</h4>
          <hr />
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Password</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={getFieldInputType("password")}
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                style={{ cursor: "pointer" }} // Make the icon clickable
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Confirm Password</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={getFieldInputType("confirmPassword")}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
              />
              <InputGroup.Text
                onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle showConfirmPassword state
                style={{ cursor: "pointer" }} // Make the icon clickable
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <h4 className="mt-4">Pharmacy Details</h4>
          <hr />
          <Form.Group>
            <Form.Label>Pharmacy Name</Form.Label>
            <Form.Control
              type="text"
              name="pharmacyName"
              value={formData.pharmacyName}
              onChange={handleChange}
              isInvalid={!!errors.pharmacyName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pharmacyName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Pharmacy Address</Form.Label>
            <Form.Control
              type="text"
              name="pharmacyAddress"
              value={formData.pharmacyAddress}
              onChange={handleChange}
              isInvalid={!!errors.pharmacyAddress}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pharmacyAddress}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Pharmacy Email</Form.Label>
            <Form.Control
              type="email"
              name="pharmacyEmail"
              value={formData.pharmacyEmail}
              onChange={handleChange}
              isInvalid={!!errors.pharmacyEmail}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pharmacyEmail}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-3">
            <Form.Label>Pharmacy Phone</Form.Label>
            <Form.Control
              type="tel"
              name="pharmacyPhone"
              value={formData.pharmacyPhone}
              onChange={handleChange}
              isInvalid={!!errors.pharmacyPhone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pharmacyPhone}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            type="submit"
            className="signup-button mt-4"
            disabled={loading}
          >
            Sign Up
          </Button>
          <div className="signup-footer mt-3">
            <Link to="/login">Already have an account? Login</Link>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default SignUpComponentCard;

import api from "./index";

// Create Collection
export const createCollection = async (collectionData) => {
  return await api.post("/create-collection", collectionData);
};
// Update Collection
export const updateCollection = async (id, collectionData) => {
  return await api.put(`/update-collection/${id}`, collectionData);
};
// Delete Collection
export const deleteCollection = async (id) => {
  return await api.delete(`/delete-collection/${id}`);
};
// Fetch Collections
export const fetchCollectionsByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-collections`);
};
// Switch Collections
export const switchCollection = async (id) => {
  return api.post(`/collection-switch/${id}`);
};

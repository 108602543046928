import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { fetchCareHomesByPharmacyAction } from "../../../redux/actions/careHomeActions";
import { fetchShelvesByPharmacyAction } from "../../../redux/actions/shelveAction";
import { useParams } from "react-router-dom";
import PatientLineGraph from "../../../Components/AdminGraphs/PatientLineGraph";
import AdminDashboardBox from "../../../Components/AdminDashboardBox";
import {
  MdOutlineArchive,
  MdOutlineHome,
  MdOutlineMedicalInformation,
} from "react-icons/md";
import ShelveMixBarGraph from "../../../Components/AdminGraphs/ShelveMixBarGraph";

function Dashboard() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const patients = useSelector((state) => state.patient.patients);
  const careHomes = useSelector((state) => state.careHome.careHomes);
  const shelves = useSelector((state) => state.shelve.shelves);
  useEffect(() => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId));
    dispatch(fetchCareHomesByPharmacyAction(pharmacyId));
    dispatch(fetchShelvesByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);

  const indicators = [
    {
      title: "Total Patients",
      count: patients.length,
      icon: <MdOutlineMedicalInformation />,
      iconColor: "#b71c1c", // Dark red
      iconBackgroundColor: "#ef9a9a", // Light red
      backgroundColor: "#ffcdd2", // Very light red
      textColor: "#880e4f", // Contrast with the reds
    },
    {
      title: "Total Care Homes",
      count: careHomes.length,
      icon: <MdOutlineHome />,
      iconColor: "#6a1b9a", // Dark purple
      iconBackgroundColor: "#ce93d8", // Light purple
      backgroundColor: "#e1bee7", // Very light purple
      textColor: "#4a148c", // Contrast with the purples
    },
    {
      title: "Total Shelves",
      count: shelves.length,
      icon: <MdOutlineArchive />,
      iconColor: "#1b5e20", // Dark green
      iconBackgroundColor: "#a5d6a7", // Light green
      backgroundColor: "#c8e6c9", // Very light green
      textColor: "#2e7d32", // Contrast with the greens
    },
  ];

  return (
    <div className="mt-60">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <AdminDashboardBox indicators={indicators} />
          </div>
          <div className="col-lg-6 col-md-12">
            <PatientLineGraph patients={patients} />
          </div>
          <div className="col-lg-6 col-md-12">
            <ShelveMixBarGraph shelves={shelves} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import { ACTION_TYPES } from "../constants/actionTypes";
import { AUTH_TYPES } from "../constants/authTypes";
import {
  signUp,
  login,
  logout,
  resetPassword,
  googleCreateOrLogin,
} from "../../api/auth";
import { getUserById } from "../../api/user";
import {
  RoleBasedRedirect,
  RedirectOnLogout,
} from "../../utils/roleBasedRedirect";
import {
  SuccessNotification,
  ErrorNotification,
  InfoNotification,
} from "../../Components/Notifications/ToastNotifications";
import { currentSuperAdmin } from "../../api/superAdmin";
import { currentRole, sendTeamInvite } from "../../api/admin";
/********************************************
  Sign up a user
*********************************************/
export const signupAction = (data) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  data.setLoading(true);

  try {
    // Fetch response from server
    const res = await signUp(
      data.name,
      data.email,
      data.password,
      data.pharmacyName,
      data.pharmacyAddress,
      data.pharmacyEmail,
      data.pharmacyPhone
    );

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: res.data.message,
      },
    });

    SuccessNotification(res.data.message);
    InfoNotification("🥴 Check mail spam if not found 🥴");
    // Clear the form on success
    data.setFormData(data.initialFormData);
    data.setLoading(false);
  } catch (error) {
    data.setLoading(false);

    let errorMessage = "An error has occurred.";
    if (error.response && error.response.data) {
      if (
        error.response.data.type &&
        typeof error.response.data.type === "object" &&
        error.response.data.type.length > 0
      ) {
        errorMessage = error.response.data.type[0].message;
        // Dispatch an error alert with the API response message
        dispatch({
          type: ACTION_TYPES.ALERT,
          payload: { message: errorMessage },
        });
      }
    } else if (error.message) {
      // Handle non-API errors (like network issues)
      errorMessage = error.message;
    }

    ErrorNotification(errorMessage);
  }
};

/********************************************
  Login an user
*********************************************/
export const loginAction = (data) => async (dispatch) => {
  // Dispatch a loading notify
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  data.setLoading(true);
  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await login(data.email, data.password);

    if (res.data.error === true) {
      ErrorNotification(res.data.type[0].message);
      errorMessage = res.data.type[0].message;
      data.setLoading(false);
      return;
    }

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.AUTH,
      payload: {
        accessToken: res.data.accessToken,
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });

    data.setLoading(false);
    RoleBasedRedirect(res, data.navigate, data.location);
  } catch (error) {
    ErrorNotification(error?.response?.data?.type[0]?.message);
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { message: "LOGIN_AUTH_ACTION_ERROR", loading: false },
    });
    data.setLoading(false);
    return Promise.reject(error);
  }
};

/********************************************
 Google login an user 
*********************************************/
export const googleLoginAction = (data) => async (dispatch) => {
  // Dispatch a loading alert
  console.log("(🚀) -  ~ googleLoginAction ~ data in action:", data);
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });
  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await googleCreateOrLogin(data.name, data.email);

    console.log("(🚀) -  ~ googleLoginAction ~ res in action:", res);
    if (res.data.error === true) {
      ErrorNotification(res.data.type[0].message);
      errorMessage = res.data.type[0].message;
      data.setLoading(false);
      return;
    }

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.AUTH,
      payload: {
        accessToken: res.data.accessToken,
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });
    // Redirect user based on role
    RoleBasedRedirect(res, data.navigate, data.location);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "GOOGLE_LOGIN_AUTH_ACTION_ERROR",
      },
    });
  }
};

/********************************************
  Log out an user
*********************************************/
export const logoutAction = () => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });
  try {
    // Fetch response from server
    const res = await logout();

    // Dispatch logout
    dispatch({
      type: AUTH_TYPES.LOGOUT,
      payload: {},
    });

    // Dispatch a success/error logout alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: res.data.message,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
    // Redirect user to login page
    RedirectOnLogout(res);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "LOGOUT_ACTION_ERROR",
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: false },
    });
  }
};
/********************************************
  Is user super admin action
*********************************************/
export const isSuperAdminAction = (data) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Fetch response from server
    const res = await currentSuperAdmin();

    // Dispatch token and user
    dispatch({
      type: AUTH_TYPES.IS_SUPER_ADMIN,
      payload: {
        user: res.data.user,
      },
    });

    // Dispatch a success/error login notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    // Dispatch a error notify
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_SUPER_ADMIN_ACTION_ERROR",
      },
    });
  }
};
/********************************************
  Is user admin action
*********************************************/
export const isAdminCheck = (data) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Add your logic to check if the user is an admin
    const res = await currentRole(); // Replace with actual API call

    dispatch({
      type: AUTH_TYPES.IS_ADMIN,
      payload: {
        user: res.data.user,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_ADMIN_CHECK_ERROR",
      },
    });
  }
};
/********************************************
  Is user Manager action
*********************************************/
export const isManagerCheck = (data) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Add your logic to check if the user is an admin
    const res = await currentRole(); // Replace with actual API call

    dispatch({
      type: AUTH_TYPES.IS_MANAGER,
      payload: {
        user: res.data.user,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_MANAGER_CHECK_ERROR",
      },
    });
  }
};

/********************************************
  Is user driver action
*********************************************/
export const isDriverCheck = (data) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Add your logic to check if the user is an admin
    const res = await currentRole(); // Replace with actual API call

    dispatch({
      type: AUTH_TYPES.IS_DRIVER,
      payload: {
        user: res.data.user,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_DRIVER_CHECK_ERROR",
      },
    });
  }
};

/********************************************
  Is user general action
*********************************************/
export const isGeneralCheck = (data) => async (dispatch) => {
  // Dispatch a loading alert
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    let errorMessage = "";

    // Add your logic to check if the user is an admin
    const res = await currentRole(); // Replace with actual API call

    dispatch({
      type: AUTH_TYPES.IS_GENERAL,
      payload: {
        user: res.data.user,
      },
    });

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: errorMessage ? errorMessage : res.data.message,
      },
    });

    data.setOk(true);
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: "IS_GENERAL_CHECK_ERROR",
      },
    });
  }
};

/********************************************
  Send team invite
*********************************************/
export const sendTeamInviteActionByPharmacy =
  (pharmacyId, inviteData) => async (dispatch) => {
    // Dispatch a loading alert
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      // Fetch response from server
      const res = await sendTeamInvite(pharmacyId, inviteData);

      // Check for a successful response
      if (res.data && res.data.success) {
        // Dispatch a success notification
        SuccessNotification(res.data.message || "Invite sent successfully!");

        // Optionally, you could dispatch an action to update the state,
        // indicating that an invite was sent
        dispatch({
          type: AUTH_TYPES.TEAM_INVITE,
          payload: { inviteSent: true },
        });
      } else {
        // Handle any errors or unsuccessful responses
        ErrorNotification(res.data.message || "Failed to send invite.");
      }

      // Dispatch alert to stop loading
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      // Handle any exceptions during the request
      let errorMessage = "An error occurred while sending the invite.";

      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.type && data.type.length > 0) {
          errorMessage = data.type[0].message || errorMessage;
        } else {
          errorMessage = data.message || errorMessage;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }

      ErrorNotification(errorMessage);

      // Dispatch error alert
      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { message: errorMessage, loading: false },
      });
    }
  };

/********************************************
  Fetch user details by ID
*********************************************/
export const fetchUserByIdAction = (userId) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await getUserById(userId);
    if (res.data) {
      dispatch({
        type: AUTH_TYPES.FETCH_USER,
        payload: {
          user: res.data,
        },
      });

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          loading: false,
          message: "User data fetched successfully",
        },
      });
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    let errorMessage = "Failed to fetch user data";
    if (error.response && error.response.data) {
      errorMessage = error.response.data.message || errorMessage;
    } else if (error.message) {
      errorMessage = error.message;
    }

    ErrorNotification(errorMessage);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { message: errorMessage, loading: false },
    });
  }
};

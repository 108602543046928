import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserById } from "../../../api/user";
import { Modal, Button, Form } from "react-bootstrap";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../../Components/Notifications/ToastNotifications";
import "./profile.css";
import { fetchUserByIdAction } from "../../../redux/actions/authActions";
import { fetchPharmacyAction } from "../../../redux/actions/pharmacyActions";

function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const pharmacy = useSelector((state) => state.pharmacy.pharmacy);

  // State for modal visibility and user form data
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: user?.name,
    email: user?.email,
    role: user?.role,
    dob: user?.dob,
    address: user?.address,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveChanges = async () => {
    try {
      // Remove the email field from the data being sent for update
      const { email, role, ...updateData } = formData;
      const result = await updateUserById(user._id, updateData);
      if (result.success) {
        setShowModal(false);
        dispatch(fetchUserByIdAction(user._id));
        SuccessNotification("Profile Updated");
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      ErrorNotification("Couldn't Update Profile, Try Again Later");
    }
  };

  useEffect(() => {
    dispatch(fetchUserByIdAction(user?._id));
    dispatch(fetchPharmacyAction(user?.pharmacy));
  }, [dispatch]);

  return (
    <div className="mt-100">
      <div className="container-fluid">
        <div>
          <h2>Personal Information</h2>
        </div>
        <hr />
        <div>
          <div className="title-name-box">
            <h2>Contact</h2>
            <button onClick={() => setShowModal(true)} className="edit-btn">
              Edit
            </button>
          </div>
          <div className="profile-details">
            <p>
              <b>Full name</b>: {user?.name}
            </p>
            <p>
              <b>Email</b>: {user?.email}
            </p>
            <p>
              <b>Role</b>: {user?.role}
            </p>
            <p>
              <b>D.O.B</b>: {user?.dob ? user?.dob : <span>Not yet set</span>}
            </p>
            <p>
              <b>Address</b>:{" "}
              {user?.address ? user?.address : <span>Not yet set</span>}
            </p>
          </div>
          <hr />
          <div className="title-name-box">
            <h2>Associated Pharmacy</h2>
          </div>
          <div className="profile-details">
            <p>
              <b>Pharmacy Name</b>: {pharmacy?.pharmacyName}
            </p>
            <p>
              <b>Pharmacy Address</b>: {pharmacy?.pharmacyAddress}
            </p>
            <p>
              <b>Pharmacy Email</b>: {pharmacy?.pharmacyEmail}
            </p>
            <p>
              <b>Pharmacy Phone</b>: {pharmacy?.pharmacyPhone}
            </p>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                disabled
                readOnly
                placeholder={formData.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                name="role"
                value={formData.role}
                disabled
                readOnly
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDOB">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Profile;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import {
  createVehicleByPharmacyAction,
  updateVehicleByPharmacyAction,
  deleteVehicleByPharmacyAction,
  fetchVehiclesByPharmacyAction,
} from "../../../redux/actions/vehicleAction";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
import "./vehicles.css";

function CreateVehicle() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const vehicles = useSelector((state) => state.vehicle.vehicles);
  const user = useSelector((state) => state.auth.user);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [insurance, setInsurance] = useState({});
  const [motDetails, setMotDetails] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const defaultVehicleDetails = {
    vehicleName: "",
    vehicleMake: "",
    vehicleModel: "",
    manufactureYear: "",
    vehicleRegNo: "",
    color: "",
    type: "",
    ownedBy: "",
  };

  const defaultInsurance = {
    insuranceName: "",
    coverDetails: "",
    insuranceStartDate: "",
    insuranceEndDate: "",
    insuranceReminderDate: "",
  };

  const defaultMOT = {
    motStartDate: "",
    motEndDate: "",
    motReminderDate: "",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleVehicleChange = (e) => {
    setVehicleDetails({ ...vehicleDetails, [e.target.name]: e.target.value });
  };

  const handleInsuranceChange = (e) => {
    setInsurance({ ...insurance, [e.target.name]: e.target.value });
  };

  const handleMOTChange = (e) => {
    setMotDetails({ ...motDetails, [e.target.name]: e.target.value });
  };

  const resetForms = () => {
    setVehicleDetails(defaultVehicleDetails);
    setInsurance(defaultInsurance);
    setMotDetails(defaultMOT);
    setModalShow(false);
    setEditMode(false);
  };

  const createNotification = (content, priority) => {
    dispatch(
      createNotificationByPharmacyAction(pharmacyId, {
        content,
        user: user._id, // Assuming you have user ID available
        forRole: ["admin", "manager"],
        priority,
      })
    );
  };

  const handleSaveVehicle = () => {
    const vehicleData = {
      ...vehicleDetails,
      ...insurance,
      ...motDetails,
    };

    const action = editMode
      ? updateVehicleByPharmacyAction(
          pharmacyId,
          selectedVehicle._id,
          vehicleData
        )
      : createVehicleByPharmacyAction(pharmacyId, vehicleData);

    dispatch(action)
      .then(() => {
        resetForms();
        dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
        // Create a notification after saving the vehicle
        const message = editMode
          ? `Vehicle details for ${vehicleDetails.vehicleName} updated successfully.`
          : `A new vehicle, ${vehicleDetails.vehicleName}, has been added.`;
        createNotification(message, "medium");
      })
      .catch((error) => {
        console.error("Failed to save vehicle:", error);
      });
  };

  const handleEdit = (vehicle) => {
    setVehicleDetails({
      vehicleName: vehicle.vehicleName,
      vehicleMake: vehicle.vehicleMake,
      vehicleModel: vehicle.vehicleModel,
      manufactureYear: vehicle.manufactureYear,
      vehicleRegNo: vehicle.vehicleRegNo,
      color: vehicle.color,
      type: vehicle.type,
      ownedBy: vehicle.ownedBy,
    });
    setInsurance({
      insuranceName: vehicle.insuranceName,
      coverDetails: vehicle.coverDetails,
      insuranceStartDate: formatDate(vehicle.insuranceStartDate),
      insuranceEndDate: formatDate(vehicle.insuranceEndDate),
      insuranceReminderDate: formatDate(vehicle.insuranceReminderDate),
    });
    setMotDetails({
      motStartDate: formatDate(vehicle.motStartDate),
      motEndDate: formatDate(vehicle.motEndDate),
      motReminderDate: formatDate(vehicle.motReminderDate),
    });
    setSelectedVehicle(vehicle);
    setEditMode(true);
    setModalShow(true);
  };

  const handleDelete = () => {
    dispatch(deleteVehicleByPharmacyAction(pharmacyId, selectedVehicle._id))
      .then(() => {
        console.log("Vehicle deleted successfully");
        dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
        createNotification(
          `Vehicle ${selectedVehicle.vehicleName} has been deleted.`,
          "high"
        );
      })
      .catch((err) => {
        console.error("Error Deleting Vehicle:", err);
      });
  };

  const handleView = (vehicle) => {
    console.log(vehicle);
    setVehicleDetails({
      vehicleName: vehicle.vehicleName,
      vehicleMake: vehicle.vehicleMake,
      vehicleModel: vehicle.vehicleModel,
      manufactureYear: vehicle.manufactureYear,
      vehicleRegNo: vehicle.vehicleRegNo,
      color: vehicle.color,
      type: vehicle.type,
      ownedBy: vehicle.ownedBy,
    });
    setInsurance({
      insuranceName: vehicle.insuranceName,
      coverDetails: vehicle.coverDetails,
      startDate: formatDate(vehicle.insuranceStartDate),
      endDate: formatDate(vehicle.insuranceEndDate),
      reminderDate: formatDate(vehicle.insuranceReminderDate),
    });
    setMotDetails({
      motStartDate: formatDate(vehicle.motStartDate),
      motEndDate: formatDate(vehicle.motEndDate),
      motReminderDate: formatDate(vehicle.motReminderDate),
    });
    setViewModalShow(true);
  };

  const handleDeleteConfirmed = () => {
    dispatch(deleteVehicleByPharmacyAction(pharmacyId, selectedVehicle._id))
      .then(() => {
        console.log("Vehicle deleted successfully");
        dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
        createNotification(
          `Vehicle ${selectedVehicle.vehicleName} has been deleted.`,
          "high"
        );
        setDeleteModalShow(false); // Hide the confirmation modal after deletion
      })
      .catch((err) => {
        console.error("Error Deleting Vehicle:", err);
        setDeleteModalShow(false); // Hide the confirmation modal on error
      });
  };

  useEffect(() => {
    dispatch(fetchVehiclesByPharmacyAction(pharmacyId));
  }, [dispatch, pharmacyId]);

  return (
    <>
      <div className="mt-100">
        <div className="container-fluid">
          <Button className="medtrakr-btn" onClick={() => setModalShow(true)}>
            Add Vehicle
          </Button>
          <div className="table-container" style={{ margin: "1.5rem 0 0 0" }}>
            <table className="table table-bordered w-100">
              <thead>
                <tr>
                  <th>Vehicle Name</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Manufactured</th>
                  <th>Reg No.</th>
                  <th>Color</th>
                  <th>Type</th>
                  <th>Owned By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {vehicles.map((vehicle) => (
                  <tr key={vehicle._id}>
                    <td>{vehicle.vehicleName}</td>
                    <td>{vehicle.vehicleMake}</td>
                    <td>{vehicle.vehicleModel}</td>
                    <td>{vehicle.manufactureYear}</td>
                    <td>{vehicle.vehicleRegNo}</td>
                    <td>{vehicle.color}</td>
                    <td>{vehicle.type}</td>
                    <td>{vehicle.ownedBy}</td>
                    <td>
                      <button
                        className="btn view"
                        onClick={() => handleView(vehicle)}
                        style={{ marginRight: "0.5rem" }}
                      >
                        View
                      </button>
                      <button
                        className="btn update"
                        onClick={() => handleEdit(vehicle)}
                        style={{ marginRight: "0.5rem" }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn delete"
                        onClick={() => {
                          setSelectedVehicle(vehicle);
                          setDeleteModalShow(true); // Show the confirmation modal
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add or Edit Modal */}

      <Modal show={modalShow} onHide={resetForms} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Vehicle" : "Add New Vehicle"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group controlId="vehicleName" className="col-md-6">
                <Form.Label>Vehicle Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="vehicleName"
                  required
                  value={vehicleDetails.vehicleName}
                  onChange={handleVehicleChange}
                />
              </Form.Group>
              <Form.Group controlId="vehicleMake" className="col-md-6">
                <Form.Label>Vehicle Make:</Form.Label>
                <Form.Control
                  type="text"
                  name="vehicleMake"
                  required
                  value={vehicleDetails.vehicleMake}
                  onChange={handleVehicleChange}
                />
              </Form.Group>
              <Form.Group controlId="vehicleModel" className="col-md-6">
                <Form.Label>Vehicle Model:</Form.Label>
                <Form.Control
                  type="text"
                  name="vehicleModel"
                  required
                  value={vehicleDetails.vehicleModel}
                  onChange={handleVehicleChange}
                />
              </Form.Group>
              <Form.Group controlId="manufactureYear" className="col-md-6">
                <Form.Label>Manufacture Year:</Form.Label>
                <Form.Control
                  type="text"
                  name="manufactureYear"
                  required
                  value={vehicleDetails.manufactureYear}
                  onChange={handleVehicleChange}
                />
              </Form.Group>
              <Form.Group controlId="vehicleRegNo" className="col-md-6">
                <Form.Label>Vehicle Reg No:</Form.Label>
                <Form.Control
                  type="text"
                  name="vehicleRegNo"
                  required
                  value={vehicleDetails.vehicleRegNo}
                  onChange={handleVehicleChange}
                />
              </Form.Group>
              <Form.Group controlId="color" className="col-md-6">
                <Form.Label>Vehicle Color:</Form.Label>
                <Form.Control
                  type="text"
                  name="color"
                  required
                  value={vehicleDetails.color}
                  onChange={handleVehicleChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Type:</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={vehicleDetails.type}
                  onChange={handleVehicleChange}
                >
                  <option value="">Select Vehicle Type</option>
                  <option value="car">Car</option>
                  <option value="bike">Bike</option>
                  <option value="van">Van</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label>Owned By:</Form.Label>
                <Form.Control
                  as="select"
                  name="ownedBy"
                  value={vehicleDetails.ownedBy}
                  onChange={handleVehicleChange}
                >
                  <option value="">Select Owner Type</option>
                  <option value="driver">Driver</option>
                  <option value="company">Company</option>
                </Form.Control>
              </Form.Group>
              {/* Insurance Details */}
              <div className="row">
                <h4 className="mt-4 mb-3">Insurance Details</h4>
                <Form.Group className="col-md-6">
                  <Form.Label>Provider Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="insuranceName"
                    value={insurance.insuranceName}
                    onChange={handleInsuranceChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>Cover Details:</Form.Label>
                  <Form.Control
                    type="text"
                    name="coverDetails"
                    value={insurance.coverDetails}
                    onChange={handleInsuranceChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="insuranceStartDate"
                    value={insurance.insuranceStartDate}
                    onChange={handleInsuranceChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="insuranceEndDate"
                    value={insurance.insuranceEndDate}
                    onChange={handleInsuranceChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>Reminder Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="insuranceReminderDate"
                    value={insurance.insuranceReminderDate}
                    onChange={handleInsuranceChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>Insurance Copy:</Form.Label>
                  <Form.Control
                    type="file"
                    name="insuranceCopy"
                    value={insurance.insuranceCopy}
                    onChange={handleInsuranceChange}
                  />
                </Form.Group>
              </div>
              {/*MOT details*/}
              <div className="row">
                <h4 className="mt-4 mb-3">MOT Details</h4>
                <Form.Group className="col-md-6">
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="motStartDate"
                    value={motDetails.motStartDate}
                    onChange={handleMOTChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="motEndDate"
                    value={motDetails.motEndDate}
                    onChange={handleMOTChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>Reminder Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="motReminderDate"
                    value={motDetails.motReminderDate}
                    onChange={handleMOTChange}
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>MOT File:</Form.Label>
                  <Form.Control
                    type="file"
                    name="insuranceCopy"
                    value={motDetails.insuranceCopy}
                    onChange={handleMOTChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetForms}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveVehicle}>
            {editMode ? "Save Changes" : "Add Vehicle"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Vehicle Details Modal */}
      <Modal
        show={viewModalShow}
        onHide={() => setViewModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="vehicleName"
              >
                <Form.Label column sm="4">
                  Vehicle Name:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.vehicleName}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="vehicleMake"
              >
                <Form.Label column sm="4">
                  Make:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.vehicleMake}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="vehicleModel"
              >
                <Form.Label column sm="4">
                  Model:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.vehicleModel}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="manufactureYear"
              >
                <Form.Label column sm="4">
                  Manufacture Year:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.manufactureYear}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="vehicleRegNo"
              >
                <Form.Label column sm="4">
                  Vehicle Reg No:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.vehicleRegNo}
                />
              </Form.Group>
              <Form.Group as={Form.Row} className="col-md-6" controlId="color">
                <Form.Label column sm="4">
                  Color:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.color}
                />
              </Form.Group>
              <Form.Group as={Form.Row} className="col-md-6" controlId="type">
                <Form.Label column sm="4">
                  Type:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.type}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="ownedBy"
              >
                <Form.Label column sm="4">
                  Owned By:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={vehicleDetails.ownedBy}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="insuranceName"
              >
                <Form.Label column sm="4">
                  Insurance Name:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={insurance.insuranceName}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="coverDetails"
              >
                <Form.Label column sm="4">
                  Cover Details:
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={insurance.coverDetails}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="startDate"
              >
                <Form.Label column sm="4">
                  Insurance Start Date:
                </Form.Label>
                <Form.Control
                  type="date"
                  readOnly
                  value={insurance.startDate}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="endDate"
              >
                <Form.Label column sm="4">
                  Insurance End Date:
                </Form.Label>
                <Form.Control type="date" readOnly value={insurance.endDate} />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="reminderDate"
              >
                <Form.Label column sm="4">
                  Insurance Reminder Date:
                </Form.Label>
                <Form.Control
                  type="date"
                  readOnly
                  value={insurance.reminderDate}
                />
              </Form.Group>
            </div>
            <div className="row">
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="motStartDate"
              >
                <Form.Label column sm="4">
                  MOT Start Date:
                </Form.Label>
                <Form.Control
                  type="date"
                  readOnly
                  value={motDetails.motStartDate}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="motEndDate"
              >
                <Form.Label column sm="4">
                  MOT End Date:
                </Form.Label>
                <Form.Control
                  type="date"
                  readOnly
                  value={motDetails.motEndDate}
                />
              </Form.Group>
              <Form.Group
                as={Form.Row}
                className="col-md-6"
                controlId="motReminderDate"
              >
                <Form.Label column sm="4">
                  MOT Reminder Date:
                </Form.Label>
                <Form.Control
                  type="date"
                  readOnly
                  value={motDetails.motReminderDate}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setViewModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this vehicle?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => setDeleteModalShow(false)}
          >
            Cancel
          </button>
          <button className="btn delete" onClick={handleDeleteConfirmed}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateVehicle;

export const RoleBasedRedirect = (res, navigate, location) => {
  const user = res.data.user;

  if (!user) {
    navigate(location.pathname);
  } else {
    // Redirect based on user role
    switch (user.role) {
      case "superadmin":
        navigate("/super-admin/dashboard");
        break;
      case "admin":
        if (user.pharmacy) {
          // Use the user's pharmacy ID dynamically in the URL
          navigate(`/admin/${user.pharmacy}/dashboard`);
        } else {
          navigate("/");
        }
        break;
      case "manager":
        if (user.pharmacy) {
          navigate(`/manager/${user.pharmacy}/dashboard`);
        } else {
          navigate("/");
        }
        break;
      case "driver":
        if (user.pharmacy) {
          navigate(`/driver/${user.pharmacy}/dashboard`);
        } else {
          navigate("/");
        }
        break;
      case "general":
        if (user.pharmacy) {
          navigate(`/general/dashboard`);
        } else {
          navigate("/");
        }
        break;
      default:
        navigate("/");
        break;
    }
  }
};

export const RoleBasedRedirectWithUser = (user, navigate, location) => {
  const intended = location && location.state ? location.state.from : null;
  if (intended) {
    navigate(intended);
  } else {
    if (!user) {
      window.location.href = "/";
    } else if (user.role === "superadmin") {
      window.location.href = "/super-admin/dashboard";
    } else if (user.role === "admin") {
      window.location.href = "/admin/dashboard";
    } else if (user.role === "user") {
      window.location.href = "/member/dashboard";
    }
  }
};

export const redirectToDashboard = (user) => {
  if (user?.role === "superadmin") {
    return "/super-admin/dashboard";
  } else if (user?.role === "admin") {
    return "/admin/dashboard";
  } else if (user?.role === "manager") {
    return "/manager/dashboard";
  }
};

export const RedirectOnLogout = (res) => {
  if (!res.data.user) {
    window.location.href = "/";
  } else if (res.data.user.role === "superadmin") {
    window.location.href = "/super-admin/dashboard";
  } else if (res.data.user.role === "admin") {
    window.location.href = "/admin/dashboard";
  } else if (res.data.user.role === "user") {
    window.location.href = "/member/dashboard";
  }
};

import { CARE_HOME_TYPES } from "../constants/careHomeTypes";

const initialState = {
  careHomes: [],
  // Add other necessary initial state fields
};

const careHomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARE_HOME_TYPES.CREATE_CARE_HOME:
      return {
        ...state,
        careHomes: [...state.careHomes, action.payload],
      };
    case CARE_HOME_TYPES.FETCH_CARE_HOME:
      return {
        ...state,
        careHomes: action.payload,
      };
    case CARE_HOME_TYPES.UPDATE_CARE_HOME:
      return {
        ...state,
        careHomes: state.careHomes.map((careHome) =>
          careHome._id === action.payload.id
            ? { ...careHome, ...action.payload }
            : careHome
        ),
      };
    case CARE_HOME_TYPES.DELETE_CARE_HOME:
      return {
        ...state,
        careHomes: state.careHomes.filter(
          (careHome) => careHome._id !== action.payload
        ),
      };
    // Handle other action types
    default:
      return state;
  }
};

export default careHomeReducer;

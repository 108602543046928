import React, { useEffect, useState } from "react";
import {
  fetchMdsByPharmacyAction,
  updateMdsStatusByPharmacyAction,
} from "../../../redux/actions/mdsActions";
import { FaEye, FaPlus, FaTrashAlt } from "react-icons/fa";
import { fetchPatientsByPharmacyAction } from "../../../redux/actions/patientAction";
import { createMdsWithoutRepeatCycleByPharmacyAction } from "../../../redux/actions/mdsActions";
import {
  fetchShelvesByPharmacyAction,
  assignShelfAction,
} from "../../../redux/actions/shelveAction";
import {
  Button,
  Modal,
  Table,
  Form,
  ListGroup,
  Row,
  Col,
  Accordion,
  Card,
} from "react-bootstrap";
import { ErrorNotification } from "../../../Components/Notifications/ToastNotifications";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./updateRx.css";
import { createNotificationByPharmacyAction } from "../../../redux/actions/notificationActions";
export default function UpdateRx() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("date");
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedMds, setSelectedMds] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [statusHistory, setStatusHistory] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showCreateMdsModal, setShowCreateMdsModal] = useState(false);
  const [searchPatientTerm, setSearchPatientTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [mdsDetails, setMdsDetails] = useState({
    start: "",
    end: "",
    medicationDetails: [{ name: "", dosage: "" }],
    status: "Pending",
  });
  const [activeKey, setActiveKey] = useState(null);
  const [pagination, setPagination] = useState({});
  // Collections
  const [selectedShelf, setSelectedShelf] = useState("");
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
  const [capacityNeeded, setCapacityNeeded] = useState(1);
  const [collectionType, setCollectionType] = useState("handout");

  // Use Selectors
  const user = useSelector((state) => state.auth.user);
  const mdsData = useSelector((state) => state.mds.mdsState);
  const allPatients = useSelector((state) => state.patient.patients);
  const shelves = useSelector((state) => state.shelve.shelves);

  const getPatients = () => {
    dispatch(fetchPatientsByPharmacyAction(pharmacyId)).catch((err) => {});
  };

  const handleOpenCreateMdsModal = () => {
    setShowCreateMdsModal(true);
  };
  const handleCloseCreateMdsModal = () => {
    setMdsDetails({
      start: "",
      end: "",
      medicationDetails: [{ name: "", dosage: "" }],
      status: "Pending",
    });
    setSearchPatientTerm("");
    setSelectedPatient(null);
    setShowCreateMdsModal(false);
  };

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
    setSearchPatientTerm(`${patient.firstName} ${patient.lastName}`);
    setFilteredPatients([]);
  };

  const handleStartDateChange = (e) => {
    const newStart = e.target.value;
    setMdsDetails((prevDetails) => ({
      ...prevDetails,
      start: newStart,
      // Automatically update the end date if it's not already set or is the same as the old start date
      end:
        !prevDetails.end || prevDetails.end === prevDetails.start
          ? newStart
          : prevDetails.end,
    }));
  };

  const handleEndDateChange = (e) => {
    setMdsDetails((prevDetails) => ({
      ...prevDetails,
      end: e.target.value,
    }));
  };

  const handleMedicationChange = (index, field, value) => {
    const newMedicationDetails = [...mdsDetails.medicationDetails];
    newMedicationDetails[index] = {
      ...newMedicationDetails[index],
      [field]: value,
    };
    setMdsDetails({ ...mdsDetails, medicationDetails: newMedicationDetails });
  };

  const addMedicationDetail = () => {
    setMdsDetails({
      ...mdsDetails,
      medicationDetails: [
        ...mdsDetails.medicationDetails,
        { name: "", dosage: "" },
      ],
    });
  };

  const removeMedicationDetail = (index) => {
    const newMedicationDetails = [...mdsDetails.medicationDetails];
    newMedicationDetails.splice(index, 1);
    setMdsDetails({ ...mdsDetails, medicationDetails: newMedicationDetails });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mdsData = {
      ...mdsDetails,
      patient: selectedPatient._id,
      pharmacyId,
    };
    dispatch(createMdsWithoutRepeatCycleByPharmacyAction(pharmacyId, mdsData));
    dispatch(fetchMdsByPharmacyAction(pharmacyId));
    // console.log(mdsData, "sending Data to create Mds");
    setShowCreateMdsModal(false);
    setMdsDetails({
      start: "",
      end: "",
      medicationDetails: [{ name: "", dosage: "" }],
      status: "Pending",
    });
    setSearchPatientTerm("");
    setSelectedPatient(null);
  };

  // Helper function to group MDS data by patient name
  const groupMdsByPatient = (mdsEntries) => {
    return mdsEntries.reduce((acc, mds) => {
      const patientKey = mds.patient ? mds.patient._id : null;
      const cycleKey = mds.mdsRepeatCycle; // Assuming this should be unique

      if (patientKey && cycleKey) {
        if (!acc[patientKey]) {
          acc[patientKey] = {
            name: `${mds.patient.firstName} ${mds.patient.lastName}`,
            cycles: {},
          };
        }
        if (!acc[patientKey].cycles[cycleKey]) {
          acc[patientKey].cycles[cycleKey] = {
            entries: [],
          };
        }
        acc[patientKey].cycles[cycleKey].entries.push(mds);
      }
      return acc;
    }, {});
  };

  const handlePageChange = (cycleId, page) => {
    setPagination((prev) => ({
      ...prev,
      [cycleId]: { ...prev[cycleId], currentPage: page },
    }));
  };

  const handleItemsPerPageChange = (cycleId, items) => {
    setPagination((prev) => ({
      ...prev,
      [cycleId]: { ...prev[cycleId], itemsPerPage: items, currentPage: 1 },
    }));
  };
  const applyPagination = (entries, cycleId) => {
    const page = pagination[cycleId]?.currentPage || 1;
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const offset = (page - 1) * itemsPerPage;
    return Array.isArray(entries)
      ? entries.slice(offset, offset + itemsPerPage)
      : [];
  };
  const renderCyclePagination = (cycleId, totalEntries) => {
    const itemsPerPage = pagination[cycleId]?.itemsPerPage || 5;
    const currentPage = pagination[cycleId]?.currentPage || 1;
    const totalPageNumbers = Math.ceil(totalEntries / itemsPerPage);

    const pageNumbers = [];
    for (let number = 1; number <= totalPageNumbers; number++) {
      pageNumbers.push(
        <li
          key={number}
          className={`page-item ${number === currentPage ? "active" : ""}`}
        >
          <button
            onClick={() => handlePageChange(cycleId, number)}
            className="page-link"
          >
            {number}
          </button>
        </li>
      );
    }

    return (
      <nav>
        <ul className="custom-table-pagination">{pageNumbers}</ul>
      </nav>
    );
  };

  const renderItemsPerPageSelector = (cycleId) => {
    return (
      <div className="items-per-page-selector">
        <select
          value={pagination[cycleId]?.itemsPerPage || 5}
          onChange={(e) =>
            handleItemsPerPageChange(cycleId, Number(e.target.value))
          }
        >
          {[5, 10, 15, 20, 25].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const applyFiltersAndSorting = (data) => {
    // Filter by dates first
    let filteredData = data.filter((mds) => {
      let startDate = new Date(mds.start);
      let filterFromDate = fromDate
        ? new Date(fromDate)
        : new Date("1900-01-01");
      let filterToDate = toDate ? new Date(toDate) : new Date("2100-01-01");

      // Check if any status in the statusHistory is 'DosettePrepared'
      const hasDosettePrepared = mds.statusHistory.some(
        (status) => status.status === "DosettePrepared"
      );
      return (
        startDate >= filterFromDate &&
        startDate <= filterToDate &&
        !hasDosettePrepared
      );
      // return startDate >= filterFromDate && startDate <= filterToDate;
    });

    // Apply search query filter
    filteredData = filteredData.filter((mds) => {
      const fullName =
        `${mds.patient.firstName} ${mds.patient.lastName}`.toLowerCase();
      return (
        searchQuery.toLowerCase() === "" ||
        fullName.includes(searchQuery.toLowerCase()) ||
        mds.patient.nhsNumber.toString().includes(searchQuery)
      );
    });

    // Sort based on the current sort settings
    return filteredData.sort((a, b) => {
      let compareValue = 0;
      if (sortBy === "patientName") {
        compareValue = (a.patient?.firstName ?? "").localeCompare(
          b.patient?.firstName ?? ""
        );
      } else if (sortBy === "nhsNumber") {
        compareValue = (a.patient?.nhsNumber?.toString() ?? "").localeCompare(
          b.patient?.nhsNumber?.toString() ?? ""
        );
      } else if (sortBy === "date") {
        compareValue = new Date(a.start) - new Date(b.start);
      } else if (sortBy === "status") {
        compareValue = (a.status ?? "").localeCompare(b.status ?? "");
      }
      return sortDirection === "asc" ? compareValue : -compareValue;
    });
  };

  // Group the filtered and sorted data for display in the accordion
  const groupedData = groupMdsByPatient(applyFiltersAndSorting(mdsData));

  // Pagination setup for grouped data
  const totalPages = Math.ceil(Object.keys(groupedData).length / itemsPerPage);

  const resetFilters = () => {
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => (
    <li key={number} className="page-item">
      <button className="page-link" onClick={() => setCurrentPage(number)}>
        {number}
      </button>
    </li>
  ));

  const handleUpdateStatus = async () => {
    if (!newStatus) {
      ErrorNotification("Please select a status before updating.");
      return;
    }

    try {
      // Dispatch the status update action
      dispatch(
        updateMdsStatusByPharmacyAction(pharmacyId, selectedMds._id, newStatus)
      );
      // Create a notification after the status update is successful
      const notificationContent = `Status of MDS ${selectedMds._id} for patient ${selectedMds.patient.firstName} ${selectedMds.patient.lastName} updated to ${newStatus} .`;
      dispatch(
        createNotificationByPharmacyAction(pharmacyId, {
          content: notificationContent,
          user: user._id,
          forRole: ["admin", "manager"],
          priority: "medium",
        })
      );
      setShowModal(false); // Close the modal after update
      setSelectedPatient(null);
    } catch (error) {
      console.error("Error updating status:", error);
      ErrorNotification("An error occurred while updating the status.");
    }
  };

  const handleStatusChange = (e) => {
    const newStatusValue = e.target.value;
    setNewStatus(newStatusValue); // Update the status state

    // Check if the new status is 'DosettePrepared' and a patient is selected
    if (newStatusValue === "DosettePrepared") {
      setShowAddCollectionModal(true);
    } else {
      setShowAddCollectionModal(false); // Optionally hide the modal if the condition is not met
    }
  };

  const closeShowStatusModal = () => {
    setShowModal(false);
    setNewStatus("");
    setSelectedPatient(null);
  };

  const handleAddCollection = () => {
    const shelfName = shelves.find(
      (shelf) => shelf._id === selectedShelf
    )?.name;

    if (!selectedShelf || !selectedPatient._id || !collectionType) {
      ErrorNotification(
        "Missing required fields for assigning a shelf",
        selectedShelf,
        capacityNeeded,
        selectedPatient._id,
        collectionType
      );
      // You may want to show an error message to the user here
      return;
    }

    dispatch(
      assignShelfAction(
        pharmacyId,
        selectedShelf,
        capacityNeeded,
        selectedPatient._id,
        collectionType
      )
    )
      .then(() => {
        // Handle success - perhaps closing the modal or updating the UI
        dispatch(fetchShelvesByPharmacyAction(pharmacyId));
        setSelectedShelf("");
        setCapacityNeeded(1);
        setShowAddCollectionModal(false);
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Shelf '${shelfName}' with ${capacityNeeded} assigned successfully to ${selectedPatient.firstName} ${selectedPatient.lastName}.`,
            user: user._id,
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
      })
      .catch((error) => {
        console.error("Error assigning shelf:", error);
        // Handle error - show an error message to the user
        ErrorNotification(error);
      });
  };

  useEffect(() => {
    if (searchPatientTerm) {
      const filtered = allPatients
        .filter(
          (patient) =>
            patient.displayName
              .toLowerCase()
              .includes(searchPatientTerm.toLowerCase()) ||
            patient.nhsNumber.includes(searchPatientTerm)
        )
        .slice(0, 5); // Limit to 5 results
      setFilteredPatients(filtered);
    } else {
      setFilteredPatients([]);
    }
  }, [searchPatientTerm, allPatients]);

  useEffect(() => {
    dispatch(fetchMdsByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching pharmacy:", error);
    });
    dispatch(fetchShelvesByPharmacyAction(pharmacyId)).catch((error) => {
      console.error("Error fetching shelves:", error);
    });
  }, [dispatch, pharmacyId]);

  useEffect(() => {
    getPatients();
  }, []);
  return (
    <>
      <div className="mt-80">
        <div className="container-fluid">
          <div className="search-input-box">
            <input
              type="text"
              placeholder="Search by name or NHS number..."
              className="form-control mr-10 search-filter-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button className="medtrakr-btn" onClick={handleOpenCreateMdsModal}>
              Add Patient Without Cycle
            </Button>
            <div className="date-filter-box">
              <input
                type="date"
                className="form-control mb-3"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control mb-3"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <button className="btn btn-warning mb-3" onClick={resetFilters}>
                Reset Filters
              </button>
            </div>
          </div>
          <div>
            <Accordion
              activeKey={activeKey}
              onSelect={(key) => setActiveKey(key)}
            >
              {Object.entries(groupedData).map(([patientId, patientData]) => (
                <Accordion.Item eventKey={patientId} key={patientId}>
                  <Accordion.Header
                    onClick={() =>
                      setActiveKey(activeKey === patientId ? null : patientId)
                    }
                  >
                    {patientData.name} - Total Cycles:{" "}
                    {Object.keys(patientData.cycles).length}
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={patientId}>
                    <Card.Body>
                      {Object.entries(patientData.cycles).map(
                        ([cycleId, cycleData]) => (
                          <div
                            key={cycleId}
                            className="table-container"
                            style={{ margin: "1.5rem 0 0 0" }}
                          >
                            <table className="table table-bordered w-100">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Medication Name (Dosage)</th>
                                  <th>Status</th>
                                  <th>Update</th>
                                  <th>View Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {applyPagination(
                                  cycleData.entries,
                                  cycleId
                                ).map((mds) => (
                                  <tr key={mds._id}>
                                    <td>
                                      {new Date(mds.start).toLocaleDateString(
                                        "en-GB",
                                        {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "2-digit",
                                        }
                                      )}
                                    </td>
                                    <td>
                                      {mds.medicationDetails
                                        .map(
                                          (detail) =>
                                            `${detail.name} (${detail.dosage})`
                                        )
                                        .join(", ")}
                                    </td>
                                    <td>
                                      {
                                        mds.statusHistory[
                                          mds.statusHistory.length - 1
                                        ].status
                                      }
                                    </td>
                                    <td>
                                      <button
                                        onClick={() => {
                                          setSelectedMds(mds);
                                          setShowModal(true);
                                          setSelectedPatient(mds.patient);
                                        }}
                                        size="sm"
                                        className="btn update"
                                      >
                                        Update
                                      </button>
                                    </td>
                                    <td>
                                      <Button
                                        variant="btn"
                                        onClick={() => {
                                          setStatusHistory(mds.statusHistory);
                                          setShowStatusModal(true);
                                        }}
                                      >
                                        <FaEye />
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="mds-table-pagination-nav-box">
                              {renderCyclePagination(
                                cycleId,
                                cycleData.entries.length
                              )}
                              {renderItemsPerPageSelector(cycleId)}
                            </div>
                          </div>
                        )
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="page-number-box">
            <nav>
              <ul className="pagination">{renderPageNumbers}</ul>
            </nav>
            {/* Items per page selector */}
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              {[5, 10, 15, 20].map((number) => (
                <option key={number} value={number}>
                  {number} Mds/page
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/* Modal for updating status */}
      <Modal show={showModal} onHide={closeShowStatusModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update MDS Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMds && (
            <div>
              <p>
                <b>Patient Name</b>: {selectedMds.patient.firstName}{" "}
                {selectedMds.patient.lastName}
              </p>
              <p>
                <b>Medication Details</b>:{" "}
                {selectedMds.medicationDetails[0].name}{" "}
                {selectedMds.medicationDetails[0].dosage}
              </p>
              <p>
                <b>Prescription Status</b>: {selectedMds.status}
              </p>
            </div>
          )}
          <Form>
            <Form.Group controlId="statusSelect">
              <Form.Label>Select New Status</Form.Label>
              <Form.Control
                as="select"
                value={newStatus}
                onChange={handleStatusChange}
              >
                <option value="">Select Status</option>
                <option value="Pending">Pending</option>
                <option value="MailSent">Mail Sent</option>
                <option value="PrescriptionReceived">
                  Prescription Received
                </option>
                <option value="PrescriptionProcessed">
                  Prescription Processed
                </option>
                <option value="DosettePrepared">Dosette Prepared</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdateStatus}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Status Modal */}
      <Modal
        show={showStatusModal}
        onHide={() => setShowStatusModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Status History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="status-timeline">
            {[...statusHistory].reverse().map((history, index, arr) => (
              <div
                key={index}
                className={`status-timeline-item ${
                  index === 0 ? "latest" : ""
                }`}
              >
                <div className="status-timeline-dot"></div>
                <div className="status-timeline-content">
                  <p>
                    <strong>Status:</strong> {history.status}
                  </p>
                  <p>
                    <strong>Date:</strong>{" "}
                    {new Date(history.date).toLocaleString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal to  create Mds */}
      <Modal
        show={showCreateMdsModal}
        size="lg"
        onHide={handleCloseCreateMdsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Non MDS Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Search Patient by Name or NHS Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchPatientTerm}
                onChange={(e) => setSearchPatientTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && filteredPatients.length > 0) {
                    e.preventDefault(); // Prevent form submit
                    handleSelectPatient(filteredPatients[0]);
                  }
                }}
                disabled={!!selectedPatient}
              />
              <ListGroup>
                {filteredPatients.map((patient) => (
                  <ListGroup.Item
                    key={patient._id}
                    onClick={() => handleSelectPatient(patient)}
                  >
                    {patient.firstName} {patient.lastName} - {patient.nhsNumber}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>
            {selectedPatient && (
              <>
                <Form.Group>
                  <Form.Label>Patient Name</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>NHS Number</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={selectedPatient.nhsNumber}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={mdsDetails.start}
                    onChange={handleStartDateChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={mdsDetails.end}
                    onChange={handleEndDateChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Medication Details</Form.Label>

                  {mdsDetails.medicationDetails.map((medication, index) => (
                    <Row key={index} className="mb-2 align-items-center">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Enter medication name"
                            value={medication.name}
                            onChange={(e) =>
                              handleMedicationChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder="Dosage"
                            value={medication.dosage}
                            onChange={(e) =>
                              handleMedicationChange(
                                index,
                                "dosage",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Button
                          variant="link"
                          onClick={() => removeMedicationDetail(index)}
                          className="text-danger p-0"
                        >
                          <FaTrashAlt />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Button
                    variant="link"
                    onClick={addMedicationDetail}
                    className="text-success mt-2"
                  >
                    <FaPlus /> Add Another Medication
                  </Button>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={mdsDetails.status}
                    onChange={(e) =>
                      setMdsDetails({ ...mdsDetails, status: e.target.value })
                    }
                  >
                    <option value="Pending">Pending</option>
                    <option value="MailSent">Mail Sent</option>
                    <option value="PrescriptionReceived">
                      Prescription Received
                    </option>
                    <option value="PrescriptionProcessed">
                      Prescription Processed
                    </option>
                    <option value="DosettePrepared">Dosette Prepared</option>
                  </Form.Control>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!selectedPatient}
                >
                  Create MDS
                </Button>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {/* Add to collection Modal */}
      <Modal
        show={showAddCollectionModal}
        onHide={() => setShowAddCollectionModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign shelf a collection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Package Size:</Form.Label>
              <Form.Control
                as="select"
                value={selectedShelf}
                onChange={(e) => setSelectedShelf(e.target.value)}
              >
                <option>Select Shelf</option>
                {shelves.map((shelf) => (
                  <option key={shelf._id} value={shelf._id}>
                    {shelf.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Capacity Needed:</Form.Label>
              <Form.Control
                type="number" // Ensure this is set to 'number'
                value={capacityNeeded}
                onChange={(e) => setCapacityNeeded(Number(e.target.value))}
                min="1" // Optional: Ensure that no invalid numbers can be entered
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Collection Type:</Form.Label>
              <Form.Control
                as="select"
                value={collectionType}
                onChange={(e) => setCollectionType(e.target.value)}
              >
                <option value="handout">handout</option>
                <option value="delivery">delivery</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddCollectionModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddCollection}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

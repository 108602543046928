import { USER_TYPES } from "../constants/userTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import { getAllUsers } from "../../api/user";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

//   Fetch All Users Action
export const fetchAllUsersAction = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await getAllUsers();

    dispatch({
      type: USER_TYPES.FETCH_USERS_LIST,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

import { ROUTE_TYPES } from "../constants/routeTypes";

const initialState = {
  routes: [],
};

const routeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_TYPES.CREATE_ROUTE:
      return {
        ...state,
        routes: [...state.routes, action.payload],
      };
    case ROUTE_TYPES.UPDATE_ROUTE:
      return {
        ...state,
        routes: state.routes.map((route) =>
          route._id === action.payload.id
            ? { ...route, ...action.payload }
            : route
        ),
      };
    case ROUTE_TYPES.DELETE_ROUTE:
      return {
        ...state,
        routes: state.routes.filter((route) => route._id !== action.payload),
      };
    case ROUTE_TYPES.FETCH_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    default:
      return state;
  }
};

export default routeReducer;

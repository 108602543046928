import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainAppRoutes from "./AppRoutes/MainAppRoutes";
// Redux
import { DataProvider } from "./redux/store";
import { SidebarProvider } from "./contexts/SidebarContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
export default function App() {
  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_ID;

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <DataProvider>
          <SidebarProvider>
            <ToastContainer position="top-right" autoClose={3000} />
            <MainAppRoutes />
          </SidebarProvider>
        </DataProvider>
      </GoogleOAuthProvider>
    </>
  );
}

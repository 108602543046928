import { MEMBER_TYPES } from "../constants/memberTypes";

const initialState = {
  members: [],
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_TYPES.CREATE_MEMBER:
      return {
        ...state,
        members: [...state.members, action.payload],
      };
    case MEMBER_TYPES.UPDATE_MEMBER:
      return {
        ...state,
        members: state.members.map((member) =>
          member._id === action.payload.id
            ? { ...member, ...action.payload.updatedMember }
            : member
        ),
      };
    case MEMBER_TYPES.DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter(
          (member) => member._id !== action.payload
        ),
      };
    case MEMBER_TYPES.FETCH_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    default:
      return state;
  }
};

export default memberReducer;

import axios from "axios";
import Cookies from "js-cookie";
import { AUTH_TYPES } from "../redux/constants/authTypes";
import { store } from "../redux/store";

// Set the base url of the backend api
const BASE_URL = process.env.REACT_APP_BACKEND_API;

// Create api with base url
const api = axios.create({ baseURL: BASE_URL });

/*
Before making any request, check for token saved in the browser cookie,
if theres a token in the cookie, add it to the request authorization
header
*/
api.interceptors.request.use((req) => {
  // If token exists, add to req auth header
  if (Cookies.get("access")) {
    req.headers.authorization = `Bearer ${Cookies.get("access")}`;
  }
  return req;
});

/*
If the response has status code of 401, fetch new token before the next request 
*/

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        api
          .post(
            "/refresh_token",
            {},
            { withCredentials: true, credentials: "include" }
          )
          .then(({ data }) => {
            Cookies.set("access", data.accessToken, { expires: 0.0125 }); // 14 minutes
            api.defaults.headers.common["Authorization"] =
              "Bearer " + data.accessToken;
            store.dispatch({
              type: AUTH_TYPES.AUTH,
              payload: {
                accessToken: data.accessToken,
                user: data.user,
              },
            });
            processQueue(null, data.accessToken);
            resolve(api(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);
export default api;

import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { acceptTeamInvite } from "../../../api/admin";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../../Components/Notifications/ToastNotifications";

const initialData = {
  name: "",
  password: "",
  confirmPassword: "",
};

const TeamInviteAccept = () => {
  const [values, setValues] = useState(initialData);
  const [success, setSuccess] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  // Handle form change
  const handleFormChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { name, password, confirmPassword } = values;

    // Validate all fields
    if (!name || !password || !confirmPassword) {
      ErrorNotification("Please fill all fields");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      ErrorNotification("Passwords don't match");
      return;
    }

    acceptTeamInvite({ token, name, password })
      .then(() => {
        // Success
        SuccessNotification("Successfully! Log in to continue");
        setSuccess(true);
      })
      .catch((err) => {
        // Error
        ErrorNotification(err.response.data.type[0].message);
        console.log("TEAM_ACCEPT_ERROR", err);
      });
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md={6}>
          {!success ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name :</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter your name"
                  value={values.name}
                  onChange={handleFormChange("name")}
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>Password :</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  value={values.password}
                  onChange={handleFormChange("password")}
                />
              </Form.Group>

              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password :</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Re-enter password"
                  value={values.confirmPassword}
                  onChange={handleFormChange("confirmPassword")}
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Join
              </Button>
            </Form>
          ) : (
            <div className="text-center">
              <h1>Joined Successfully</h1>
              <Link to="/login">Login</Link>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TeamInviteAccept;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserById, uploadUserProfileImage } from "../../../api/user";
import { Modal, Button, Form, Image } from "react-bootstrap";
import "./profile.css";
import { fetchUserByIdAction } from "../../../redux/actions/authActions";
import { fetchPharmacyAction } from "../../../redux/actions/pharmacyActions";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../../Components/Notifications/ToastNotifications";

function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const pharmacy = useSelector((state) => state.pharmacy.pharmacy);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    role: user?.role || "",
    dob: user?.dob || "",
    address: user?.address || "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "dob") {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        setValidationErrors({
          ...validationErrors,
          dob: "Date of Birth cannot be a future date.",
        });
      } else {
        // Clear validation error if date is correct and update form data
        const newErrors = { ...validationErrors };
        delete newErrors.dob; // Remove dob error
        setValidationErrors(newErrors);
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleSaveChanges = async () => {
    if (selectedFile) {
      await handleUploadImage();
    }

    try {
      const { email, role, ...updateData } = formData; // Exclude non-editable data
      const result = await updateUserById(user._id, updateData);
      if (result.success) {
        dispatch(fetchUserByIdAction(user._id));
        SuccessNotification("Profile Updated Successfully!");
        // close modal
        setShowModal(false);
      } else {
        ErrorNotification("Failed to Update Profile");
      }
    } catch (error) {
      ErrorNotification("Couldn't Update Profile, Try Again Later");
    }
  };

  const handleUploadImage = async () => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await uploadUserProfileImage(user._id, formData);
      if (response.message === "Media uploaded successfully") {
        dispatch(fetchUserByIdAction(user._id));
        SuccessNotification("Image Uploaded Successfully!");
      } else {
        ErrorNotification("Image Upload Failed");
      }
    } catch (error) {
      ErrorNotification("Failed to Upload Image");
    }
  };

  useEffect(() => {
    dispatch(fetchUserByIdAction(user?._id));
    dispatch(fetchPharmacyAction(user?.pharmacy));
  }, [dispatch, user?._id, user?.pharmacy]);

  return (
    <div className="mt-100 container-fluid">
      <div>
        <h2>Personal Information</h2>
        <hr />
        <div className="title-name-box">
          <h2>Contact</h2>
          <button onClick={() => setShowModal(true)} className="edit-btn">
            Edit
          </button>
        </div>
        <div className="profile-details">
          {user?.picture && user?.picture.length > 0 ? (
            <Image
              src={user?.picture[user?.picture.length - 1]}
              width={100}
              height={100}
              className="profile-image"
              rounded
            />
          ) : null}
          <p>
            <b>Full name</b>: {user?.name}
          </p>
          <p>
            <b>Email</b>: {user?.email}
          </p>
          <p>
            <b>Role</b>: {user?.role}
          </p>
          <p>
            <b>D.O.B</b>: {user?.dob || <span>Not yet set</span>}
          </p>
          <p>
            <b>Address</b>: {user?.address || <span>Not yet set</span>}
          </p>
        </div>
        <hr />
        <div className="title-name-box">
          <h2>Associated Pharmacy</h2>
        </div>
        <div className="profile-details">
          <p>
            <b>Pharmacy Name</b>: {pharmacy?.pharmacyName}
          </p>
          <p>
            <b>Pharmacy Address</b>: {pharmacy?.pharmacyAddress}
          </p>
          <p>
            <b>Pharmacy Email</b>: {pharmacy?.pharmacyEmail}
          </p>
          <p>
            <b>Pharmacy Phone</b>: {pharmacy?.pharmacyPhone}
          </p>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDOB">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.dob}
              />
              {validationErrors.dob && (
                <Form.Control.Feedback type="invalid">
                  {validationErrors.dob}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Profile Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Profile;

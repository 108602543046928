import { SHELVE_TYPES } from "../constants/shelveTypes";

const initialState = {
  shelves: [],
};

const shelveReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHELVE_TYPES.CREATE_SHELVE:
      return {
        ...state,
        shelves: [...state.shelves, action.payload],
      };
    case SHELVE_TYPES.UPDATE_SHELVE:
      return {
        ...state,
        shelves: state.shelves.map((shelve) =>
          shelve._id === action.payload.id
            ? { ...shelve, ...action.payload }
            : shelve
        ),
      };
    case SHELVE_TYPES.DELETE_SHELVE:
      return {
        ...state,
        shelves: state.shelves.filter(
          (shelve) => shelve._id !== action.payload
        ),
      };
    case SHELVE_TYPES.FETCH_SHELVES:
      return {
        ...state,
        shelves: action.payload,
      };
    case SHELVE_TYPES.ASSIGN_SHELVE:
      return {
        ...state,
        shelves: state.shelves.map((shelf) =>
          shelf._id === action.payload._id ? action.payload : shelf
        ),
      };
    case SHELVE_TYPES.REASSIGN_SHELF:
      return {
        ...state,
        shelves: state.shelves.map((shelf) =>
          shelf._id === action.payload._id ? action.payload : shelf
        ),
      };
    case SHELVE_TYPES.REMOVE_SHELVE:
      return {
        ...state,
        shelves: state.shelves.map(
          (shelf) => (shelf._id === action.payload._id ? action.payload : shelf)
          // Assuming action.payload contains the updated shelf information
        ),
      };
    default:
      return state;
  }
};

export default shelveReducer;

import React, { useEffect, useState } from "react";
import { fetchPlansAction } from "../../../../redux/actions/planActions";
import "./viewPlan.css";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa"; // Importing an icon for bullet points

function ViewPlan() {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.plan.plans);
  const [billingCycle, setBillingCycle] = useState("monthly");

  useEffect(() => {
    dispatch(fetchPlansAction());
  }, [dispatch]);

  // Utility function to format feature text
  const formatFeatureText = (feature) => {
    const splitIndex = feature.indexOf(":");
    if (splitIndex !== -1) {
      return (
        <span className="plan-text">
          <strong>{feature.substring(0, splitIndex)}</strong>
          {feature.substring(splitIndex)}
        </span>
      );
    }
    return feature;
  };

  return (
    <div className=" plan-section">
      <div className="container-fluid">
        <div className="plan-toggle-container">
          <ToggleButtonGroup
            type="radio"
            name="options"
            defaultValue={billingCycle}
            onChange={(value) => setBillingCycle(value)}
          >
            <ToggleButton
              id="monthly"
              value="monthly"
              variant="outline-primary"
            >
              Monthly
            </ToggleButton>
            <ToggleButton
              id="yearly"
              value="yearly"
              variant="outline-secondary"
            >
              Yearly
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="d-flex flex-wrap justify-content-around plan-container">
          {plans.map((plan, index) => (
            <Card key={index} className="plan-card">
              <Card.Body>
                <Card.Title className="plan-title">{plan.name}</Card.Title>
                <Card.Text className="plan-description">
                  {plan.metaDescription}
                </Card.Text>
                <Card.Text className="plan-price">
                  Price:{" "}
                  {billingCycle === "monthly"
                    ? `$${plan.monthlyPrice}`
                    : `$${plan.annualPrice}`}
                </Card.Text>
                <ul className="features-list">
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>
                      <FaCheckCircle className="feature-icon" />
                      {formatFeatureText(feature.feature)}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewPlan;

import React, { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
  const [isSidebarExpanded, setSidebarIsExpanded] = useState(false);

  const toggleSidebar = () => setSidebarIsExpanded(!isSidebarExpanded);
  const showSidebar = () => setSidebarIsExpanded(true);
  const hideSidebar = () => setSidebarIsExpanded(false);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarExpanded,
        setSidebarIsExpanded,
        toggleSidebar,
        showSidebar,
        hideSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import medtrakrLogo from "../../images/medtrakrLogo.png";
import { useSidebar } from "../../contexts/SidebarContext";
import "./adminSidebar.css";
export default function DriverSidebar({ pharmacyId }) {
  const { isSidebarExpanded, setSidebarIsExpanded } = useSidebar();
  const location = useLocation();
  const [dropdowns, setDropdowns] = useState({
    // Nothing exists yet that is a dropdown
  });

  const toggleDropdown = (dropdown) => {
    setDropdowns((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const getTextStyle = () => ({
    overflow: isSidebarExpanded ? "visible" : "hidden",
    whiteSpace: isSidebarExpanded ? "normal" : "nowrap",
    width: isSidebarExpanded ? "auto" : "0",
    transition: "width 0.3s ease",
    fontSize: "1rem",
  });

  useEffect(() => {
    const path = location.pathname;
  });

  return (
    <div
      className={`sidebar`}
      onMouseEnter={() => setSidebarIsExpanded(true)}
      onMouseLeave={() => setSidebarIsExpanded(false)}
      style={{
        width: isSidebarExpanded ? "200px" : "60px",
        transition: "width 0.3s ease",
      }}
    >
      <div className="sidebar-content">
        <div className="text-center my-3">
          <Link
            to={`/driver/${pharmacyId}/dashboard`}
            style={{ justifyContent: "center" }}
          >
            <img
              className="logo-small"
              src={medtrakrLogo}
              alt="logo"
              style={{ maxWidth: "35px" }}
            />
          </Link>
        </div>
        <ul className="sidebar-ul pt-4">
          <li>
            <div className="w-100 sidebar-div ">
              <NavLink
                to={`/driver/${pharmacyId}/dashboard`}
                className={({ isActive }) =>
                  isActive ? "nav-item-active" : "nav-item"
                }
              >
                <div className="admin-nav">
                  <i className="fas fa-tachometer-alt"></i>
                  <span style={getTextStyle()}>Dashboard</span>
                </div>
              </NavLink>
            </div>
          </li>
        </ul>
      </div>
      <div
        className="outlet"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ marginTop: "75px" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

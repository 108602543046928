import api from "./index";

// Create Member
export const createMemberByPharmacy = async (pharmacyId, memberData) => {
  return await api.post(`/${pharmacyId}/create-member`, memberData);
};
// Update Member
export const updateMemberByPharmacy = async (pharmacyId, id, memberData) => {
  return await api.put(`/${pharmacyId}/update-member/${id}`, memberData);
};
// Delete Member
export const deleteMemberByPharmacy = async (pharmacyId, id) => {
  return await api.delete(`/${pharmacyId}/delete-member/${id}`);
};
// Fetch Members
export const fetchMembersByPharmacy = async (pharmacyId) => {
  return await api.get(`/${pharmacyId}/get-members`);
};

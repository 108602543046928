import { SHELVE_TYPES } from "../constants/shelveTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createShelveByPharmacy,
  updateShelveByPharmacy,
  deleteShelveByPharmacy,
  fetchShelvesByPharmacy,
  assignShelf,
  removeShelf,
  reassignShelf,
} from "../../api/shelveApi";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Create Shelve Action
export const createShelveByPharmacyAction =
  (pharmacyId, shelveData) => async (dispatch) => {
    try {
      const res = await createShelveByPharmacy(pharmacyId, shelveData);
      dispatch({
        type: SHELVE_TYPES.CREATE_SHELVE,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Shelve Action
export const updateShelveByPharmacyAction =
  (pharmacyId, id, shelveData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateShelveByPharmacy(pharmacyId, id, shelveData);

      dispatch({
        type: SHELVE_TYPES.UPDATE_SHELVE,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Shelve Action
export const deleteShelveByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteShelveByPharmacy(pharmacyId, id); // Assuming this function makes a DELETE request to the server with the shelf ID

      dispatch({
        type: SHELVE_TYPES.DELETE_SHELVE,
        payload: id,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };

// Fetch Shelves Action
export const fetchShelvesByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchShelvesByPharmacy(pharmacyId);

      dispatch({
        type: SHELVE_TYPES.FETCH_SHELVES,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

// Assign Shelf Action
export const assignShelfAction =
  (pharmacyId, shelfId, capacityNeeded, selectedPatientId, collectionType) =>
  async (dispatch) => {
    try {
      const res = await assignShelf(
        pharmacyId,
        shelfId,
        capacityNeeded,
        selectedPatientId,
        collectionType
      );
      dispatch({
        type: SHELVE_TYPES.ASSIGN_SHELVE,
        payload: res.data,
      });
    } catch (error) {
      const errorMessage =
        error.response.data?.error ||
        error.response.data?.message ||
        "An unexpected error occurred";
      ErrorNotification(errorMessage);
    }
  };

// Reassign Shelf Action
export const reassignShelfAction =
  (pharmacyId, collectionId, newShelfId, capacityNeeded) =>
  async (dispatch) => {
    try {
      const response = await reassignShelf(
        pharmacyId,
        collectionId,
        newShelfId,
        capacityNeeded
      );
      dispatch({
        type: SHELVE_TYPES.REASSIGN_SHELF,
        payload: response.data, // Assuming response.data contains the updated shelf data
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        "An unexpected error occurred";
      ErrorNotification(errorMessage);
    }
  };

export const removeShelfAction =
  (pharmacyId, collectionId, newStatus, collectionDetails) =>
  async (dispatch) => {
    try {
      const res = await removeShelf(
        pharmacyId,
        collectionId,
        newStatus,
        collectionDetails
      );
      dispatch({
        type: SHELVE_TYPES.REMOVE_SHELVE,
        payload: { collectionId, ...res.data }, // Assuming res.data contains the updated shelf data
      });
    } catch (error) {
      const errorMessage =
        error.response.data?.error ||
        error.response.data?.message ||
        "An unexpected error occurred";
      ErrorNotification(errorMessage);
    }
  };

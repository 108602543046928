import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./mixgraph.css";
function ShelveMixBarGraph({ shelves }) {
  const abbreviateName = (name) => name.substring(0, 3);
  // Prepare chart data
  const data = shelves.map((shelf) => ({
    name: shelf.name,
    TotalCapacity: shelf.shelfCapacity,
    OccupiedCapacity: shelf.occupiedCapacity,
    AvailableCapacity: shelf.shelfCapacity - shelf.occupiedCapacity,
  }));

  return (
    <div className="mix-graph-container">
      <div className="title-box">
        <div className="dashboard-titles">Shelves</div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="TotalCapacity" fill="#8884d8" name="Total Capacity" />
          <Bar
            dataKey="OccupiedCapacity"
            fill="#82ca9d"
            name="Occupied Capacity"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ShelveMixBarGraph;

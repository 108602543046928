import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./dashboard.css";
import { Col, Container, Row } from "react-bootstrap";
import OnTheWorksAnimation from "../../../Components/animations/OnTheWorksAnimation";
export default function Dashboard() {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  return (
    <div className="mt-60">
      <Container>
        <Row className="main-row">
          <Col className="row-centered corn-blue">
            <OnTheWorksAnimation />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

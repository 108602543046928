import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/onTheWorks.json";
import "./loginAnimation.css";
export default class OnTheWorksAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false,
      currentQuoteIndex: 0,
      quotes: [
        "We're building something amazing for your pharmacy needs.",
        "Your health, our priority—stay tuned for the best in pharmacy tech.",
        "On the way: Streamlined delivery and management for modern pharmacies.",
        "Creating a seamless experience for pharmacies and drivers alike.",
        "Under construction: Empowering your pharmacy with cutting-edge technology.",
      ],
    };
  }
  componentDidMount() {
    this.quoteInterval = setInterval(() => {
      this.setState((prevState) => ({
        currentQuoteIndex:
          (prevState.currentQuoteIndex + 1) % prevState.quotes.length,
      }));
    }, 6000); // Changes quote seconds
  }

  componentWillUnmount() {
    clearInterval(this.quoteInterval);
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const { quotes, currentQuoteIndex } = this.state;

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        <div className="quote">{quotes[currentQuoteIndex]}</div>
      </div>
    );
  }
}

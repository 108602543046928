import { VEHICLE_TYPES } from "../constants/vehicleTypes";

const initialState = {
  vehicles: [],
};

const vehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE_TYPES.CREATE_VEHICLE:
      return {
        ...state,
        vehicles: [...state.vehicles, action.payload],
      };
    case VEHICLE_TYPES.UPDATE_VEHICLE:
      return {
        ...state,
        vehicles: state.vehicles.map((vehicle) =>
          vehicle._id === action.payload.id
            ? { ...vehicle, ...action.payload }
            : vehicle
        ),
      };
    case VEHICLE_TYPES.DELETE_VEHICLE:
      return {
        ...state,
        vehicles: state.vehicles.filter(
          (vehicle) => vehicle._id !== action.payload
        ),
      };
    case VEHICLE_TYPES.FETCH_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };
    default:
      return state;
  }
};

export default vehicleReducer;

import { MDS_TYPES } from "../constants/mdsTypes";

const initialState = {
  mdsState: [],
  mdsReports: [],
};

const mdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MDS_TYPES.CREATE_MDS:
      return {
        ...state,
        mdsState: [...state.mdsState, action.payload],
      };
    case MDS_TYPES.CREATE_MDS_NON_CYCLE:
      return {
        ...state,
        mdsState: [...state.mdsState, action.payload],
      };
    case MDS_TYPES.UPDATE_MDS:
      return {
        ...state,
        mdsState: state.mdsState.map((mds) =>
          mds._id === action.payload.id ? { ...mds, ...action.payload } : mds
        ),
      };
    case MDS_TYPES.UPDATE_MDS_STATUS:
      return {
        ...state,
        mdsState: state.mdsState.map((mds) => {
          if (mds._id === action.payload.id) {
            // Assuming action.payload contains the updated MDS object
            return {
              ...mds,
              statusHistory: [...mds.statusHistory, action.payload.newStatus],
            };
          }
          return mds;
        }),
      };
    case MDS_TYPES.DELETE_MDS:
      return {
        ...state,
        mdsState: state.mdsState.filter((mds) => mds._id !== action.payload),
      };
    case MDS_TYPES.FETCH_MDS:
      return {
        ...state,
        mdsState: action.payload,
      };
    case MDS_TYPES.FETCH_MDS_REPORTS:
      return {
        ...state,
        mdsReports: action.payload,
      };

    case MDS_TYPES.DELETE_MULTI_MDS:
      return {
        ...state,
        mdsState: state.mdsState.filter(
          (mds) => mds.repeatCycle !== action.payload
        ),
      };
    case MDS_TYPES.UPDATE_MULTI_MDS:
      return {
        ...state,
        mdsState: state.mdsState.map((mds) => {
          // If the mds has the same repeatCycleId as the updated ones,
          // replace it. Otherwise, keep the original.
          if (mds.mdsRepeatCycle === action.payload.repeatCycleId) {
            return (
              action.payload.updatedMds.find(
                (updatedMds) => updatedMds._id === mds._id
              ) || mds
            );
          }

          return mds;
        }),
      };

    default:
      return state;
  }
};

export default mdsReducer;

import { MDS_REPEAT_CYCLE_TYPES } from "../constants/mdsRepeatCycleTypes";
import { ACTION_TYPES } from "../constants/actionTypes";

import { fetchMdsRepeatCyclesByPharmacy } from "../../api/mdsRepeatCycle";
import { ErrorNotification } from "../../Components/Notifications/ToastNotifications";

// Fetch RepeatCycles Action
export const fetchMdsRepeatCyclesByPharmacyAction =
  (pharmacyId) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await fetchMdsRepeatCyclesByPharmacy(pharmacyId);

      dispatch({
        type: MDS_REPEAT_CYCLE_TYPES.FETCH_MDS_REPEAT_CYCLES,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };

import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer";
import careHomeReducer from "./careHomeReducer";
import patientReducer from "./patientReducer";
import routeReducer from "./routeReducer";
import shelveReducer from "./shelveReducer";
import vehicleReducer from "./vehicleReducer";
import collectionReducer from "./collectionReducer";
import appointmentReducer from "./appointmentReducer";
import memberReducer from "./memberReducer";
import deliveryReducer from "./deliveryReducer";
import repeatCycleReducer from "./repeatCycleReducer";
import mdsReducer from "./mdsReducer.";
import mdsRepeatCycleReducer from "./mdsRepeatCycleReducer";
import pharmacyReducer from "./pharmacyReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import planReducer from "./planReducer";
import adminReducer from "./adminReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  careHome: careHomeReducer,
  patient: patientReducer,
  route: routeReducer,
  shelve: shelveReducer,
  vehicle: vehicleReducer,
  collection: collectionReducer,
  appointment: appointmentReducer,
  member: memberReducer,
  delivery: deliveryReducer,
  repeatCycle: repeatCycleReducer,
  mds: mdsReducer,
  mdsRepeatCycle: mdsRepeatCycleReducer,
  pharmacy: pharmacyReducer,
  users: userReducer,
  notifications: notificationReducer,
  plan: planReducer,
});

export default rootReducer;

import { ROUTE_TYPES } from "../constants/routeTypes";
import { ACTION_TYPES } from "../constants/actionTypes";
import {
  createRouteByPharmacy,
  updateRouteByPharmacy,
  deleteRouteByPharmacy,
  fetchRoutesByPharmacy,
} from "../../api/routeApi";
import {
  SuccessNotification,
  ErrorNotification,
} from "../../Components/Notifications/ToastNotifications";

// Create Route Action
export const createRouteByPharmacyAction =
  (pharmacyId, routeData) => async (dispatch) => {
    try {
      const res = await createRouteByPharmacy(pharmacyId, routeData);
      dispatch({
        type: ROUTE_TYPES.CREATE_ROUTE,
        payload: res.data,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);
    }
  };

// Update Route Action
export const updateRouteByPharmacyAction =
  (pharmacyId, id, routeData) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      const res = await updateRouteByPharmacy(pharmacyId, id, routeData);

      dispatch({
        type: ROUTE_TYPES.UPDATE_ROUTE,
        payload: { id, ...res.data },
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: {
          message: error.response.data?.message,
        },
      });
    }
  };
// Delete Route Action
export const deleteRouteByPharmacyAction =
  (pharmacyId, id) => async (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: { loading: true },
    });

    try {
      await deleteRouteByPharmacy(pharmacyId, id);

      dispatch({
        type: ROUTE_TYPES.DELETE_ROUTE,
        payload: id,
      });
    } catch (error) {
      ErrorNotification(error.response.data?.message);

      dispatch({
        type: ACTION_TYPES.ALERT,
        payload: { loading: false },
      });
    }
  };
// Fetch Routes Action
export const fetchRoutesByPharmacyAction = (pharmacyId) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.ALERT,
    payload: { loading: true },
  });

  try {
    const res = await fetchRoutesByPharmacy(pharmacyId);

    dispatch({
      type: ROUTE_TYPES.FETCH_ROUTES,
      payload: res.data,
    });
  } catch (error) {
    ErrorNotification(error.response.data?.message);

    dispatch({
      type: ACTION_TYPES.ALERT,
      payload: {
        message: error.response.data?.message,
      },
    });
  }
};

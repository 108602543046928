import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RiNotification3Line } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  markNotificationAsRead,
  fetchNotificationsByPharmacyAction,
} from "../../redux/actions/notificationActions";
import "./notification.css";

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const closeDropdownTimeout = useRef(null);

  const unreadNotifications = notifications.filter(
    (notification) => !notification.isRead
  );

  // Create a copy of the array and reverse it to display the latest notification first
  const reversedUnreadNotifications = [...unreadNotifications].reverse();

  const handleMouseEnter = () => {
    if (closeDropdownTimeout.current) {
      clearTimeout(closeDropdownTimeout.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    closeDropdownTimeout.current = setTimeout(() => {
      setIsOpen(false);
    }, 500); // Close the dropdown after a delay of 500ms
  };

  const handleMarkAsRead = (notificationId) => {
    dispatch(markNotificationAsRead(pharmacyId, notificationId));
  };

  const fetchAllNotifications = async () => {
    dispatch(fetchNotificationsByPharmacyAction(pharmacyId));
  };

  // Cleanup on component unmount to clear any lingering timeouts
  useEffect(() => {
    fetchAllNotifications();
    return () => {
      if (closeDropdownTimeout.current) {
        clearTimeout(closeDropdownTimeout.current);
      }
    };
  }, []);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={dropdownRef}
    >
      <button className="header-notification-btn">
        <RiNotification3Line />
        {unreadNotifications.length > 0 && (
          <span className="notification-count">
            {unreadNotifications.length}
          </span>
        )}
      </button>
      {isOpen && (
        <div className="notification-menu">
          {reversedUnreadNotifications.length > 0 ? (
            reversedUnreadNotifications.map((notification) => (
              <div key={notification._id} className="notification-item">
                <p>{notification.content}</p>
                <button
                  className="tick-btn"
                  onClick={() => handleMarkAsRead(notification._id)}
                >
                  <FaCheck />
                </button>
              </div>
            ))
          ) : (
            <p>No new notifications</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
